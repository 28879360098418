import "./VideoPage.scss";

import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import { IonContent, IonItemDivider, IonPage } from "@ionic/react";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { ApplicationContext } from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { ApiVideo, MediaType, VideoResponse, EAPDescriptionType, ClinicalCareCategory } from "../../utils/ApiTypes";
import { PleaseWait } from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import { VideoComment } from "../../components/VideoComment/VideoComment";
import { MuxVideoPlayer } from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import { useParams, useLocation } from "react-router-dom";
import { Feature } from "../../components/Feature/Feature";
import { ResponsiveNavigationMenu } from "../../components/Navigation/ResponsiveNavigationMenu";
import { TalkToSomeone } from "../../components/TalkToSomeone/TalkToSomeone";
import { t } from "i18next";
import useQueryChecker from "../../components/CustomHooks/useQueryChecker";
import { HasFeatureEnabled } from "../../components/Feature/Feature";

type VideoPageRouteParams = {
    id: string;
    playlistId: string;
    categoryId?: string;
};

export const VideoPage: React.FC = () => {
    const [muxVideo, setMuxVideo] = useState<VideoResponse | null>(null);
    const [muxAssetVideo, setMuxAssetVideo] = useState<ApiVideo | null>(null);
    const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const [clinicalCareVideoCategory, setClinicalCareVideoCategory] = useState<string>("generic");
    const { isMobileWidth, currentUser, handleGeneralError, handleApplicationError } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const [videoId, setVideoId] = useState<number | null>(null);
    const params = useParams<VideoPageRouteParams>();
    const {isMatch, isCheckLoading} = useQueryChecker("isAsset", "1");
    const hasProactiveClinicalCare = HasFeatureEnabled("negative-check-in-prompt");

    useEffect(() => {
        //We wait for the assetFetched hook to finish either getting the asset or letting us know there is no asset to fetch
        if (isComponentVisible && !isCheckLoading) {
            //If we have an asset and its type of ApiVideo, then we dont need to call getVideoContent()
            const vId = params.id;
            setVideoId(parseInt(vId, 10));
            setIsVideoContentLoading(true);
            if(isMatch){
                getAssetContent(params.id);
            } else {
                getVideoContent(vId);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, params.id, isCheckLoading]);


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title ?? muxAssetVideo?.title,
            video_duration: muxVideo?.duration ?? muxAssetVideo?.duration,
            video_id: muxVideo?.id ?? muxAssetVideo?.id,
            video_clinical_care_category: clinicalCareVideoCategory,
        }
        await AnalyticsService.trackUserAction("video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title ?? muxAssetVideo?.title,
            video_duration: muxVideo?.duration ?? muxAssetVideo?.duration,
            video_id: muxVideo?.id ?? muxAssetVideo?.id,
        }
        await AnalyticsService.trackUserAction("video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: muxVideo?.title ?? muxAssetVideo?.title,
            video_duration: muxVideo?.duration ?? muxAssetVideo?.duration,
            video_id: muxVideo?.id ?? muxAssetVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("video_checkpoint", location.pathname, eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: muxVideo?.title ?? muxAssetVideo?.title,
            video_duration: muxVideo?.duration ?? muxAssetVideo?.duration,
            video_id: muxVideo?.id ?? muxAssetVideo?.id,
        }
        await AnalyticsService.trackUserAction("video_unmute", location.pathname, eventProperties);
    }

    function getVideoContent(videoId: string) {
        setIsVideoContentLoading(true);
        JourneyApiClient.getVideoById(videoId).then((jsonResponse: VideoResponse) => {
            setMuxVideo(jsonResponse);
            setIsVideoContentLoading(false);
            logClinicalCareWatch(jsonResponse);
        }).catch(error => {
            setIsVideoContentLoading(false);
            handleGeneralError("Could not get video content", error);
        });
    }

    function getAssetContent(assetId: string) {
        JourneyApiClient.getAsset(assetId).then((asset) => {
            if(asset && (asset.type === MediaType.Video || asset.type === MediaType.Audio)){
                setMuxAssetVideo(asset as ApiVideo);
                setIsVideoContentLoading(false);
                logClinicalCareWatch(asset as ApiVideo);
            }
        }).catch(error => {
            setIsVideoContentLoading(false);
            handleGeneralError("Could not get video content", error);
        });
    }

    function logClinicalCareWatch(video: VideoResponse | ApiVideo) {
        if(video.clinicalCareCategory) {
            setClinicalCareVideoCategory(video.clinicalCareCategory);
            if(video.clinicalCareCategory === "sensitive" && hasProactiveClinicalCare) {
                JourneyApiClient.logClinicalCareVideoWatch(params.playlistId, video.id.toString());
            }
        };
    }

    function handleError(description: string) {
        handleApplicationError(description);
    }

    function getVideoDurationString(duration: number): string {
        const minutes: number = Math.round(duration / 60);
        return `${minutes} min`;
    }

    const proactiveClinicalHelpSubtitle = clinicalCareVideoCategory === ClinicalCareCategory.Generic ? t("Our Master's Level Clinicians are available 24/7 to support your mental health, child care, elder care, legal, or financial needs. All support is free and confidential.") : 
    t("Our Master's Level Clinicians are available 24/7 to provide a listening ear and have a free, confidential conversation about your mental health. Your employer will never have access to anything you share in the app or with our counselors.")
    return (
        <IonPage className="video-page-component">

            <ResponsiveNavigationMenu />

            <IonContent className="video-content">
                <div className="video-page-wrapper">
                    <div className='video-container'>
                        {((videoId === null && muxAssetVideo === null) || !currentUser || isVideoContentLoading) ?
                            <PleaseWait/> :
                            
                            (muxVideo) ? <MuxVideoPlayer
                                videoId={muxVideo.id}
                                duration={muxVideo.duration}
                                position={muxVideo.position}
                                isLoading={isVideoContentLoading}
                                isComponentVisible={isComponentVisible}
                                isInitialMuted={true}
                                muxPlaybackId={muxVideo.muxPlaybackId}
                                muxVideoId={muxVideo.muxVideoId}
                                title={muxVideo.title}
                                currentUser={currentUser}
                                trackCuePointEvent={trackCuePointEvent}
                                trackPlayEvent={trackPlayEvent}
                                trackPauseEvent={trackPauseEvent}
                                handleApplicationError={handleError}
                                trackUnmute={trackUnmute}
                                autoplay={true}
                                playlistId={parseInt(params.playlistId)}
                                numberOfRecommendationsToShow={isMobileWidth ? 1 : 6} /> 
                            :
                            <> {/* Typescript complains when we try (muxVideo || muxAssetVideo) with videoId={muxVideo.id ?? muxAssetVideo.id} */} 
                            {muxAssetVideo && <MuxVideoPlayer
                                videoId={muxAssetVideo.id}
                                duration={muxAssetVideo.duration}
                                position={muxAssetVideo.position}
                                isLoading={isVideoContentLoading}
                                isComponentVisible={isComponentVisible}
                                isInitialMuted={true}
                                muxPlaybackId={muxAssetVideo.muxPlaybackId}
                                muxVideoId={muxAssetVideo.muxVideoId}
                                title={muxAssetVideo.title}
                                currentUser={currentUser}
                                trackCuePointEvent={trackCuePointEvent}
                                trackPlayEvent={trackPlayEvent}
                                trackPauseEvent={trackPauseEvent}
                                handleApplicationError={handleError}
                                trackUnmute={trackUnmute}
                                autoplay={true}
                                playlistId={parseInt(params.playlistId)}
                                numberOfRecommendationsToShow={isMobileWidth ? 1 : 6} />} </>}
                    </div>
                    <div className="video-content-container">
                        {(muxVideo || muxAssetVideo) && <>
                            <div className="title body-large-variant">{muxVideo?.title ?? muxAssetVideo?.title}</div>
                            <div className="duration caption">{(muxVideo?.duration) ? getVideoDurationString(muxVideo?.duration ?? muxAssetVideo?.duration) : ""}</div>
                            <div className="description body-medium">{muxVideo?.description ?? muxAssetVideo?.description}</div>
                        </>}

                        <div className="video-talk-to-someone-wrapper">
                            {currentUser && <Feature feature="has-eap">
                                <TalkToSomeone
                                    className={"video-page-talk-to-someone"}
                                    user={currentUser}
                                    isMobileWidth={isMobileWidth}
                                    showDescription={false}
                                    showDescriptionInPopover={false}
                                    isCondensed={false}
                                    eapDescription={EAPDescriptionType.Default}
                                    subtitle={proactiveClinicalHelpSubtitle}
                                    isComponentVisible={isComponentVisible} />
                            </Feature>}
                        </div>

                        <IonItemDivider className="line-divider" />
                        {(muxVideo && videoId && currentUser) && 
                            <Feature feature="video-comments">
                                <div className={"comments header-5"}>Comments</div>
                                <VideoComment
                                    sourceId={videoId}
                                    currentUser={currentUser}
                                    isLiveStream={false}
                                />
                            </Feature>
                        } 
                        
                        {( muxAssetVideo && muxAssetVideo.id && currentUser) && 
                            <Feature feature="video-comments">
                                <div className={"comments header-5"}>Comments</div>
                                <VideoComment
                                    sourceId={muxAssetVideo.id}
                                    currentUser={currentUser}
                                    isLiveStream={false}
                                />
                            </Feature>
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
};
