import React, {useContext} from 'react';
import {IonButton, IonButtons, IonFooter, IonToolbar, isPlatform} from "@ionic/react";
import './FooterComponent.scss';
import {ApplicationContext} from "../../misc/ApplicationContext";
import {PRIVACY_POLICY_URL, PROFILE_PATH} from "../../utils/utils";
import DeleteAccount from "../DeleteAccount/DeleteAccount";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import { useTranslation } from 'react-i18next';
import {useLocation} from "react-router-dom";

const FooterComponent: React.FC = () => {
    const {handleLogout, isMobileWidth, currentUser} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const isIos = isPlatform("ios");
    const { t } = useTranslation();
    const location = useLocation();
    const isProfilePage: boolean = location.pathname === PROFILE_PATH;

    return (
        <IonFooter className="footer-component">
            <IonToolbar className="footer-toolbar-component">
                <IonButtons className="buttons-container" slot="start">
                    {currentUser && <IonButton className="footer-button button-small" fill="clear" onClick={handleLogout}>
                        {t("Log out")}
                    </IonButton>}

                    <IonButton className="footer-button button-small-variant" fill="clear" href={PRIVACY_POLICY_URL} target="_blank">
                        {t("Privacy Policy")}
                    </IonButton>

                    {isIos && isMobileWidth && currentUser && isProfilePage &&
                        <DeleteAccount isMobileWidth={isMobileWidth} isComponentVisible={isComponentVisible}/>
                    }
                </IonButtons>
            </IonToolbar>
        </IonFooter>
    );
}

export default FooterComponent;