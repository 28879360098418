import React, {useEffect, useState} from "react";
import {IonLabel, IonCheckbox, IonItem} from "@ionic/react";
import "./AssessmentQuestion.scss";

interface checkboxAssessmentQuestionProps {
    assessmentQuestion: string,
    onValueChange: (array: string[]) => void,
    assessmentValueOptions: any[],
    checkboxLimit: number,
}

export const CheckboxAssessmentQuestion: React.FC<checkboxAssessmentQuestionProps> = (props: checkboxAssessmentQuestionProps) => {

    const [answersChecked, setAnswersChecked] = useState<boolean[]>([]);
    const [assessmentAnswers, setAssessmentAnswers] = useState<string[]>([]);

    useEffect(() => {
        initializeAnswersCheckedArray();
        setAssessmentAnswers([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.assessmentQuestion])

    function initializeAnswersCheckedArray () {
        let initialArray: boolean[] = [];
        props.assessmentValueOptions.forEach(() => {
            initialArray.push(false);
        })
        setAnswersChecked(initialArray);
    }

    function handleValueChange (indexSelected: number, value: string, event: any) {
        let dummyArr = [...answersChecked];
        dummyArr[indexSelected] = !dummyArr[indexSelected];
        setAnswersChecked(dummyArr);

        if(dummyArr[indexSelected]){
            addAnswer(value);
        } else {
            removeAnswer(value);
        }
    }

    function addAnswer (value: string) {
        let assessmentAnswersCopy: string[] = [...assessmentAnswers];
        assessmentAnswersCopy.push(value);
        setAssessmentAnswers(assessmentAnswersCopy);
        props.onValueChange(assessmentAnswersCopy);
    }

    function removeAnswer (value: string) {
        let assessmentAnswersCopy: string[] = assessmentAnswers.filter((e: string) => e !== value);
        setAssessmentAnswers(assessmentAnswersCopy);
        props.onValueChange(assessmentAnswersCopy);
    }

    return(
        <div className="assessment-question-component">
            <div className="assessment-question-copy header-4">{props.assessmentQuestion}</div>
            <div className="assessment-items-container">
                {props.assessmentValueOptions.map((value: any, index: number) => {
                    return(
                        <IonItem
                            key={index}
                            className={`assessment-checkbox-item ${assessmentAnswers.includes(value) ? "selected" : ""}`}>
                            <IonCheckbox
                                className={`assessment-checkbox ${assessmentAnswers.includes(value) ? "selected" : ""}`}
                                onClick={(event) => handleValueChange(index, value, event)}
                                checked={answersChecked[index]}
                                disabled={(assessmentAnswers.length === props.checkboxLimit) && !answersChecked[index]}/>
                            <IonLabel className={`assessment-label button-medium ${assessmentAnswers.includes(value) ? "selected" : ""}`}>
                                {value}
                            </IonLabel>
                        </IonItem>
                    )
                })}
            </div>
        </div>
    );
}
