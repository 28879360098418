import React, {useContext} from 'react';
import {ApiAcuityAppointment} from "../../utils/ApiTypes";
import "./Appointment.scss";
import {IonButton} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { useTranslation } from 'react-i18next';
import AppointmentInformation from '../AppointmentInformation/AppointmentInformation';

interface AppointmentProps{
    appointment: ApiAcuityAppointment;
    onCancelButtonClicked: () => void;
    onRescheduleButtonClicked: () => void;
}

export function Appointment (props: AppointmentProps) {

    const { t } = useTranslation();
    const {isMobileWidth} = useContext(ApplicationContext);

    return(
        <div className={"upcoming-appointment-component"}>
            <div className={`${isMobileWidth ? "header-6-variant" : "h6-bold"} upcoming-appointment-title`}>
                {t("Your upcoming appointment:")}
            </div>
            <div className={"appointment-information-wrapper"}>
                <AppointmentInformation appointment={props.appointment} className={"talk-to-someone"} includeDuration={false} includeVirtualAppointmentText={false}/>
            </div>
            <div className={"upcoming-appointment-buttons-container"}>
                <IonButton
                    className={"button-small-variant upcoming-appointment-button"}
                    onClick={props.onRescheduleButtonClicked}>
                    {t('reschedule')}
                </IonButton>
                <IonButton
                    className={"button-small-variant upcoming-appointment-button"}
                    onClick={props.onCancelButtonClicked}>
                    {t('cancel')}
                </IonButton>
            </div>
        </div>
    )
}