import "./ArticleStyle.scss";
import { WPOContentItem } from "../../../../utils/ApiTypes";
import { Link } from "react-router-dom";
import newsIcon from '../../../../assets/images/news-gray.svg';
import { EventHandler } from "react";

export type ArticleStyleProps = {
    content: WPOContentItem;
    onClick?: EventHandler<any>;
};


export function ArticleStyle(props: ArticleStyleProps)  {
    const { content, onClick } = props;

    return(
        <div className="article-style-component">
            <Link className="article-style-component-link" to={content.path} onClick={onClick ? onClick : () => {}}>
                <div className="container">
                    <div className="image">
                        <img className="icon" alt="Article icon" src={newsIcon}/>
                    </div>
                    <div className="title-container h6-bold">
                        <p className="title-size-modifier">
                            {content.title}
                        </p> 
                    </div>
                </div>
            </Link>
        </div>
    )
}