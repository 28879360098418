import {User} from "../utils/ApiTypes";
import ClientConfig from "../utils/ClientConfig";
import { JourneyApiClient } from "../utils/JourneyApiClient";

/**
 * Perform any front-end cleanup needed to allow for a "clean" demo session,
 * and request the same from the back-end DemoResetService
 */
class EnvironmentSwitcherService {
    canSwitchEnvironments(user: User): boolean {
        return user.userRoles?.includes('Tester') || user.userRoles?.includes('Engineer');
    }

    getCurrentEnvironment(): string {
        return ClientConfig.environment;
    }

    getDevUrl(): string {
        return ClientConfig.getDevelopmentRoot();
    }

    switchToEnvironment(environment: string, rootUrl: string | null): void {
        ClientConfig.storeEnvironment(environment, rootUrl);
        JourneyApiClient.logout();
        window.location.replace('/');
    }
}

const environmentSwitcherService = new EnvironmentSwitcherService();
export default environmentSwitcherService;
