
export default class NavigationHelper {
    static getURLParams(urlHash: Map<string, string>, path: string): Map<string, string>{
        const pathArray = path.split("/");
        let i = 1;
        urlHash.forEach((value: string, key: string) => {
            if(i <= pathArray.length - 1){
                urlHash.set(key, pathArray[i]);
            }
            i++;
        });
        return urlHash;
    }

    static getParam(paramIndex: number, path: string): string {
        const pathArray = path.split("/");
        return pathArray[paramIndex];
    }
/**
 * 
 * @param url the url to check
 * @param key the query param key to look for in the url
 * @param value the expected value of the query param
 * @returns true if the url contains the key AND the value sent in the parameters is the same from the url
 */
    static checkURLQuery(url: string, key: string, value: string): boolean {
        // Create a URL object to easily access query parameters
        const searchParams = new URLSearchParams(url);
        // Get the value of the specified query parameter
        const paramValue = searchParams.get(key);
        
        // Check if the query parameter matches the given value
        return paramValue === value;
    }
}