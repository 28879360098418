import "./PleaseWait.scss";
import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

interface PleaseWaitProps{
    transparentBackground?: boolean;
    forefront?: boolean;
}

export const PleaseWait: React.FC<PleaseWaitProps> = (props: PleaseWaitProps) => {
    return(
        <div className={`please-wait-component ${props.transparentBackground && "transparent"} ${props.forefront && "please-wait-forefront"}`}>
            <div className="content animate__animated animate__fadeIn">
                <CircularProgress className="loading-spinner" size={100} />
            </div>
        </div>
    )
};
