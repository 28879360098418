import './ScheduleCarousel.scss';
import {IonButton, IonIcon} from "@ionic/react";
import ForwardArrow from "../../assets/images/forward-arrow-blue.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation} from "swiper";
import {v4 as uuidv4} from "uuid";
import {ApiClinician} from "../../utils/ApiTypes";
import {format} from "date-fns";
import {RadioButtonAssessmentQuestion} from "../Assessment/RadioButtonAssessmentQuestion";


export interface ScheduleCarouselProps{
    isMobileWidth: boolean;
    clinician: ApiClinician;
    onAppointmentClick: (timeslot: Date, clinician: ApiClinician) => void;
}

export function ScheduleCarousel(props: ScheduleCarouselProps) {
    const uniqueId = uuidv4();

    const timeslotsByDay = new Map<string, Array<Date>>();
    for (let slot of props.clinician.slots) {
        const date = new Date(slot);
        if (!timeslotsByDay.has(slot)) {
            timeslotsByDay.set(slot, []);
        }
        timeslotsByDay.get(slot)!.push(date);
    }

    return(
        <div className={`schedule-carousel`}>
            <IonButton fill="clear" className={`schedule_prev_${uniqueId} arrow-button left`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <IonButton fill="clear" className={`schedule_next_${uniqueId} arrow-button right`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <Swiper
                className="schedule-swiper"
                modules={[Navigation, FreeMode]}
                scrollbar={false}
                freeMode={true}
                navigation={{
                    nextEl: `.schedule_next_${uniqueId}`,
                    prevEl: `.schedule_prev_${uniqueId}`,
                    disabledClass: 'hide-arrow'
                }}
                spaceBetween={15}
                slidesPerView={props.isMobileWidth ? 2 : 5} >
                {
                    Array.from(timeslotsByDay.entries()).map(([day, times], i) => {
                        const timeStringToDateMap = new Map<string, Date>(times.map(t => [t.toLocaleTimeString(undefined, {hour: 'numeric', minute:'2-digit'}), t ]));
                        return(
                            <SwiperSlide key={i}>
                                <div className={"date-tile"}>
                                    <div className="date-container overline">
                                        {format(new Date(day), 'EE, MMM d')}
                                    </div>
                                    <RadioButtonAssessmentQuestion
                                        className="schedule-carousel-radio-button"
                                        questionClassName="header-6"
                                        clearAnswerOnSelection={true}
                                        assessmentQuestion={""}
                                        assessmentValueOptions={Array.from(timeStringToDateMap.keys())}
                                        onValueChange={(timeSlot: string) => props.onAppointmentClick(timeStringToDateMap.get(timeSlot)!, props.clinician)}
                                    />
                                </div>
                            </SwiperSlide>

                        )
                    })
                }
            </Swiper>

        </div>
    )
}