import React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import "./ResourcesBackButton.scss";
import leftArrow from "../../assets/images/left-arrow-black.svg";
import {useHistory} from "react-router";
import { useTranslation } from 'react-i18next';
type ResourcesBackButtonProps = {
    className?:string
}
export const ResourcesBackButton: React.FC<ResourcesBackButtonProps> = (props: ResourcesBackButtonProps) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();

    return(
        <div className={`resources-back-button-component ${props.className}`} onClick={() => history.goBack()}>
            <IonIcon className="resources-back-icon" icon={leftArrow}/>
            {i18n.exists('Back') && <IonButton className="resources-back-button button-small" fill="clear"><span>{t('Back')}</span></IonButton>}
        </div>
    );
}
