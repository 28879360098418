import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import {useHistory} from "react-router-dom";
import "./ChampionRegistrationPage.scss";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import {
    HOME_PATH
} from "../../utils/utils";
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';

export const ChampionRegistrationPage: React.FC = () => {
    const [isOptInCompleted, setOptInCompleted] = useState<boolean>(false);
    const {handleApplicationError} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();

    useEffect(() => {
        (async function() {
            if(isComponentVisible){
                try {
                    await JourneyApiClient.setUserAsMhamChampion();
                    setOptInCompleted(true);
                } catch(e) {
                    handleApplicationError("Unable to opt in as a Champion. Try again!");
                    history.push(HOME_PATH);
                }
            }
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className={`championship-registration-page`}>

            <ResponsiveNavigationMenu title={"Championship Opt In"} />

            <IonContent className="championship-page-content">
                {isOptInCompleted ?
                    <SuccessMessage
                        isComponentVisible={isComponentVisible}
                        redirectToHome={true}
                        message={`Thank you for being a Champion for the Mental Health Awareness Month Challenge! Please check your email for more information.`}
                    />
                    :
                    <PleaseWait/>
                }
            </IonContent>
        </IonPage>
    )
};
