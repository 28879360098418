import {SyntheticEvent} from 'react';
import "./VideoStyle.scss";
import {IonIcon} from "@ionic/react";
import Play from "../../../../assets/images/play.svg";
import {VideoResponse} from "../../../../utils/ApiTypes";
import {BACKGROUND_TILE_COLOR} from "../../../../utils/utils";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";
import { randomGenerator } from '../../../../utils/RandomGenerator';

export type VideoStyleProps = {
    video: VideoResponse;
    progress: number;
    durationMinutes: string;
    durationSeconds: string;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
    industryClass: string;
};

export function VideoStyle(props: VideoStyleProps)  {
    let rand = randomGenerator(props.video.id);
    const colorId = Math.floor(rand() * BACKGROUND_TILE_COLOR.length);
    const thumbnailUrl = props.video.thumbnailS3Path ?? props.video.teacher?.videoThumbnailImageS3Path;
    const isStandardColor = props.industryClass === "standard";

    return(
        <div className={`video-style-component ${isStandardColor ? BACKGROUND_TILE_COLOR[colorId] : "industry-targeting-color-container"}`}>
            <div className={`image-container ${props.progress ? 'progress-visible' : ''}`}>
                {
                    thumbnailUrl && 
                    <img className={"image"} alt="Video thumbnail" src={`${thumbnailUrl}?fit=max&h=200&fm=webp`}/>
                }
                <div className={"title-container"}>
                    <div className={"title subtitle2-variant"}>
                        <p className={`title-size-modifier ${props.video.title.length > 40 ? 'small' : ''}`}>{props.video.title}</p>
                    </div>
                    <div className={"author-container"}>
                        <div className={"author-name overline"}>{props.video.teacher?.firstName}</div>
                    </div>
                </div>
                {props.durationMinutes ?  <div className="duration caption">{props.durationMinutes}:{props.durationSeconds}</div>: ""}
                <IonIcon className="play-icon" src={Play} />
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>
            </div>
        </div>
    )
}