import React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import close from "../../assets/images/close-icon-gray.svg";
import "./ExitButton.scss";

interface ExitButtonProps {
    onClick: () => void;
}

export const ExitButton: React.FC<ExitButtonProps> = (props: ExitButtonProps) => {
    return(
        <div className="exit-button-component">
            <IonButton className="exit-button" fill="clear" onClick={props.onClick}>
                <IonIcon icon={close}/>
            </IonButton>
        </div>
    );
}
