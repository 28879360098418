import "./MuxVideoPlayer.scss";
import {FC, useEffect, useMemo, useState, useContext} from "react";
import MuxPlayer from "@mux/mux-player-react";
import {ApiUser} from "../../utils/ApiTypes";
import * as React from "react";
import LodashDebounce from "lodash.debounce";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import * as Sentry from "@sentry/react";
import {LoginModalComponent} from "../LoginModalComponent/LoginModalComponent";
import {NextRecommendation} from "../NextRecommendation/NextRecommendation";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { useTranslation } from 'react-i18next';

interface MuxVideoPlayerProps{
    videoId: number;
    muxPlaybackId: string | null;
    muxVideoId: string | null;
    title: string | null;
    currentUser: ApiUser | null;
    trackCuePointEvent: (cuePoint: number) => {};
    trackPlayEvent: () => {};
    trackPauseEvent: () => {};
    trackUnmute?: () => void;
    onVideoEnd?: () => void;
    thumbnail?: string | null;
    isComponentVisible: boolean;
    isLoading: boolean;
    handleApplicationError?: (description: string) => void;
    duration: number;
    position?: number;
    autoplay?: boolean;
    isInitialMuted?: boolean;
    isPublicVideo?: boolean;
    hideInitialControls?: boolean;
    playlistId?: number;
    navigateTo?: (path: string) => void;
    numberOfRecommendationsToShow: number;
    hideRecommendations?: boolean;
    replayVideo?: boolean;
}

export const MuxVideoPlayer: FC<MuxVideoPlayerProps> = (props: MuxVideoPlayerProps) => {

    const videoElementRef = React.useRef<any>(null);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [isVolumeChangedTracked, setIsVolumeChangedTracked] = useState<boolean>(false);
    const [isVideoEndingSoon, setIsVideoEndingSoon] = useState<boolean>(false);
    const [isVideoEnded, setIsVideoEnded] = useState<boolean>(false);
    const [hideControlsAfterVideoEnd, setHideControlsAfterVideoEnd] = useState<boolean>(false);
    const playerUUID = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);
    const { currentlyPlayingId, setCurrentlyPlayingId } = useContext(ApplicationContext);
    const { t } = useTranslation();

    useEffect( () => {
        if(videoElementRef && videoElementRef.current && currentlyPlayingId !== playerUUID){
            videoElementRef.current.pause();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentlyPlayingId]);

    useEffect( () => {
        if(props.isComponentVisible && videoElementRef && videoElementRef.current){
            videoElementRef.current.play().catch((error: any) => Sentry.captureException(error.message));

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[videoElementRef])

    useEffect( () => {
        if(!props.isComponentVisible && videoElementRef.current){
            videoElementRef.current.pause();
            videoElementRef.current = null;
            setIsVideoEndingSoon(false);
            setIsVideoEnded(false);
            setHideControlsAfterVideoEnd(false);
        }
    },[props.isComponentVisible])

    function handleVolumeChange() {
        if(!isVolumeChangedTracked && props.isInitialMuted){
            setIsVolumeChangedTracked(true);
            if (props.trackUnmute) props.trackUnmute();
        }
    }

    function handleTimeChange () {
        if(videoElementRef.current){
            const currentTime = Math.round(videoElementRef.current.currentTime);
            if(props.duration && currentTime && !isNaN(currentTime)){
                if (currentTime % 5 === 0) {
                    JourneyApiClient.logVideoWatch(props.videoId, currentTime, props.duration);
                }

                if(currentTime === Math.round(props.duration/4)){
                    props.trackCuePointEvent(25);
                } else if(currentTime === Math.round(props.duration/2)){
                    props.trackCuePointEvent(50);
                } else if(currentTime === Math.round(3*(props.duration/4))){
                    props.trackCuePointEvent(75);
                } else if(currentTime === Math.round(props.duration * 0.99)){
                    props.trackCuePointEvent(99);
                    setIsVideoEndingSoon(true);
                    setHideControlsAfterVideoEnd(true);
                }
            }
        }
    }


    function handleLoggedOutPlaying () {
        if(!props.currentUser && !props.isPublicVideo){
            setIsLoginModalOpen(true);
            if(videoElementRef && videoElementRef.current){
                videoElementRef.current.pause();
            }
        }
    }

    function handlePlayEvent() {
        setCurrentlyPlayingId(playerUUID);
        props.trackPlayEvent();
        if (props.currentUser) {
            JourneyApiClient.recordActivity('video-watch');
        }
    }

    function handleEndEvent() {
        setIsVideoEnded(true)
        if (props.onVideoEnd) {
            props.onVideoEnd();
        }
    }

    const debouncedHandleTime = LodashDebounce(handleTimeChange, 900, {maxWait: 900})

    return(
        <div className={`mux-video-player-component ${(props.hideInitialControls || hideControlsAfterVideoEnd) ? 'no-initial-controls' : ''}`}>
            <LoginModalComponent
                isComponentVisible={props.isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />

            {props.isLoading ? <></> : (props.muxPlaybackId && props.muxVideoId) ?
                <div className={"mux-player-and-recommendations-container"}>
                    <MuxPlayer
                        streamType="on-demand"
                        startTime={props.position ?? 0}
                        ref={videoElementRef}
                        playbackId={props.muxPlaybackId}
                        poster={props.thumbnail ? props.thumbnail : ""}
                        onPlaying={handleLoggedOutPlaying}
                        onPlay={handlePlayEvent}
                        onPause={props.trackPauseEvent}
                        onTimeUpdate={debouncedHandleTime}
                        onVolumeChange={handleVolumeChange}
                        autoPlay={props.autoplay}
                        muted={props.isInitialMuted ?? false}
                        onEnded={handleEndEvent}
                        loop={props.replayVideo}
                        metadata={{
                            video_id: props.videoId,
                            video_title: props.title,
                            viewer_user_id: props.currentUser ? props.currentUser.id : null,
                            player_name: 'vod-player',
                        }}/>
                    {!props.hideRecommendations &&
                    <NextRecommendation
                        videoId={props.videoId}
                        playlistId={props.playlistId}
                        isVideoEndingSoon={isVideoEndingSoon}
                        hasVideoFinished={isVideoEnded}
                        isComponentVisible={props.isComponentVisible}
                        numberOfRecommendationsToShow={props.numberOfRecommendationsToShow}
                    />}
                </div>
                : <div className="mux-player-error-copy">{t("There was an error displaying this video.")}</div>
            }
        </div>
    );
}
