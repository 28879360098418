import './BenefitsPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {GetCompanyBenefitsInfoResponse} from "../../utils/ApiTypes";
import {Benefits} from "../../components/Benefits/Benefits";
import {IonContent, IonPage} from '@ionic/react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { useTranslation } from 'react-i18next';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';

export const BenefitsPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [companyBenefitsInfo, setCompanyBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const {handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const { t } = useTranslation();

    useEffect(() => {
        if(isComponentVisible){
            JourneyApiClient.getCompanyBenefitsInfo()
                .then(setCompanyBenefitsInfo)
                .catch((error) => {
                    handleGeneralError("Could not get company benefits or create user engagement", error);
                })
                .finally(() => setIsLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className={`benefits-page`} id="main-content">

            <ResponsiveNavigationMenu title={t('benefits')} />

            <IonContent>
                {
                    (function() {
                        if (isLoading) {
                            return <PleaseWait/>;
                        } else if (companyBenefitsInfo && !isLoading) {
                            return <Benefits benefitsInfo={companyBenefitsInfo} currentUser={currentUser} />;
                        } else {
                            return <div>{t("Something went wrong.")}</div>;
                        }
                    })()
                }
            </IonContent>
        </IonPage>
    );

}
