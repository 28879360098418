import './PersonalPlanPage.scss';
import * as React from 'react';
import {useState} from 'react';
import {IonContent, IonIcon, IonPage} from "@ionic/react";
import { checkmarkCircle, chevronDown, ellipsisHorizontalCircle, lockClosed } from 'ionicons/icons';
import { VideoTile } from '../../components/ContentTiles/VideoTile';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { VideoResponse } from '../../utils/ApiTypes';

// @ts-ignore
const weekOneVideos = [{
        "duration": 895,
        "title": "Stress Relief 101",
        "id": 11791,
        "liveRecordingDate": null,
        "muxPlaybackId": "JSVznSZpsMzxJbm5zjSgbCvfCWac5Lyc9fmlHMYXFbw",
        "muxVideoId": "r02pSm9p49qe026zMBGGhti383iiDLY43NTHk2a01bG5lM",
        "path": "/watch/11791",
        "playbackId": "JSVznSZpsMzxJbm5zjSgbCvfCWac5Lyc9fmlHMYXFbw",
        "thumbnailStyle": "video",
        "type":"video",
        "teacher": {
            firstName: "Lodro",
            videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Lodro_Rinzler.png',
        }
    }, {
        "duration": 992,
        "title": "How to Manage Stress",
        "id": 11788,
        "liveRecordingDate": null,
        "muxPlaybackId": "DzHD6tqfrDAmZhfgv1tPmmyA9ZNMXato94M9dodEVd00",
        "muxVideoId": "vPLJNaMlKh5FVF2T6V4WIaKLvI3E5YpMu4zITxiMfeI",
        "path": "/watch/11788",
        "playbackId": "DzHD6tqfrDAmZhfgv1tPmmyA9ZNMXato94M9dodEVd00",
        "teacher": {
            firstName: 'Amanda',
            videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Amanda_Gilbert.png'
        },
        "thumbnailStyle": "video",
        "type":"video"
    }, {
        "duration": 773,
        "id": 10872,
        "title": "Stress Management 101",
        "liveRecordingDate": null,
        "muxPlaybackId": "ID00piTXPtWP0166jXZUDFbtf2fiSkKI1101kWGnSHAbQ00",
        "muxVideoId": "WCo6XvOug7qJSORGK5CBtP389ssyD00NwHy0201dGAD5ac",
        "path": "/watch/10872",
        "playbackId": "ID00piTXPtWP0166jXZUDFbtf2fiSkKI1101kWGnSHAbQ00",
        "teacher": {
            firstName: 'Hector',
            videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Hector_Marcel.png'
        },
        "thumbnailStyle": "video",
        "type":"video"
    }, {
        "duration": 772,
        "id": 10658,
        "title": "Mental Wellness for Parents",
        "liveRecordingDate": null,
        "muxPlaybackId": "G021T01dk02yfRUa4bPDnd9DHocQ1mgdX7tZ00T6RGrwenw",
        "muxVideoId": "T1JI66Gf84pbfbs5HsZFuzFPD4V1cQZitI76AIIXMUQ",
        "path": "/watch/10658",
        "playbackId": "G021T01dk02yfRUa4bPDnd9DHocQ1mgdX7tZ00T6RGrwenw",
        "teacher": {
            firstName: 'Jackie',
            videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Jackie_Stewart.png'
        },
        "thumbnailStyle": "video",
        "type":"video"
    }, {
        "duration": 915,
        "id": 11785,
        "title": "Release Stressful Thoughts",
        "liveRecordingDate": null,
        "muxPlaybackId": "vZ7NYynLU8XNdIepb002xsqfj8OOv9I64802wLM017u6NA",
        "muxVideoId": "KV01A1MYiDvdSa4iEobGBtyqV00KHvI9QL2YR00rpZ9i02w",
        "path": "/watch/11785",
        "playbackId": "vZ7NYynLU8XNdIepb002xsqfj8OOv9I64802wLM017u6NA",
        "teacher": {
            firstName: 'Cheryl',
            videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Cheryl_Brause.png'
        },
        "thumbnailStyle": "video",
        "type":"video"
}] as VideoResponse[];
// @ts-ignore
const weekTwoVideos = [{
    "duration": 888,
    "title": "Breaking the Habit of Stress",
    "id": 11792,
    "liveRecordingDate": null,
    "muxPlaybackId": "AZq5Xwu5GeUw00V1011eDcQjwppweXokw4rB6Ce8UC4a00",
    "muxVideoId": "x6ajqEfA312kA4rvvGSGsiNcIV02faA8ysG02zJA02XsNc",
    "path": "/watch/11791",
    "playbackId": "AZq5Xwu5GeUw00V1011eDcQjwppweXokw4rB6Ce8UC4a00",
    "thumbnailStyle": "video",
    "type":"video",
    "teacher": {
        firstName: "Lodro",
        videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Lodro_Rinzler.png',
    }
}, {
    "duration": 425,
    "title": "How to Manage Stress",
    "id": 10518,
    "liveRecordingDate": null,
    "muxPlaybackId": "B2K6kbHxl3d00vipMJ7XDrMeE2XtWXp1G01x7tByzev5c",
    "muxVideoId": "uGO1Zw5900l7WTu2vMMN01LSaDTBooEQgnJVQGQ6zTmmQ",
    "path": "/watch/10518",
    "playbackId": "B2K6kbHxl3d00vipMJ7XDrMeE2XtWXp1G01x7tByzev5c",
    "teacher": {
        firstName: 'Anahita',
        videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Anahita_Moghaddam.png'
    },
    "thumbnailStyle": "video",
    "type":"video"
}, {
    "duration": 394,
    "id": 11245,
    "title": "Parting Ways with Stress",
    "liveRecordingDate": null,
    "muxPlaybackId": "oi00aqFSf01iRFeem5rX02kpnR3PVG2GDFBquyg202jQOK8",
    "muxVideoId": "gdwkeYiIA1skJjuWc24fLUGBgsXiVm1lYXv6gEPA3vo",
    "path": "/watch/11245",
    "playbackId": "oi00aqFSf01iRFeem5rX02kpnR3PVG2GDFBquyg202jQOK8",
    "teacher": {
        firstName: 'Cheryl',
        videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Cheryl_Brause.png'
    },
    "thumbnailStyle": "video",
    "type":"video"
}, {
    "duration": 774,
    "id": 2816026,
    "title": "You Belong Here, Overcoming Imposter Syndrome",
    "liveRecordingDate": null,
    "muxPlaybackId": "Ma3AJj9QVlTkV22ZYN8eLMHeuyrCPvNt300c8i200kW2E",
    "muxVideoId": "85mEfavxJGTB5Hg3y8W5yFYSQz02Uh88Za026zxGOqfCg",
    "path": "/watch/2816026",
    "playbackId": "Ma3AJj9QVlTkV22ZYN8eLMHeuyrCPvNt300c8i200kW2E",
    "teacher": {
        firstName: 'Cojuan',
        videoThumbnailImageS3Path: 'https://journey-live-public-images.s3.us-west-2.amazonaws.com/development/teachers/Cojuan_Cabassa.png'
    },
    "thumbnailStyle": "video",
    "type":"video"
}] as VideoResponse[];

export const PersonalPlanPage: React.FC = () => {

    const [weekOneOpen, setWeekOneOpen] = useState<boolean>(false);
    const [weekTwoOpen, setWeekTwoOpen] = useState<boolean>(true);

    const currentDate = new Date();
    const monthName = currentDate.toLocaleString('default', { month: 'long' });
    const currentYear = currentDate.getFullYear();
    const currentDay = currentDate.getDate();
    const totalDays = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();
    let progressPercentage = (currentDay / totalDays) * 100;
    // Obtain the nearest factor of 20%
    progressPercentage = Math.round(progressPercentage / 20) * 20;
    // Remove 0% values at the beginning of the month
    progressPercentage = Math.round(Math.max(progressPercentage, 20));

    return (
        <IonPage className={`personal-plan-page`}>

            <ResponsiveNavigationMenu title={"Personal Plan"} />

            <IonContent className='personal-plan-content'>
                <div className='page-header'>
                    <div className='title-container'>
                        <p className='subtitle1'>{monthName.toUpperCase()} {currentYear}</p>
                        <h1 className='header'>Managing Stress</h1>
                        <p className='copy'>Here is your personal plan for the next {totalDays} days to improve stress levels. We’ve made it easy to follow and fit into your schedule. See below for the week by week breakdown of your plan. We ordered the recommendations to build on skills and concepts.</p>
                    </div>
                    <div className='dashboard-container'>
                        <div className='dashboard'>
                            <h3>Personal Dashboard</h3>
                            <div className='row'><h4>Assessment</h4><span>Moderate</span></div>
                            <div className='row'><h4>Progress</h4><span>{progressPercentage}% Complete</span></div>
                            <div className='row'><h4>Total Days</h4><span>{currentDay}/{totalDays} days</span></div>
                        </div>
                    </div>
                </div>
                <div className='page-content'>

                    <div className={"industry-targeting-row-wrapper"}>
                        <section className={`accordion ${weekOneOpen ? 'open' : ''}`}>
                            <header onClick={() => setWeekOneOpen(!weekOneOpen)}>
                                <div className='week-chip completed'><IonIcon src={checkmarkCircle}/> Week 1</div>
                                <h2>Understanding Stress</h2>
                                <IonIcon className='down-icon' size='large' src={chevronDown}/>
                            </header>
                            <div className='content'>
                                {weekOneVideos.map(video => <VideoTile showTitle={false} showDescription={false} className='video-thumbnail' video={video} />)}
                            </div>
                        </section>
                    </div>
                    <div className={"industry-targeting-row-wrapper"}>
                        <section className={`accordion ${weekTwoOpen ? 'open' : ''}`}>
                            <header onClick={() => setWeekTwoOpen(!weekTwoOpen)}>
                                <div className='week-chip in-progress'><IonIcon src={ellipsisHorizontalCircle}/> Week 2</div>
                                <h2>Healthy Ways to Manage Stress</h2>
                                <IonIcon className='down-icon' size='large' src={chevronDown}/>
                            </header>
                            <div className='content'>
                                {weekTwoVideos.map(video => <VideoTile showTitle={false} showDescription={false} className='video-thumbnail' video={video} />)}
                            </div>
                        </section>
                    </div>

                    <section className={`accordion ${weekTwoOpen ? 'open' : ''}`}>
                        <header onClick={() => setWeekTwoOpen(!weekTwoOpen)}>
                            <div className='week-chip disabled'><IonIcon src={lockClosed}/> Week 3</div>
                            <h2>Getting a Good Night’s Sleep</h2>
                        </header>
                    </section>

                    <section className={`accordion ${weekTwoOpen ? 'open' : ''}`}>
                        <header onClick={() => setWeekTwoOpen(!weekTwoOpen)}>
                            <div className='week-chip disabled'><IonIcon src={lockClosed}/> Week 4</div>
                            <h2>Exercise to Reduce Stress</h2>
                        </header>
                    </section>
                </div>
            </IonContent>
        </IonPage>
    );

};
