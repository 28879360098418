import {SyntheticEvent} from 'react';
import "./SolidColorStyle.scss";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";
import {BACKGROUND_TILE_COLOR} from "../../../../utils/utils";
import { useTranslation } from 'react-i18next';
import { randomGenerator } from '../../../../utils/RandomGenerator';

export type VideoStyleProps = {
    id: number;
    thumbnailUrl: string;
    tileTitle: string;
    contentTitle: string;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
    industryClass: string;
};

export function SolidColorStyle(props: VideoStyleProps)  {

    const { t } = useTranslation();
    let rand = randomGenerator(props.id);
    const colorId = Math.floor(rand() * BACKGROUND_TILE_COLOR.length);
    const isStandardColor = props.industryClass === "standard";

    return(
        <div className={`solid-color-style-component ${!isStandardColor && "industry-targeting-color-container"}`}>
            <div className={`image-container  ${isStandardColor ? BACKGROUND_TILE_COLOR[colorId] : "industry-targeting-color-container"}`}>
                {props.thumbnailUrl && <img className={"image"} alt="Teacher thumbnail" src={`${props.thumbnailUrl}?fit=max&h=200&fm=webp`}/>}
                <div className={"tile-name-container"}>
                    <div className={"tile-name overline"}>{props.tileTitle ?? t('teacher')}</div>
                </div>
                <div className={"author-container"}>
                    <div className={"author-name video-feature-tile"}>{props.contentTitle}</div>
                </div>
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>
            </div>
        </div>
    )
}