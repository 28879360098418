import "./InstantHelpButton.scss";
import {IonButton} from "@ionic/react";
import { MouseEventHandler} from 'react';
import { useTranslation } from 'react-i18next';

export type InstantHelpButtonProps = {
    onClick?: MouseEventHandler;
}

export const InstantHelpButton: React.FC<InstantHelpButtonProps> = (props: InstantHelpButtonProps) => {

    const { t } = useTranslation();

    return (
        <IonButton className="toolbar-button button-small instant-help-button button-small-variant" fill="clear" onClick={props.onClick} >
            {t("Help Now")}
        </IonButton>
    );
}
