import React, {useContext} from "react";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import "./WorklifeButtonsContainer.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useQuery } from "@tanstack/react-query";
import { ApiResourcesContentDivision } from "../../utils/ApiTypes";
import { WorklifeButtonsContainer } from "./WorklifeButtonsContainer";


export const WorklifeComponent: React.FC = () => {

    const { handleGeneralError } = useContext(ApplicationContext);

    async function getWorklifeContent(): Promise<ApiResourcesContentDivision[] | undefined> {
        try {
            return await JourneyApiClient.getWorklifeContentDivisions();
        } catch (e) {
            handleGeneralError("Could not fetch Work Life content", e);
        }
    }
    const worklifeQuery = useQuery<Promise<ApiResourcesContentDivision[] | undefined>, unknown, ApiResourcesContentDivision[]>
        (["getWorklifeContentDivisions"], getWorklifeContent);
    
    return(
        <div className="worklife-component">
            {worklifeQuery.status === "success" && worklifeQuery.data.length > 0 &&
                <WorklifeButtonsContainer worklifeContent={worklifeQuery.data} />}
        </div>
    );
}
