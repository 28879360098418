import { IonItem, IonList } from '@ionic/react';
import React, { useContext, useState } from 'react';
import './IndustrySelectionList.scss';
import { ApplicationContext } from '../../misc/ApplicationContext';

type IndustrySelectionListProps = {
    onSelect: (industry: string) => void;
    isOpen: boolean;
};

export function IndustrySelectionList({ onSelect, isOpen } : IndustrySelectionListProps) {
    const [selectedIndustry, setSelectedIndustry] = useState('');
    const { industryCssClass } = useContext(ApplicationContext);

    const handleSelect = (industry: string) => {
        setSelectedIndustry(industry);
        onSelect(industry);
    };

    return (
        <IonList className={`industry-selection-list-component`}>
            <IonItem  className={`menu-item`} onClick={async () => await handleSelect("standard")}>
                <div className={`caption ${industryCssClass === "standard" && "selected"}`}>
                    Standard
                </div>
            </IonItem>
            <IonItem  className={`menu-item`} onClick={async () => await handleSelect("law")}>
                <div className={`caption ${industryCssClass === "law" && "selected"}`}>
                    Law
                </div>
            </IonItem>
            <IonItem  className={`menu-item`} onClick={async () => await handleSelect("retail")}>
                <div className={`caption ${industryCssClass === "retail" && "selected"}`}>
                    Retail
                </div>
            </IonItem>
            <IonItem  className={`menu-item`} onClick={async () => await handleSelect("manufacturing")}>
                <div className={`caption ${industryCssClass === "manufacturing" && "selected"}`}>
                    Manufacturing
                </div>
            </IonItem>
        </IonList>
    );
};