import * as React from "react";
import "./InstantHelpSlider.scss";
import {
    IonIcon,
    IonModal,
    createAnimation
} from "@ionic/react";
import PhoneIcon from '@mui/icons-material/Phone';
import ChatIcon from '@mui/icons-material/Chat';
import CloseIcon from '../../assets/images/close-icon-gray.svg';
import { useContext, useEffect, useState } from "react";
import { ApiResourcesContentLanguage, GetCompanyBenefitsInfoResponse } from "../../utils/ApiTypes";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { PhoneNumberList } from "./PhoneNumberList";
import { useTranslation } from 'react-i18next';
import { Feature } from "../Feature/Feature";
import { ApplicationContext } from "../../misc/ApplicationContext";

export type InstantHelpSliderProps = {
    isOpen?: boolean;
    handleModalDismiss: () => void;
}

export const InstantHelpSlider: React.FC<InstantHelpSliderProps> = (props: InstantHelpSliderProps) => {
    const { language } = useContext(ApplicationContext);
    const { t } = useTranslation();

    const enterAnimation = (baseElement: HTMLElement) => {
        const wrapperAnimation = createAnimation()
            .beforeStyles({
                transform: 'translateY(0) scale(1)'
            })
            .duration(250)
            .fromTo('transform', 'translateX(100%)', 'translateX(0)')
            .addElement(baseElement.shadowRoot!.querySelector('.modal-wrapper')!);

        return createAnimation()
            .addElement(baseElement)
            .addAnimation([wrapperAnimation]);
    };

    const leaveAnimation = (baseElement: any) => {
        const wrapperAnimation = createAnimation()
            .duration(250)
            .fromTo('transform', 'translateX(0)', 'translateX(100%)')
            .addElement(baseElement.shadowRoot!.querySelector('.modal-wrapper')!);

        return createAnimation()
            .addElement(baseElement)
            .addAnimation([wrapperAnimation]);
    };

    const getCountryCode = (language: Maybe<ApiResourcesContentLanguage>): string => {
        return language?.alpha2CodeISO || "us";
    }

    const [benefitsInfo, setBenefitsInfo] = useState<GetCompanyBenefitsInfoResponse | null>(null);
    const [havePhones, setHavePhones] = useState<boolean>(false);
    const [countryCode, setCountryCode] = useState<string>(getCountryCode(language));

    useEffect(() => {
        getResources();
    }, [props.isOpen]);

    useEffect(() => {
        setCountryCode(getCountryCode(language));
    }, [language]);

    const getResources = async () => {
        const benefitsInfo = await JourneyApiClient.getCompanyBenefitsInfo();
        setBenefitsInfo(benefitsInfo);
        setHavePhones(benefitsInfo?.supportPhones.length > 0);
    }

    const updateCountryCode = (code: string) => {
        setCountryCode(code);
    };

    return (
        <IonModal showBackdrop={true} className="instant-help-slider"
            isOpen={props.isOpen}
            onWillDismiss={props.handleModalDismiss}
            enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation}
        >
            <div className="ion-page">
                <IonIcon src={CloseIcon} className="instant-help-close-icon" aria-label="Close" onClick={props.handleModalDismiss} />
                <h4 className="header-4">{t("Instant Help and Support")}</h4>

                {havePhones && <Feature feature="has-eap">
                    <div className="phones">
                        <PhoneNumberList phones={benefitsInfo?.supportPhones} countryCode={countryCode} updateCountryCode={updateCountryCode} />
                    </div>
                </Feature>}

                <Feature feature="help-now-immediate-crisis-support">
                    <div className="emergency-numbers">
                        <p className="header-6"><PhoneIcon className="instant-help-icon" aria-label="Call" />{t("For a medical emergency, call 911")}</p>
                        <p className="header-6">
                            <ChatIcon className="instant-help-icon" aria-label="Chat" />
                            <div className="column">
                                {t("988 Suicide & Crisis Lifeline")}
                                <p className="caption">{t("Free and confidential national network of local crisis centers for people in emotional distress or suicidal crisis 24 hours a day, 7 days a week.")}</p>
                            </div>
                        </p>
                        
                    </div>
                </Feature>
                {/* {IsUSCode(countryCode) &&

                    <p className="header-6"><Chat  className="instant-help-icon" aria-label="Chat" />{t("Text “HELLO” to 741741")}</p>

                    <p className="caption">{t("The Crisis Text hotline is available 24 hours a day, 7 days a week to connect anyone, in any type of crisis, with a crisis counselor.")}</p>

                } */}
            </div>
        </IonModal>
    )
}
