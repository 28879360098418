import { differenceInDays } from 'date-fns'
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { useContext, useEffect, useState} from "react";
import placeholderOne from "../../assets/images/daily-practice-1.jpg";
import placeholderTwo from "../../assets/images/daily-practice-2.jpg";
import placeholderThree from "../../assets/images/daily-practice-3.jpg";
import placeholderFour from "../../assets/images/daily-practice-4.jpg";
import placeholderFive from "../../assets/images/daily-practice-5.jpg";
import "./DailyPractice.scss";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import { ApplicationContext } from '../../misc/ApplicationContext';
import AnalyticsService from '../../misc/AnalyticsService';
import { VideoResponse } from '../../utils/ApiTypes';
import { MONTHLY_ACTIVITY_INTENSITY_HIGH } from '../../utils/utils';
import {useLocation} from "react-router-dom";
import {MuxVideoPlayer} from "../MuxVideoPlayer/MuxVideoPlayer";
import { useTranslation } from 'react-i18next';
import { LocalizedImage } from '../LocalizedImage/LocalizedImage';

const placeHolderImages = [
    placeholderOne,
    placeholderTwo,
    placeholderThree,
    placeholderFour,
    placeholderFive
];

export type DailyPracticeProps = {
    className?: string
    state: 'load-video' | 'autoplay' | 'placeholder'
}

export function DailyPractice(props: DailyPracticeProps) {

    const [dailyPracticeVideo, setDailyPracticeVideo] = useState<VideoResponse|null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const { appResumeCounter, currentUser, isMobileWidth, language} = useContext(ApplicationContext);
    const [dayIndex, setDayIndex] = useState<number | null>(null);
    const [dailyPracticeWatched, setDailyPracticeWatched] = useState<boolean>(false);
    const { t } = useTranslation();

    async function fetchDailyPracticeVideo() {
        setIsLoading(true);
        const video = await JourneyApiClient.getDailyPractice();
        setDailyPracticeVideo(video);
        setIsLoading(false)
    }

    async function fetchTodaysActivity() {
        //The offset for timezone to the west of UTC is positive, and for timezone to the east of UTC is negative.  
        //Therefore if we ADD(sum) the offset in milliseconds, we will get the UTC time.
        //To convert offset to milliseconds, we multiply by 60 seconds and 1000 milliseconds.
        let timeOffsetInMilliseconds = (new Date().getTimezoneOffset() * 60 * 1000);
        const todayActivity = await JourneyApiClient.getTodaysActivityV2(timeOffsetInMilliseconds);
        if (todayActivity) {
            setDailyPracticeWatched(todayActivity.intensity === MONTHLY_ACTIVITY_INTENSITY_HIGH);
        } else {
            setDailyPracticeWatched(false);
        }
    }


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
        }
        JourneyApiClient.recordActivity('daily-practice-watch');
        await AnalyticsService.trackUserAction("daily_practice_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_practice_video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("daily_practice_video_checkpoint", location.pathname, eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: dailyPracticeVideo?.title,
            video_duration: dailyPracticeVideo?.duration,
            video_id: dailyPracticeVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_practice_video_unmute", location.pathname, eventProperties);
    }

    function init(state: 'load-video' | 'autoplay' | 'placeholder') {
        const daysSinceEpoch = differenceInDays(new Date(), new Date(0));
        const newDayIndex = daysSinceEpoch % placeHolderImages.length;
        setDayIndex(newDayIndex);
        if (state === 'placeholder') {
            setDailyPracticeVideo(null);
        } else {
            fetchDailyPracticeVideo();
            fetchTodaysActivity();
        }
    }

    useEffect(() => {
        if (!isComponentVisible) return;
        init(props.state);
    }, [isComponentVisible, appResumeCounter, props.state]);

    useEffect(() => {
        // We need this initial use effect because when this component is loaded after a state variable change, isComponentVisible is not updated
        init(props.state);
    }, []);


    useEffect(() => {
        if (!isComponentVisible || props.state === 'placeholder') return;
        fetchDailyPracticeVideo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    return(
        <div className={`daily-practice-component industry-targeting-daily-practice-background journey-card ${props.className ?? ''}`}>
            {dailyPracticeVideo ? <>
                <MuxVideoPlayer
                    thumbnail={dailyPracticeVideo.thumbnailUrl}
                    videoId={dailyPracticeVideo.id}
                    muxPlaybackId={dailyPracticeVideo.muxPlaybackId}
                    muxVideoId={dailyPracticeVideo.muxVideoId}
                    title={dailyPracticeVideo.title}
                    currentUser={currentUser}
                    trackCuePointEvent={trackCuePointEvent}
                    trackPlayEvent={trackPlayEvent}
                    trackPauseEvent={trackPauseEvent}
                    isComponentVisible={isComponentVisible}
                    isLoading={isLoading}
                    autoplay={props.state === 'autoplay'}
                    isInitialMuted={props.state === 'autoplay'}
                    duration={dailyPracticeVideo.duration}
                    trackUnmute={trackUnmute}
                    hideInitialControls={true}
                    hideRecommendations={true}
                    numberOfRecommendationsToShow={0}
                />
            </>:
                <div className={"daily-practice-padding-container"}>
                    <LocalizedImage className='background-image' i18nKey='daily-practice-image' fallback='https://journeylive.imgix.net/production/daily-practice-image-en.png?fm=webp' />
                    <div className="box-header">
                        <div className="box-title">
                            <div className={"title overline"}>{`2. ${t("Daily Practice")}`}</div>
                        </div>
                    </div>

                    <div className="tile">
                        <div className={`tile-text ${isMobileWidth ? "subtitle1-variant" : "header-6-variant"}`}>{t("Check-in first to get a personalized practice!")} </div>
                    </div>
                </div>
            }
        </div>
    )
}
