
const ALLOWED_CLIENT_ENVIRONMENTS = ["production", "staging", "development","master", "test", "ctest","release-candidate","tolgee"];

class ClientConfig {

    // Configuration Variables
    environment: string;
    apiRoot: string;
    segmentWriteKey: string;
    mixpanelProjectKey: string;
    auth0Domain:string;
    auth0ClientId: string;
    auth0Audience: string;
    muxMetricsKey: string;
    enableAnalytics: boolean;
    newFrontendRoot: string;
    appVersion: string;

    constructor() {
        const selectedEnvironment = this.getEnvVar('VITE_ENVIRONMENT');
        if(ALLOWED_CLIENT_ENVIRONMENTS.includes(selectedEnvironment)) {
            this.environment = selectedEnvironment;
        } else {
            throw new Error(`Invalid environment value found: ${selectedEnvironment} expected: ${ALLOWED_CLIENT_ENVIRONMENTS}`);
        }

        this.apiRoot = this.getEnvVar('VITE_API_ROOT');
        this.segmentWriteKey = this.getEnvVar('VITE_SEGMENT_WRITE_KEY');
        this.mixpanelProjectKey = this.getEnvVar('VITE_MIXPANEL_PROJECT_KEY');
        this.muxMetricsKey = this.getEnvVar('VITE_MUX_METRICS_KEY');
        this.enableAnalytics = this.getEnvVar('VITE_ENABLE_ANALYTICS') === 'true';
        this.newFrontendRoot = this.getEnvVar('VITE_NEW_FRONTEND_ROOT');
        this.auth0Domain = this.getEnvVar('VITE_AUTH0_DOMAIN');
        this.auth0ClientId = this.getEnvVar('VITE_AUTH0_CLIENT_ID');
        this.auth0Audience = this.getEnvVar('VITE_AUTH0_AUDIENCE');
        this.appVersion = this.getEnvVar('VITE_APP_VERSION');

    }

    getDevelopmentRoot() {
        return localStorage.getItem('newFrontEndRoot') || 'http://localhost:3001';
    }

    /**
     * Save our desired operating environment to localStorage
     *
     * @param environment development, staging or production
     * @param rootUrl base URL to override the default for this environment (optional)
     */
    storeEnvironment(environment: string, rootUrl: string | null) {
        localStorage.removeItem('newFrontEndRoot');

        if (environment === "development" && rootUrl) {
            localStorage.setItem("newFrontEndRoot", rootUrl);
        }

        localStorage.setItem('VITE_ENVIRONMENT', environment);
    }

    private getEnvVar(varName: string): string {
        return localStorage.getItem(varName) || import.meta.env[varName] || '';
    }

    private getValueForEnvironment<R>(valueName: string, options: {[key: string]: R}): R {
        const selectedValue = localStorage.getItem(valueName) as R || options[this.environment];

        if(!selectedValue && selectedValue !== false) {
            throw new Error(`Invalid ${valueName} value found: '${selectedValue}' for '${this.environment}' environment. Expected a string.`);
        } else {
            return selectedValue;
        }
    }
}

const clientConfig = new ClientConfig();
export default clientConfig;