import {useEffect, useState} from 'react';
import NavigationHelper from "../../misc/NavigationHelper";
import { useLocation } from 'react-router-dom';


// it looks at the URL and determines given a query string and expected value if it matches
// /watch/123 (assumed to be a videoId)
// /watch/123?isAsset=1 (assumed to be an assetId)
/**
 * 
 * @param query a query string like "isAsset" or "isMedia"
 * @param expectedValue the expected value of the query string like "1"
 * @returns isMatch: boolean, isCheckLoading: boolean 
 */
export default function useQueryChecker(query: string, expectedValue: string): {isMatch: boolean, isCheckLoading: boolean} {
    const [isMatch, setIsMatch] = useState<boolean>(false);
    const [isCheckLoading, setIsCheckLoading] = useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
        const matches = NavigationHelper.checkURLQuery(location.search, query, expectedValue);
        if(!matches) {
            setIsCheckLoading(false);
            return;
        };
        
        const contentId = getLastRouteId(location.pathname);
        if(!contentId)  {
            setIsCheckLoading(false);
            return;
        };
        setIsMatch(true);
        setIsCheckLoading(false);

    }, []);

    function getLastRouteId(pathname: string): string {
        // Split the pathname by '/' and filter out any empty segments
        const pathSegments = pathname.split('/').filter(Boolean);
        
        // The last segment of the path will be the ID
        const lastId = pathSegments[pathSegments.length - 1];
        
        return lastId;
    }
    return {isMatch, isCheckLoading};    
}