import './DailyVideoPage.scss';
import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {VideoResponse} from "../../utils/ApiTypes";
import {IonContent, IonPage} from "@ionic/react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { DailyVideo } from '../../components/DailyVideo/DailyVideo';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';



export const DailyVideoPage: React.FC = () => {

    const [muxVideo, setMuxVideo] = useState<VideoResponse|null>(null);
    const {handleGeneralError, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();

    async function getVideoContent() {
        try {
            const jsonResponse = await JourneyApiClient.getDailyVideoInfo()
            setMuxVideo(jsonResponse.video);
        } catch (error) {
            handleGeneralError("Could not get video content", error);
        }
    }

    useEffect(() => {
        if(!isComponentVisible) return;
        getVideoContent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);


    return (
        <IonPage className={`daily-video-page`}>

            <ResponsiveNavigationMenu />

            <IonContent>
            {currentUser && muxVideo &&
            <DailyVideo
                muxVideo={muxVideo}
                currentUser={currentUser}
                playVideoOnMute={false}/>}
            </IonContent>
        </IonPage>
    );

};
