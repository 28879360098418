import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./CategoryPage.scss";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import { VideoTile } from '../../components/ContentTiles/VideoTile';
import { PlaylistTile } from '../../components/ContentTiles/PlaylistTile';
import { VideoCategoryResponse } from '../../utils/ApiTypes';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import ClampLines from '../../components/ClampLines/ClampLines';
import { t } from 'i18next';

export const CategoryPage: React.FC = () => {

    const [category, setCategory] = useState<VideoCategoryResponse | null>();
    const { isMobileWidth, handleGeneralError, language } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();

    useEffect(() => {
        if(isComponentVisible){
            const categoryId = window.location.pathname.split("/")[2];
            getVideoContent(categoryId);
            setCategory(null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    const getVideoContent = (categoryId: string) => {
        JourneyApiClient.getCategory(categoryId).then(jsonResponse => {
            setCategory(jsonResponse);
        }).catch( error => {
            handleGeneralError("Could not get category", error);
        });
    }

    return(
        <IonPage className="category-page">
            
            <ResponsiveNavigationMenu title={category?.name} />

            <IonContent className="category-content-container">
                {!category ? <PleaseWait /> : <>
                    <div className='category-header'>
                        {!isMobileWidth && category.thumbnailUrl && language?.languageCode === "en" && <img src={category.thumbnailUrl} alt="Category thumbnail" />}
                        <div className={"category-title-description-container"}>
                            <div className={`${isMobileWidth ? "header-4" : "page-title"} category-title`}>{category.name}</div>
                            <ClampLines
                                showButton={true}
                                text={category.description ?? ''}
                                lines={8}
                                ellipsis="..."
                                moreText={t("Expand") ?? ''}
                                lessText={t("Collapse") ?? ''}
                                className={`clamp-description body-large`}
                            />
                        </div>
                    </div>


                    <div className="category-content industry-targeting-grid-wrapper">
                        {category.items.map((item, i: number) => {
                            switch (item.type) {
                                case 'playlist':
                                    return <div key={i} className='content-tile'><PlaylistTile key={i} playlist={item} showTitle={false} /></div>
                                case 'video':
                                    return <div key={i} className='content-tile'><VideoTile key={i} video={item} showTitle={false} /></div>
                                default:
                                    return <div key={i}></div>
                            }
                        })}
                    </div>
                </>}
            </IonContent>
        </IonPage>
    )
};
