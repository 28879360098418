import {ApiResourcesContentLanguage, User} from "../utils/ApiTypes";
import { JourneyApiClient } from "../utils/JourneyApiClient";

/**
 * Perform any front-end cleanup needed to allow for a "clean" demo session,
 * and request the same from the back-end DemoResetService
 */
class DemoResetService {
    async reset(currentUser: User, changeLanguage: (lang: ApiResourcesContentLanguage) => void) {
        // todo: any required front-end cleanup
        const englishLanguage: ApiResourcesContentLanguage = {
            id:"001",
            name:"English",
            country:"U.S.A.",
            abbreviation: 'en-US',
            nameLocalised:null,
            alpha2CodeISO: "US",
            languageCode:"en",
            divisions:[],
            countryId: "001",
            localizedLanguageName: "English",
            localizedCountryName: "U.S.A."
        };

        changeLanguage(englishLanguage);

        // request back-end cleanup as needed
        return JourneyApiClient.demoReset();
    }
}

const demoResetService = new DemoResetService();
export default demoResetService;
