import {ApiClinician} from "../../utils/ApiTypes";
import "./ClinicianAppointment.scss";
import {ScheduleCarousel} from "../ScheduleCarousel/ScheduleCarousel";

export interface ClinicianAppointmentProps {
    isMobileWidth: boolean;
    clinician: ApiClinician;
    onAppointmentClick: (timeslot: Date, clinician: ApiClinician) => void;
}


export function ClinicianAppointment(props: ClinicianAppointmentProps) {
    return(
        <div className={"clinician-appointment-component"}>
            <div className={"clinician-container"}>
                <div className={"clinician-image-container"}>
                    <img className={"clinician-image"} src={props.clinician.thumbnailUrl ?? props.clinician.thumbnailBase64 ?? ''} alt="Clinician"/>
                </div>
                <div className={"clinician-name-title-degree-container"}>
                    <div className={"clinician-name subtitle1-variant"}>
                        {`${props.clinician.firstName} ${props.clinician.lastName}`}
                        {props.clinician.pronouns && <div className={"clinician-pronouns subtitle1"}>{` (${props.clinician.pronouns})`}</div>}
                    </div>
                    {props.clinician.title &&
                        <div className={"clinician-title body-medium"}>
                            {`${props.clinician.title}`}
                        </div>
                    }
                    {props.clinician.degree &&
                        <div className={"clinician-degree body-medium"}>
                            {`${props.clinician.degree}`}
                        </div>
                    }
                </div>
            </div>

            <div className={"clinician-bio-container"}>
                <div className={"clinician-bio body-small"} dangerouslySetInnerHTML={{ __html: props.clinician.bio ?? "" }} />
            </div>
            <div className={"calendar-container"}>
                <ScheduleCarousel
                    isMobileWidth={props.isMobileWidth}
                    clinician={props.clinician}
                    onAppointmentClick={props.onAppointmentClick}
                />
            </div>
        </div>
    )
}