import React, {useContext, useEffect, useState} from "react";
import "./DeleteAccount.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {
    IonButton,
} from "@ionic/react";
import DeleteAccountModal from "./DeleteAccountModal";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import { useTranslation } from 'react-i18next';

interface AccountDeletionProps{
    isMobileWidth: boolean;
    isComponentVisible: boolean;
}

const DeleteAccount: React.FC<AccountDeletionProps> = (props: AccountDeletionProps) => {

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isDeletionLoading, setIsDeletionLoading] = useState<boolean>(false);
    const appContext = useContext(ApplicationContext);
    const { t } = useTranslation();

    useEffect( () => {
        if(props.isComponentVisible){
            setIsModalOpen(false);
            setIsDeletionLoading(false);
        }
    },[props.isComponentVisible])


    const handleDeleteConfirmationButtonPressed = async () => {
        setIsDeletionLoading(true);
        deleteUser();
    }

    const deleteUser = () => {
        JourneyApiClient.deleteUser().then( () => {
            appContext.handleLogout();
            setIsDeletionLoading(false);
        }).catch((error) => {
            appContext.handleGeneralError("Could not delete user", error);
            setIsDeletionLoading(false);
        })
    }


    return(
        <div className="account-deletion-component">
            <IonButton
                className="delete-account-button button-small-variant"
                onClick={() => setIsModalOpen(true)}
                fill="clear">
                {t("Delete my account")}
            </IonButton>
            <DeleteAccountModal
                isOpen={isModalOpen}
                close={() => setIsModalOpen(false)}
                handleDeleteAccount={handleDeleteConfirmationButtonPressed}
                isDeletionLoading={isDeletionLoading}
            />
        </div>
    )

}

export default DeleteAccount;