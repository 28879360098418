import {
    IonModal
} from "@ionic/react";
import {LoginComponent} from "../LoginComponent/LoginComponent";
import * as React from "react";

interface LoginModalComponentProps {
    isComponentVisible: boolean;
    isModalOpen: boolean;
    closeModal: () => void;
    email?: string;
}

export const LoginModalComponent: React.FC<LoginModalComponentProps> = (props: LoginModalComponentProps) => {

    async function handleModalDismiss () {
        //Resolves issue for modal not showing on edge case were user opens/closes modal repeatedly in a fast fashion
        await new Promise(r => setTimeout(r, 10));
        props.closeModal();
    }

    return(

        <IonModal
            className={"login-component-modal"}
            showBackdrop={false}
            isOpen={props.isModalOpen}
            onWillDismiss={handleModalDismiss}>
            <LoginComponent isComponentVisible={props.isComponentVisible} email={props.email}/>
        </IonModal>
    )
}
