import { ApplicationContext } from "../../misc/ApplicationContext";
import React from "react";

export enum FeatureShowContents {
    whenEnabled = 1,
    whenDisabled = 2,
}

interface FeatureProps {
    feature: string,
    showContents?: FeatureShowContents,
}

/**
 * Wrapper whose contents are rendered or not based on the Locale... database tables.
 *
 * In particular, for our current locale (e.g. en-US) we only render if a records are found along the lines of:
 *
 *   Locales: en-US
 *   Features: key: 'my-cool-feature'
 *   FeatureConfig: localeId -> en-US, feature -> 'my-cool-feature', enabled: 1
 *
 * To show alternative content for a disabled feature, set showContents=whenDisabled:
 *
 *   Locales: en-US
 *   Features: key: 'everywhere-but-us'
 *   FeatureConfig: localeId -> en-US, feature -> 'everywhere-but-us', enabled: 0
 *
 * @example
 * <Feature feature="my-cool-feature">
 *   I would render for en-US.
 * </Feature>
 *
 * <Feature feature="everywhere-but-us">
 *   I would render anywhere but en-US.
 * </Feature>
 * <Feature feature="everywhere-but-us" showContents={FeatureShowContents.whenDisabled}>
 *   I would render in en-US
 * </Feature>
 *
 * @param feature - the DB key for our feature
 * @param action - enable-for-locale or disable-for-locale (defaults to "enable-for-locale")
 */
export const Feature: React.FC<React.PropsWithChildren<FeatureProps>> = (props: React.PropsWithChildren<FeatureProps>) => {
    const { featureConfig } = React.useContext(ApplicationContext);
    const features = featureConfig?.features || {};
    let enabled: boolean = props.feature in features && features[props.feature];
    let show: boolean = enabled;

    if (props?.showContents === FeatureShowContents.whenDisabled) {
        show = !enabled;
    }

    if (show) {
        return <>{props.children}</>;
    }
    else {
        return <></>;
    }
}

export function HasFeatureEnabled(feature: string): boolean {
    const { featureConfig } = React.useContext(ApplicationContext);
    const features = featureConfig?.features || {};
    const enabled: boolean = feature in features && features[feature];

    return enabled;
}
