import { IonButton, IonIcon } from '@ionic/react';
import './ScheduleAppointmentButton.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CalendarIcon from "../../assets/images/calendar-month-blue-gradient.svg";

interface ScheduleAppointmentButtonProps {
    onClick: () => void;
    className?: string;
    hasAppointmentScheduled: boolean;
    overrideModifyWithReschedule?: boolean;
}

const ScheduleAppointmentButton: React.FC<ScheduleAppointmentButtonProps> = (props: ScheduleAppointmentButtonProps) => {

    const { t } = useTranslation();

    return (
        <IonButton
            className={`schedule-appointment-button-component ${props.className}`}
            onClick={props.onClick}>
            <IonIcon src={CalendarIcon} className={"benefits-icon"} />
            {props.hasAppointmentScheduled ? (props.overrideModifyWithReschedule ? t("Reschedule Appointment") : t("Modify Appointment"))
                : t("Schedule Appointment")}
        </IonButton>
   
    );
};

export default ScheduleAppointmentButton;
