import React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import BlackGavel from "../../assets/images/gavel-black.svg";
import BlackParentAndChild from "../../assets/images/parent-and-child-black.svg";
import BlackSavingsPig from "../../assets/images/savings-black.svg";
import BlackElderly from "../../assets/images/elderly-black.svg";
import "./WorklifeButtonsContainer.scss";
import { useTranslation } from "react-i18next";
import { ApiResourcesContentDivision } from "../../utils/ApiTypes";
import {useHistory} from "react-router-dom";
import { RESOURCES_PATH } from "../../utils/utils";

interface WorklifeButtonsContainerProps {
    worklifeContent: ApiResourcesContentDivision[];
}

export const WorklifeButtonsContainer: React.FC<WorklifeButtonsContainerProps> = (props: WorklifeButtonsContainerProps) => {

    const { t } = useTranslation();
    const history = useHistory();
    const nameDescriptionMap = new Map<string, {description: string, icon: string}>([
        ["Elder Care", { description: "Explore resources to support the needs of elders and caregivers.", icon: BlackElderly }],
        ["Child Care", { description: "Resources to support kids’ well-being.", icon: BlackParentAndChild }],
        ["Legal", { description: "Resources to help you navigate legal challenges.", icon: BlackGavel }],
        ["Financial", {description: "Access resources to support your financial planning needs.", icon: BlackSavingsPig }]
    ]);
    
    function handleWorklifeButtonClicked(content: ApiResourcesContentDivision){
        let path = `${RESOURCES_PATH}/${content.id}`;
        history.push(path);
    }

    return(
        <div className="worklife-buttons-container-component">
            <div className="work-life-header h6-bold">
                {t("Your Work-Life Services")}
            </div>
            <div className="worklife-card-container">
                {props.worklifeContent.map((content: ApiResourcesContentDivision, index) => {
                    return (
                        <div key={index} className="journey-card work-life-card" onClick={() => handleWorklifeButtonClicked(content)}>
                            <div className="tablet-and-web">
                                <div className="work-life-title header-5-variant">
                                    <IonIcon className="work-life-icon" src={nameDescriptionMap.get(content.nameEnglish)?.icon}/>
                                    {t(`${content.nameEnglish}`)}
                                </div>
                                <div className="work-life-decription body-small">
                                    {t(nameDescriptionMap.get(content.nameEnglish)?.description ?? "")}
                                </div>
                            </div>
        
                            <div className="mobile-only">
                                <IonIcon className="work-life-icon" src={BlackGavel}/>
                                <div className="work-life-title header-5-variant">
                                {t(`${content.nameEnglish}`)}
                                    <div className="work-life-decription body-small">
                                    {t(nameDescriptionMap.get(content.nameEnglish)?.description ?? "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}
