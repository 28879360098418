import "./HrHubListItem.scss";
import * as React from "react";
import { useMemo } from "react";
import {ApiDirectoryNode, ApiFileNode, ApiFolderNode} from "../../utils/ApiTypes";
import FolderIcon from "./assets/folder.svg";
import DownloadArrowIcon from "./assets/download-arrow.svg";
import ChevronRight from "../../assets/images/chevron_right.svg";
import {IonIcon} from "@ionic/react";
import { Link } from "react-router-dom";
import ClientConfig from "../../utils/ClientConfig";
import {format} from "date-fns";


export type FileDownloadCallback = (file_id: string, file_name: string) => void;

const FileCard: React.FC<{file: ApiFileNode, downloadCallback: FileDownloadCallback}> = ({file, downloadCallback}) => {

  // Turn file.size into a human readable format in MB or KB depending on size
  const fileSizeFormatted = useMemo(() => {
    if(file.sizeInBytes === null) {
      return "";
    }
    const fileSizeInMB = file.sizeInBytes / (1024 * 1024);
    if (fileSizeInMB > 1) {
      return `${Math.round(fileSizeInMB)} MB`;
    } else {
      return `${Math.round(file.sizeInBytes / 1024)} KB`;
    }
  }, [file]);

  function onClick() {
    downloadCallback(file.id, file.name);
  }

  return(
      <div className="hr-hub-list-item-component journey-content-card item-card">

      
        <a className="left-col" href={`${ClientConfig.apiRoot}/hr-hub/files/${file.id}/preview`} target="_blank" rel="noreferrer">
          {/*  To Do: have default image in case we don't have a thumbnail url */ }
          {/*  Should we only do this for certain file types ? */ }
          { file.thumbnailLink ? <img className="thumbnail" src={file.thumbnailLink} alt="Preview" /> : 'Preview' }
          <div className="download-button-small overline">
            <img className="download-arrow-icon" src={DownloadArrowIcon} alt="Download" /> {fileSizeFormatted}
          </div>
        </a>
      

        <div className="middle-col">
            <div className="title">
              <div className="h6">
                <span className="overline created-date">{format(new Date(file.createdAt), "MMM d, yyyy")} | </span>
                {file.name}
              </div>
            </div>

            <div className="description body-small">
              {file.description}
            </div>
        </div>

        <div className="right-col">
          <a href={`${ClientConfig.apiRoot}/hr-hub/files/${file.id}/download`} onClick={onClick}>
            <div className="download-button">
              <img className="download-arrow-icon" src={DownloadArrowIcon} alt="Download" />
              <div className="button-small">Download</div>
              <div className="button-small">{fileSizeFormatted}</div>
            </div>
          </a>
        </div>


      </div>
  );
}

const FolderCard: React.FC<{folder: ApiFolderNode}> = ({folder}) => {
  return(
      <Link
        to={`/hr-hub/${folder.id}`}
        className="hr-hub-list-item-component journey-content-card item-card"
      >
          <div className="left-col">
            <img className="file-icon" src={FolderIcon} alt="File" />
          </div>
          <div className="middle-col">

            <div className="title">
              <div className="header-5">
                {folder.name}
                <span className="item-count body-small">({folder.children.length} item{folder.children.length > 1 ? "s" : ""})</span>
              </div>
            </div>

            <div className="description body-small">
              {folder.description}
            </div>

          </div>

          <div className="right-col arrow">
            <IonIcon className="arrow-icon" icon={ChevronRight} />
          </div>
      </Link> 
  );
}


export const HrHubListItem: React.FC<{item: ApiDirectoryNode, downloadCallback: FileDownloadCallback}> = ({item, downloadCallback}) => {
  if(item.isFolder) {
    return <FolderCard folder={item} />;
  } else {
    return <FileCard file={item} downloadCallback={downloadCallback} />
  }
}