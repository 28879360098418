import React, { useContext } from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import "./LanguagesPage.scss";
import { useTranslation } from 'react-i18next';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { ApplicationContext } from "../../misc/ApplicationContext";
import { MiniLanguagesMenu } from "../../components/MiniLanguageMenu/MiniLanguagesMenu";
import useAppLanguages from "../../components/CustomHooks/useAppLanguages";

export const LanguagesPage: React.FC = () => {
    const { t } = useTranslation();
    const { currentUser, hasCompanyCountries } = useContext(ApplicationContext);
    const { languages } = useAppLanguages(currentUser?.companyId);

    return (
        <IonPage className="languages-page">

            <ResponsiveNavigationMenu title={t('Languages')} />

            <IonContent className="languages-page-content">
                <div>
                    {hasCompanyCountries && <MiniLanguagesMenu companyId={currentUser!.companyId} sendToHome={true} rightMargin={10} languages={languages}/>}
                </div>
            </IonContent>
        </IonPage>
    )
};
