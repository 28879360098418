import * as React from 'react';
import { useContext, useEffect, useState } from "react";
import {
    IonContent,
    IonPage,
} from "@ionic/react";
import "./ContentDivisionPage.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { ResourcesCarrousel } from "../../components/Resources/ResourcesCarrousel";
import { ApiResourcesContentDivision, EAPDescriptionType } from "../../utils/ApiTypes";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { ResourcesTile } from "../../components/Resources/ResourcesTile";
import { PleaseWait } from "../../components/PleaseWait/PleaseWait";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { BACKGROUND_TILE_COLOR } from "../../utils/utils";
import { TalkToSomeone } from '../../components/TalkToSomeone/TalkToSomeone';
import { Feature } from '../../components/Feature/Feature';
import { WorklifeComponent } from '../../components/Worklife/WorklifeComponent';
import { useTranslation } from 'react-i18next';


export const ContentDivisionPage: React.FC = () => {
    const [isDivisionsLoading, setIsDivisionsLoading] = useState<boolean>(true);
    const { isMobileWidth, handleGeneralError, language, currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [divisions, setDivisions] = useState<ApiResourcesContentDivision[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        if (!isComponentVisible) return;
        fetchDivisions();
        setIsDivisionsLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    useEffect(() => {
        if (!isComponentVisible) return;
        fetchDivisions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, language]);

    async function fetchDivisions() {
        setIsDivisionsLoading(true);
        try {
            const divisions = await JourneyApiClient.getResourcesContentDivisions();
            setDivisions(divisions);
            setIsDivisionsLoading(false);
        } catch (error) {
            setIsDivisionsLoading(false);
            handleGeneralError("Unable to fetch Resources Content Module By DivisionId", error);
        }
    }

    return (
        <IonPage className="content-division-page">

            <ResponsiveNavigationMenu title={"Resources"} />

            <IonContent className="division-content">
                <ResourcesCarrousel isComponentVisible={isComponentVisible} isMobileWidth={isMobileWidth} />
                <Feature feature='resources-talk-to-someone'>
                    <div className={"talk-someone-container"}>
                        {currentUser && <Feature feature="has-eap">
                            <TalkToSomeone
                                user={currentUser}
                                isMobileWidth={isMobileWidth}
                                showDescription={true}
                                showDescriptionInPopover={false}
                                isCondensed={false}
                                eapDescription={EAPDescriptionType.Default}
                                subtitle={t("All support is free and confidential.")}
                                isComponentVisible={isComponentVisible} />
                        </Feature>
                        }
                    </div>
                </Feature>
                <div className="content-division-worklife-wrapper">
                    <Feature feature="work-life">
                        <WorklifeComponent/>
                    </Feature>
                </div>
                {isDivisionsLoading ? <PleaseWait /> :
                    <div className="resources-tiles-wrapper">
                        <div className="resources-tiles-container">
                            {divisions && divisions.map((contentDivision: ApiResourcesContentDivision, index: number) => {
                                return (<ResourcesTile className='left-aligned-tile' color={BACKGROUND_TILE_COLOR[index % BACKGROUND_TILE_COLOR.length]} key={index} displayBorder={true} text={contentDivision.nameNative ?? contentDivision.nameEnglish} i18nKey={`resources-division-${(contentDivision.nameEnglish).toLowerCase()}`} link={`/resources/${contentDivision.id}`} alignTextLeft={true} />)
                            })}
                        </div>
                    </div>}
            </IonContent>
        </IonPage>
    )
}
