import * as React from 'react';
import {useContext, useEffect} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./AssessmentPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {AssessmentComponent} from "../../components/Assessment/AssessmentComponent";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';

export const AssessmentPage: React.FC = () => {

    const {currentUser, silentlyRefreshUserData} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const queryClient = useQueryClient();

    useEffect(() => {
        if(!isComponentVisible) return;
        assessmentFlagMutation.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);


    const assessmentFlagMutation = useMutation({
        mutationFn: () => {
            return JourneyApiClient.setUserHasSeenMonthlyAssessment();
        }, onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ['getMonthlyAssessmentV101'] })
            await silentlyRefreshUserData();
        }
    })


    return(
        <IonPage className="assessment-page">
            
            <ResponsiveNavigationMenu title={"Assessment"} />

            <IonContent className="assessment-page-container">
                {currentUser && <AssessmentComponent currentUser={currentUser}/>}
            </IonContent>
        </IonPage>
    )
};
