import React, {useContext} from "react";
import "./CancelAppointmentModal.scss";
import {IonButton, IonContent, IonModal} from "@ionic/react";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from "react-i18next";
import { ExitButton } from "../../ExitButton/ExitButton";
import { ApiAcuityAppointment } from "../../../utils/ApiTypes";
import { ApplicationContext } from "../../../misc/ApplicationContext";
import AppointmentInformation from "../../AppointmentInformation/AppointmentInformation";
import { differenceInHours } from 'date-fns';

interface CancelAppointmentModalProps {
    isOpen: boolean
    handleCloseModal: () => void;
    handleAppointmentCancel: () => void;
    isCancelLoading: boolean;
    appointment: ApiAcuityAppointment;
}

export const CancelAppointmentModal: React.FC<CancelAppointmentModalProps> = (props: CancelAppointmentModalProps) => {

    const { t } = useTranslation();
    const {isMobileWidth} = useContext(ApplicationContext);
    const isAppointmentIn24Hours = differenceInHours(new Date(props.appointment.appointmentDatetime), new Date()) < 24;

    return(
        <IonModal
            className={`cancel-appointment-modal-component`}
            showBackdrop={true}
            isOpen={props.isOpen}
            onWillDismiss={props.handleCloseModal}
        >
            <IonContent>
                <div className="exit-button-container">
                    <ExitButton onClick={() => props.handleCloseModal()}/>
                </div>
                <p className="cancel-appointment-header header-4">{t("Are you sure you want to cancel your appointment?")}</p>
                <p className="cancel-appointment-body body-medium">{t("Please note: Appointments cannot be cancelled within 24 hours of the scheduled time.")}</p>
                {isMobileWidth && <AppointmentInformation 
                    appointment={props.appointment} 
                    className={"cancel-appointment-modal"} 
                    includeDuration={true}
                    includeVirtualAppointmentText={true}/>
                }
                <div className="buttons-container">
                    <IonButton
                        disabled={props.isCancelLoading}
                        className="buttons do-not-cancel-button button-medium-variant"
                        onClick={props.handleCloseModal}>
                            {t("No, go back")}
                    </IonButton>
                    <IonButton
                        disabled={props.isCancelLoading || isAppointmentIn24Hours}
                        className="buttons cancel-button button-medium-variant"
                        onClick={props.handleAppointmentCancel}>
                        {props.isCancelLoading ? <CircularProgress size={30} /> : t("Yes, cancel this appointment")}
                    </IonButton>
                </div>
            </IonContent>    
        </IonModal>
    )
}
