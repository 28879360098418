import React, {useContext, useEffect, useRef} from "react";
import {IonButton, IonIcon} from "@ionic/react";
import "./LiveChat.scss";
import {ApiUser} from "../../utils/ApiTypes";
import MessageIcon from "../../assets/images/message-icon-blue-gradient.svg";
import { useTranslation } from 'react-i18next';
import {ApplicationContext} from "../../misc/ApplicationContext";

const chatSDKURL = 'https://developer.livehelpnow.net/js/sdk/lhn-jssdk-current.min.js';
const chatScriptText = `window.lhnJsSdkInit = function () {
    lhnJsSdk.setup = { application_id: "25cb2181-be71-4c5a-81d9-bdc274e3befd", application_secret: "8loorouea+9aajgb5x/hxk0kjsjxmqbe58odpztpn6fkdzleom" };
    lhnJsSdk.controls = [{ type: "hoc", id: "04342767-901b-4373-b26f-7c6ff73f1f24" }];
    lhnJsSdk.options = {
        autoinvite: {
            enabled: false
        },
        custom1: "{name}",
        custom2: "{email}",
    }
    lhnJsSdk.dictionary = {
        agentConnecting: "Connecting to agent",
        callbackMenu: "Callback",
        callbackTitle: "Request a callback",
        cancel: "Cancel",
        chatBoxPlaceholder: "Your message goes here",
        chatMenu: "Chat",
        chatTitle: "Conversation",
        email: "Email",
        emailTranscript: "Email transcript to",
        emailSuccess: "Chat transcript has been emailed to",
        endChat: "End Chat",
        endChatConfirm: "Are you sure you want to end the current chat?",
        hide: "hide",
        inviteCancel: "Dismiss",
        inviteStart: "Chat now",
        knowledgeMenu: "FAQ",
        knowledgeSearch: "",
        knowledgeTitle: "Search Knowledge",
        livechat: "LIVE CHAT",
        livechat_offline: "GET HELP",
        newChatTitle: "New conversation",
        offlineTitle: "Leave a message",
        remoteAssistance: "The agent is requesting access to view your desktop",
        send: "Send",
        startChat: "Start Chat",
        submit: "Submit",
        ticketMenu: "Ticket",
        ticketTitle: "Submit a ticket",
        uploadFile: "Upload File",
        uploadFileSuccess: "File has been successfully uploaded",
        error_msg_empty: "",
        error_msg_email: "",
        error_msg_phone: "",
        error_msg_url: ""
    };
};`;


interface LiveChatProps {
    currentUser: ApiUser;
    isComponentVisible: boolean;
    isTrial: boolean;
    isChatWithUsButtonTriggered?: boolean;
}

export function LiveChat(props: LiveChatProps) {

    const {handleUserError} = useContext(ApplicationContext);
    const { t } = useTranslation();
    const buttonRef = useRef<HTMLIonButtonElement>(null);

    useEffect(() => {
        window.lhnJsSdkReady = setChatFieldData;

        const scriptConfig = document.createElement('script');
        scriptConfig.async = true;
        scriptConfig.text = chatScriptText.replace("{name}", `${props.currentUser.firstName} ${props.currentUser.lastName}`)
            .replace("{email}", props.currentUser.email ?? "");
        document.body.appendChild(scriptConfig);

        const scriptSDK = document.createElement('script');
        scriptSDK.async = true;
        scriptSDK.src = chatSDKURL;
        document.body.appendChild(scriptSDK);

        return () => {
            document.body.removeChild(scriptConfig);
            document.body.removeChild(scriptSDK);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setChatFieldData(){
        window.lhnJsSdk.fieldData = {
            email_readonly: props.currentUser.email, //mapping to the EMail CRM field
            full_name_readonly: `${props.currentUser.firstName} ${props.currentUser.lastName}`, //mapping to the Full name CRM field.
            auto_submit: true //set to true to auto submit the pre chat form with supplied info
        };
    }

    function handleChat(){
        if(props.isTrial){
            handleUserError(t("Live Chat is not available for Trial users."), " ");
        } else{
            window.lhnJsSdk.openHOC('c');
        }
    }
    
    window.lhnJsSdkReady = () => {
        if (buttonRef?.current && props.isChatWithUsButtonTriggered && props.isComponentVisible) {
            handleChat();
        }
    }

    return(
        <div className="live-chat-component" >
            {props.isComponentVisible && <IonButton
                className={`benefits-button header-6-variant`}
                ref={buttonRef}
                onClick={handleChat}>
                <IonIcon src={MessageIcon} className={"benefits-icon"}/>
                {t("Chat With Us")}
            </IonButton>}
        </div>
    );
}