import * as React from 'react';
import {useState} from "react";
import {
    IonButton,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonRadio,
    IonRadioGroup,
    RadioGroupChangeEventDetail
} from "@ionic/react";
import "./DeveloperPage.scss";
import EnvironmentSwitcherService from '../../misc/EnvironmentSwitcherService';
import { Deploy } from 'cordova-plugin-ionic';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';

export const DeveloperPage: React.FC = () => {
    const initialEnvironment = EnvironmentSwitcherService.getCurrentEnvironment();
    const [currentSelection, setCurrentSelection] = useState<string>(initialEnvironment);
    const [isSwitchingEnv, setSwitchingEnv] = useState(false);

    const handleValueChange = (event: CustomEvent<RadioGroupChangeEventDetail<string>>) => {
        const value = event.detail.value;
        setCurrentSelection(value);
    };

    const changeChannel = async()=>{
        setSwitchingEnv(true);
        console.log(`changing channel to ${currentSelection}`);
        await Deploy.configure({channel: currentSelection});
        await Deploy.sync({updateMethod: 'auto'}, percentDone => {
            console.log(`Update is ${percentDone}% done!`);
        }) 
        setSwitchingEnv(false);     
    }

    return(
        <IonPage className="developer-page">
            
            <ResponsiveNavigationMenu title={"Developer"} />

            <IonContent className="developer-page-content">
                <section className="developer-section">
                    <h2 className="environment-list-header header-4">Environment:</h2>

                    <div className="environment-warning">
                        <h3 className="warning-header">Note:</h3>
                        <p className="body-medium">Changing your environment will immediately log you out.</p>

                    </div>
                    <IonRadioGroup className="radio-button-group" value={currentSelection} onIonChange={handleValueChange}>
                        <IonItem
                            key="production"
                            className={`radio-button-environment-item`}>
                            <IonRadio
                                className={`radio-button-enviroment-checkbox`}
                                value="production"
                                />
                            <IonLabel className={`radio-button-environment-label button-medium`}>
                                Production
                            </IonLabel>
                        </IonItem>
                        <IonItem
                            key="release-candidate"
                            className={`radio-button-environment-item`}>
                            <IonRadio
                                className={`radio-button-enviroment-checkbox`}
                                value="release-candidate"
                                />
                            <IonLabel className={`radio-button-environment-label button-medium`}>
                                Release Candidate
                            </IonLabel>
                        </IonItem>
                        <IonItem
                            key="staging"
                            className={`radio-button-environment-item`}>
                            <IonRadio
                                className={`radio-button-enviroment-checkbox`}
                                value="staging"
                                />
                            <IonLabel className={`radio-button-environment-label button-medium`}>
                                Staging
                            </IonLabel>
                        </IonItem>
                    </IonRadioGroup>

                    <IonButton className="environment-change" disabled={isSwitchingEnv} onClick={changeChannel}>
                        Change Environment
                    </IonButton>
                </section>
            </IonContent>
        </IonPage>
    )
};
