import React, { useContext, useEffect, useMemo, useState} from "react";
import "./ResourcesCarrousel.scss";
import {ApiResourcesContentFeaturedArticle} from "../../utils/ApiTypes";
import SwiperCore, {Autoplay, Pagination, Navigation} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper-bundle.min.css";
import {useHistory} from "react-router-dom";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { ApplicationContext } from "../../misc/ApplicationContext";

interface ResourcesCarrouselProps{
    isComponentVisible: boolean;
    isMobileWidth: boolean;
}

export const ResourcesCarrousel: React.FC<ResourcesCarrouselProps> = (props: ResourcesCarrouselProps) => {

    const history = useHistory();
    const { language } = useContext(ApplicationContext);
    const [featuredArticles, setFeaturedArticles] = useState<ApiResourcesContentFeaturedArticle[]>([]);
    const [swiperIndex, setSwiperIndex] = useState<number>(0);
    const swiperRef = React.useRef<SwiperCore>();
    const onInit = (Swiper: SwiperCore): void => {
        swiperRef.current = Swiper;
    };

    useEffect(() => {
        if (!props.isComponentVisible) return;
        getFeaturedArticles();
    }, [props.isComponentVisible, language]);

    async function getFeaturedArticles() {
        const featuredArticles = await JourneyApiClient.getResourcesContentFeaturedArticle();
        setFeaturedArticles(featuredArticles);
        swiperRef.current?.slideTo(0, 0, false);
        swiperRef.current?.autoplay?.start();
    }

    const slideDots = useMemo(function updateSlideDots() {
        const newSlideDots = new Array(featuredArticles.length).fill(false);
        if (swiperRef.current) {
            newSlideDots[swiperRef.current.realIndex] = true;
        }
        return newSlideDots;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [featuredArticles, swiperIndex]);

    function handleDotClick(index: number) {
        swiperRef.current?.slideTo(index, index, false);
    }

    return(
        <Swiper
            className="resources-carrousel-swiper"
            onInit={onInit}
            modules={[Autoplay, Pagination, Navigation]}
            scrollbar={false}
            autoplay={{ delay: 8000, disableOnInteraction: false }}
            spaceBetween={0}
            pagination={{el: '.resources-swiper-pagination', clickable: true}}
            navigation={{
                prevEl: `.resources-prev`,
                nextEl: `.resources-next`
            }}
            onSlideChange={e => setSwiperIndex(e.realIndex)}
            slidesPerView={1}
            loop={true}
        >
            {featuredArticles.map((fa, i) =>  <SwiperSlide className="resources-swiper-slide" key={i} onClick={() => history.push(`/resources-article/${fa.articleId}`)}>
            <div className="resources-slide-background" style={{ backgroundImage: `url('https://journeylive.imgix.net/${fa.imageS3Path}`}}>
                <div className="resources-text">
                    <div className={`resources-title header-4`} dangerouslySetInnerHTML={{__html: fa.title ?? ""}}></div>
                    <div className="resources-description header-6" dangerouslySetInnerHTML={{ __html: fa.teaser ?? ''}}></div>
                </div>
                <div className="resources-thumbnail-container"></div>
            </div>
            </SwiperSlide>)}
            <ul className="dots" slot='container-start'>{slideDots.map((sd, i) => <li key={i} onClick={() => handleDotClick(i)} className={sd ? 'selected' : ''}></li>)}</ul>
        </Swiper>
    )
}