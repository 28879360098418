import "./LandingPage.scss";
import React from "react";
import { useContext } from "react";
import DOMPurify from 'dompurify';
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useState } from "react";
import { IonPage, IonContent } from "@ionic/react";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ApiLandingPage } from "../../utils/ApiTypes";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import AnalyticsService from "../../misc/AnalyticsService";
import { PleaseWait } from "../../components/PleaseWait/PleaseWait";
import Logo from "../../assets/images/journey-logo-blue.svg";
import { SignUpForm } from "../../components/SignUpForm/SignUpForm";
import { SignUpFormError } from "../../components/SignUpFormError/SignUpFormError";
import { MuxVideoPlayer } from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import { useLocation } from "react-router-dom";
import { VideoTile } from "../../components/ContentTiles/VideoTile";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from 'react-i18next';

export const LandingPage: React.FC = () => {

    const { handleApplicationError, currentUser } = useContext(ApplicationContext);
    const [landingPage, setLandingPage] = useState<ApiLandingPage | null>(null);
    const { name } = useParams<{ name: string }>();
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const { t } = useTranslation();

    async function fetchLandingPage() {
        if (!name) {
            Sentry.captureEvent({ message: "Invalid Url for Landing Page", level: 'info' });
        }
        const landingPage = await JourneyApiClient.getLandingPage(name);

        setLandingPage(landingPage);

        return landingPage;
    };

    const landingPageResult = useQuery(["fetchLandingPage", isComponentVisible], fetchLandingPage);
    if (landingPageResult.status === "error") {
        Sentry.captureException(landingPageResult.error);
    }

    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: landingPage?.video.title,
            video_duration: landingPage?.video.duration,
            video_id: landingPage?.video.id,
        }
        await AnalyticsService.trackUserAction("landing_page_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: landingPage?.video.title,
            video_duration: landingPage?.video.duration,
            video_id: landingPage?.video.id,
        }
        await AnalyticsService.trackUserAction("landing_page_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: landingPage?.video.title,
            video_duration: landingPage?.video.duration,
            video_id: landingPage?.video.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("landing_page_checkpoint", location.pathname, eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: landingPage?.video.title,
            video_duration: landingPage?.video.duration,
            video_id: landingPage?.video.id,
        }
        await AnalyticsService.trackUserAction("landing_page_unmute", location.pathname, eventProperties);
    }

    const handleError = (description: string) => {
        handleApplicationError(description);
    }

    const message: string = DOMPurify.sanitize(landingPage?.message ?? "");

    return (
        <IonPage className="landing-page-component">

            <IonContent className='landing-page-content'>
                <div className='landing-page'>
                    <div className='header'>
                        {/* <img src={currentUser?.company?.logoUrl ?? Logo} className="logo-image" alt="Journey LIVE Logo"/> */}
                        <img src={Logo} className="logo-image" alt="Journey LIVE Logo" />
                    </div>
                    <div className='body'>
                        {
                            landingPageResult.status === "success" && landingPage &&
                            <div className="column">
                                <div className="top-row">
                                    <div className="left-quadrant">
                                        <h1 className="welcome">
                                            {landingPage.title ?? (landingPage.hasEAP ? t("Welcome to Journey Proactive EAP") : t("Welcome to Journey!"))}
                                        </h1>
                                        <h3 className="message" dangerouslySetInnerHTML={{ __html: message }}></h3>
                                    </div>
                                    <div className="right-quadrant">
                                        <div className="video-container">
                                            <MuxVideoPlayer
                                                videoId={landingPage.video.id}
                                                duration={landingPage.video.duration}
                                                isLoading={false}
                                                isComponentVisible={isComponentVisible}
                                                muxPlaybackId={landingPage.video.muxPlaybackId}
                                                muxVideoId={landingPage.video.muxVideoId}
                                                title={landingPage.video.title}
                                                currentUser={null}
                                                thumbnail={landingPage.video.thumbnailUrl}
                                                trackCuePointEvent={trackCuePointEvent}
                                                trackPlayEvent={trackPlayEvent}
                                                trackPauseEvent={trackPauseEvent}
                                                handleApplicationError={handleError}
                                                trackUnmute={trackUnmute}
                                                autoplay={true}
                                                isInitialMuted={true}
                                                hideRecommendations={true}
                                                numberOfRecommendationsToShow={0}
                                                isPublicVideo={true} />
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom-row">
                                    <div className="left-quadrant">
                                        {
                                            landingPage.showRegistrationForm &&
                                            <SignUpForm emailType="company" title={t("Register for Free")} company={landingPage.company} realmDiscovery={true} />
                                        }
                                    </div>
                                    <div className="right-quadrant">
                                        <h2>{t("More from Journey")}</h2>
                                        <div className="videos-container">
                                            {
                                                landingPageResult.status === "success" && !currentUser &&
                                                landingPage?.playlist?.videos.filter(video => video.isPublic === true)?.map((item, i: number) => {
                                                    switch (item.type) {
                                                        case 'video':
                                                            return <div key={i} className='content-tile'><VideoTile showTitle={false} showDescription={false} key={i} video={item} /></div>
                                                        default:
                                                            return <div key={i}></div>
                                                    }
                                                })
                                            }
                                            {
                                                landingPageResult.status === "success" && currentUser &&
                                                landingPage?.playlist?.videos.map((item, i: number) => {
                                                    switch (item.type) {
                                                        case 'video':
                                                            return <div key={i} className='content-tile'><VideoTile showTitle={false} showDescription={false} key={i} video={item} /></div>
                                                        default:
                                                            return <div key={i}></div>
                                                    }
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            landingPageResult.status === "error" && <SignUpFormError />
                        }
                        {
                            landingPageResult.status === "loading" && <PleaseWait transparentBackground />
                        }
                    </div>
                </div>
            </IonContent>
        </IonPage>
    );
}
