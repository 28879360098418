import "./HrHubPage.scss";
import * as React from "react";
import {IonContent, IonPage} from "@ionic/react";
import {useContext, useEffect, useState, useCallback} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {HrHubFileContents} from "../../components/HrHubPage/HrHubFileContents";
import {HrHubHeader} from "../../components/HrHubPage/HrHubHeader";
import {useLocation, useHistory} from "react-router";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {SearchBar} from "../../components/SearchBar/SearchBar";
import {ApiDirectoryNode} from "../../utils/ApiTypes";
import { HOME_PATH, HR_HUB_PAGE_PATH } from "../../utils/utils";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { BackButton } from "../../components/BackButton/BackButton";
import { ResponsiveNavigationMenu } from "../../components/Navigation/ResponsiveNavigationMenu";
import { HR_HUB_ADMIN } from "../../utils/ApiTypes";


export const HrHubPage: React.FC = () => {

  const {handleGeneralError } = useContext(ApplicationContext);
  const location = useLocation();
  const history = useHistory();
  const [allFiles, setAllFiles] = useState<ApiDirectoryNode[]>([]);
  const [searchResults, setSearchResults] = useState<ApiDirectoryNode[] | null>(null);
  const [querySearchString, setQuerySearchString] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentFolderId, setCurrentFolderId] = useState<string | null>(null);
  const [currentResourcesCount, setCurrentResourcesCount] = useState<string>("");	
  const isComponentVisible = useIsComponentVisible();
  const {currentUser} = useContext(ApplicationContext);


  async function initializeHrHubContents() {
    if(allFiles.length > 0) {
      return;
    }
    try {
      const hrHubContent = await JourneyApiClient.getHrHubContent();
      setAllFiles(hrHubContent);
    } catch (error) {
      handleGeneralError("Unable to fetch HR Hub Content", error);
    }
  }


  async function handleSearchQuery() {
    let queryParams = new URLSearchParams(location.search);
    let searchString = queryParams.get("search");
    if(searchString) {
      try {
          const decodedSearchString = decodeURIComponent(searchString);
          setQuerySearchString(decodedSearchString);
          const newSearchResults = await JourneyApiClient.searchHrHubContent(decodedSearchString);
          setSearchResults(newSearchResults);
        } catch (error) {
          handleGeneralError("Unable to fetch HR Hub Content", error);
        }
    } else {
      setQuerySearchString(null);
      setSearchResults(null);
    }
  }

  function onClearSearchQuery() {
    if(querySearchString) {
      history.push({
        pathname: HR_HUB_PAGE_PATH,
        search: ''
      });
    }
  }

  async function goBack() {
    if(history.length === 0) {
        history.push(HR_HUB_PAGE_PATH);
    } else {
        history.goBack();
    }
};

  async function doHrHubSearch(query: string) {
    history.push({
      pathname: HR_HUB_PAGE_PATH,
      search: `?search=${encodeURIComponent(query)}`
    });
  }

  function checkUserPermissions() {
    if(!currentUser?.userRoles?.includes(HR_HUB_ADMIN)) {
      history.push(HOME_PATH);
      const message = "You are not authorized to access this page.";
      handleGeneralError(message, new Error(message));
    }
  }

  useEffect(() => {
    (async () => {

      if(isComponentVisible) {
        setIsLoading(true);

        checkUserPermissions();

        // Initialize all hr hub contents
        await initializeHrHubContents();

        // Set current folder id if present in the URL
        const pathPieces = location.pathname.split("/").filter(x => x.length > 0);
        setCurrentFolderId( pathPieces.length > 1 ? pathPieces.pop() ?? null : null);

        // Handle search query if present in the URL
        await handleSearchQuery();

        setIsLoading(false);
      }

    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isComponentVisible]);



  const onCurrentFilesChange = useCallback((currentFilesCount) => {
      if(currentFilesCount === null) {
        setCurrentResourcesCount("");
      } else if(currentFilesCount === 0) {
        setCurrentResourcesCount("No resources found");
      } else {
        setCurrentResourcesCount(`${currentFilesCount} resource${currentFilesCount > 1 ? "s" : ""}`);
      }
  }, []);


  return(
    <IonPage className='hr-hub-page'>

      <ResponsiveNavigationMenu title={"HR Hub"} />

      <IonContent className="hr-hub-content">

        <div className="hr-hub-header-component-wrapper">
          <HrHubHeader currentUser={currentUser} />
        </div>
        
        <div className="hr-hub-search-bar-component-wrapper">
          
          <div className="header-6 resources-count">

            {
              location.pathname !== HR_HUB_PAGE_PATH && <BackButton onClick={goBack} />
            }

            {currentResourcesCount}
            
          </div>

          <SearchBar
            onNewSearchQuery={doHrHubSearch}
            onClearSearchQuery={onClearSearchQuery}
            isSearchLoading={isLoading}
            searchPlaceholder="Search"
            initialSearch={querySearchString}
          />
        </div>

        <div className="hr-hub-file-contents-component-wrapper">
          {
            isLoading ?
              <div>Please wait</div>
              :
              <HrHubFileContents 
                files={searchResults ?? allFiles}
                currentFolderId={currentFolderId}
                onCurrentFilesChange={onCurrentFilesChange} />
          }
        </div>

      </IonContent>
    </IonPage>
  )
}