import React from "react";
import {useEffect, useState} from "react";
import {IonPage, IonContent, isPlatform} from "@ionic/react";
import "./SsoLoginPage.scss";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import {ApiCompany} from "../../utils/ApiTypes";
import {JourneyApiClient} from '../../utils/JourneyApiClient';
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import Logo from "../../assets/images/logo-journey.svg";
import { SignUpFormError } from "../../components/SignUpFormError/SignUpFormError";
import {useHistory} from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import { SuccessMessage } from '../../components/SuccessMessage/SuccessMessage';
import { Browser } from '@capacitor/browser';

export const SsoLoginPage: React.FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<ApiCompany | null>(null);
    const {companyCode} = useParams<{companyCode: string}>();
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const isIos = isPlatform('ios');
    const { buildAuthorizeUrl } = useAuth0();
    const redirectUri = isPlatform('android') ? "journeylive://com.journeylive.android" : isPlatform('ios') ? 'journeylive://com.journeylive.ios' : window.location.origin;

    const loadCompany = async () => {
        setIsLoading(true);

        if (!companyCode) {
            Sentry.captureEvent({message: "Invalid Url", level: 'info'});
            setIsLoading(false);
            return;
        }

        try {
            let company = await JourneyApiClient.getCompany(companyCode);

            if(!company.isSsoLogin) {
                // company is not sso login
                setIsLoading(false);
                return;
            }
            setCompany(company);
            setIsLoading(false);

            //sso login
            let url;
            if(company.ssoProvider === "workos") {
                // workOs sso login
                url = await JourneyApiClient.getWorkOsRedirectUrl(null, redirectUri, company.id);
            } else {
                // auth0 sso login
                url = await buildAuthorizeUrl({ connection: company.code });
            }
            
            // Redirect using Capacitor's Browser plugin
            await Browser.open({
                url,
                windowName: "_self",
                presentationStyle: "popover",
            });
            setIsLoading(false);
        } catch(error) {
            Sentry.captureException(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if(!isComponentVisible) return;

        (async () => {
            await loadCompany();
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible]);

    return (
        <IonPage className="sso-login-page-component">
            <IonContent className='sso-login-page-content'>
                    <div className='header'>
                        <img src={Logo} className="logo-image" alt="Journey Logo"/>
                    </div>
                    <div className='body'>
                        {
                            !isLoading && company ?
                                <SuccessMessage isComponentVisible={isComponentVisible} message={`Thank You! You'll now be redirected to ${company.name}'s SSO Login.`} redirectToHome={false}/>
                            :
                            !isLoading && !company ?
                                <SignUpFormError/>
                            :
                                <PleaseWait/>
                        }
                    </div>
            </IonContent>
        </IonPage>
    );
}
