import * as React from "react";
import "./IndustrySelectionMenu.scss";
import { IndustrySelectionList } from "./IndustrySelectionList";
import { useContext, useState } from "react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { IndustrySelectionHeader } from "./IndustrySelectionHeader";

interface Props {
    
}

export const IndustrySelectionMenu: React.FC<Props> = (props: Props) => {
    const [isIndustrySelectionMenuOpen, setIsIndustrySelectionMenuOpen] = useState<boolean>(false);
    const {setIndustry} = useContext(ApplicationContext);

    function handleIndustrySelection(industry: string) {
        setIsIndustrySelectionMenuOpen(false);
        setIndustry(industry);
    }

    function handleHeaderClick(isOpen: boolean) {
        setIsIndustrySelectionMenuOpen(isOpen);
    }

    return (
        <div className={`industry-selection-menu-component ${isIndustrySelectionMenuOpen ? 'menu-comp-open' : ''}`} >
            {isIndustrySelectionMenuOpen ? <IndustrySelectionList onSelect={handleIndustrySelection} isOpen={isIndustrySelectionMenuOpen} /> 
            : <IndustrySelectionHeader onHeaderClick={handleHeaderClick}/>}
        </div>
    )
}
