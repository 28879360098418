import React, { useContext } from 'react';
import {IonMenuButton, IonButtons, IonHeader, IonToolbar, IonButton, IonIcon} from "@ionic/react";
import './SideMenuButton.scss';
import Logo from "../../assets/images/journey-logo-green.svg";
import {useHistory, useLocation} from "react-router";
import BackArrow from "../../assets/images/toolbar-back-arrow.svg";
import { ApplicationContext } from '../../misc/ApplicationContext';
import {HOME_PATH} from "../../utils/utils";
import ClientConfig from '../../utils/ClientConfig';

interface Props {
    title?: string | null | undefined;
}

/**
 * This component should be called CompactToolbar.
 */
//TODO: Rename after we refactor or nav bars into ResponsiveNavigationMenu.
export const SideMenuButton: React.FC<Props> = (props) => {

    const history = useHistory();
    const location = useLocation();
    const { currentUser, profileStepsCompleted, totalProfileSteps, fullLanguageSupport} = useContext(ApplicationContext);
    const isHome: boolean = location.pathname === HOME_PATH;

    const profileCompleted = profileStepsCompleted === totalProfileSteps;
    const stepsLeft = totalProfileSteps - profileStepsCompleted;
    const environmentTitle = ClientConfig.environment === "production" ? "" : ClientConfig.environment;

    const goBack = async () => {
        if(history.length === 0) {
            history.push(HOME_PATH);
        } else {
            history.goBack();
        }
    };

    return(
        <IonHeader className="side-menu-button-component" >
            <IonToolbar className="side-menu-button-toolbar">
                <IonButtons className="side-menu-buttons" slot="start">
                    {isHome ?
                        <>
                            {!profileCompleted && fullLanguageSupport &&
                                <div className={`notification`}>
                                    {stepsLeft}
                                </div>
                            }
                            <IonMenuButton menu="side-menu" color={"--Green"} autoHide={false} className="side-menu-button"/>
                        </>
                    :
                        <IonButton className="back-button" onClick={goBack}>
                            <IonIcon src={BackArrow}/>
                        </IonButton>}
                </IonButtons>
                {props.title ? <div className="caption-variant">{props.title}</div> :
                <div className="toolbar-logo-container">
                    <img src={currentUser?.company?.appLogoUrl ?? Logo} className="logo-image" alt="Logo"/>
                    {environmentTitle &&
                        <span className="environment-title">{environmentTitle}</span>
                    }
                </div>}
            </IonToolbar>
        </IonHeader>
    )
}