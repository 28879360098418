import React from "react";
import { useTranslation } from "react-i18next";

interface LocalizedImageProps {
    i18nKey: string,
    fallback?: string,
    className?: string
}

export function LocalizedImage({ i18nKey, fallback, className }: LocalizedImageProps) {

    const { i18n } = useTranslation();
    const src = i18n.exists(i18nKey) ? i18n.t(i18nKey) : fallback;

    return src ? <img className={className} alt="Localized" src={src} /> : <></>;
}