import * as Sentry from "@sentry/react";
import {User} from "../utils/ApiTypes";
import ClientConfig from '../utils/ClientConfig';
import mixpanel from 'mixpanel-browser';
import {Mixpanel} from 'mixpanel-browser';

const USER_ACTION = "user_action";
const NAVIGATION = "navigation";

interface UserData {
    user_id?: number;
    company_id?: number;
    company_code?: string;
    has_eap?: boolean;
}

class AnalyticsService {

    private mixpanelAnalytics: Mixpanel | null = null;
    private globalMetadata = {};
    private userData: UserData = {};
    private enableUserTrackingEvents: boolean | undefined = true;

    constructor() {
        this.mixpanelAnalytics = mixpanel.init(ClientConfig.mixpanelProjectKey, 
            {debug: false, persistence: 'localStorage'}, "mixpanelAnalytics"
        );
        if(!this.mixpanelAnalytics){
            Sentry.captureException(new Error(`Error initializing Mixpanel}`));
        }
    }

    setGlobalMetadata(metadata = {}) {
        this.globalMetadata = metadata;
    }

    getPathPageProperties(pathName?: string | null) {
        // pageName is the first name in the path. e.g. /pageName/other/parts
        let finalPageName, finalPathName;
        if (pathName) {
            finalPathName = pathName.toLowerCase();
            finalPageName = (finalPathName.split("/").filter(s => !!s).shift() ?? "home").replace(/-/g, "_")
        } else {
            finalPageName = "home";
            finalPathName = "/";
        }
        return {
            page_name: finalPageName,
            path_name: finalPathName
        }
    }

    /**
     * Only needs to be called once when a user registers (or whenever a user's information changes)
     * In theory and a perfect world, if a user information never changed, we only would have to call identify
     * when a user registers. In practice, we want to call it on registration, login and profile update.
     *
     * @param user user object to get the identifier
     */
    async identifyUser(user: User) {
        if (!this.mixpanelAnalytics) {
            return;
        }
        this.userData = {
            user_id: user.id,
            company_id: user.company?.id,
            company_code: user.company?.code,
            has_eap: false,
        };
        this.enableUserTrackingEvents = user.company?.enableUserTrackingEvents ?? true;

        // To cover all bases, we set company-specific traits twice both by using Segment's built-in fields and our custom fields
        const identifyTraits = {
            company: {
                id: user.company?.id,
                name: user.company?.code,
            },
            company_id: user.company?.id,
            company_code: user.company?.code,
        }
        await this.mixpanelAnalytics.identify(user.id.toString());
        await this.mixpanelAnalytics.people.set(identifyTraits);
    }

    async setUserHasEAP(hasEAP: boolean) {
        if (!this.mixpanelAnalytics) {
            return;
        }

        this.userData.has_eap = hasEAP;
    }

    /**
     * @param userActionName lower-case snake_cased action name
     * @param pathName must come from location.pathname or equivalent
     * @param extraProperties an optional hash of additional event properties
     */
    async trackUserAction(userActionName: string, pathName: string | null = null, extraProperties: any = {}) {
        if (!ClientConfig.enableAnalytics || !this.enableUserTrackingEvents) {
            return;
        }
        if (!this.mixpanelAnalytics) {
            return;
        }

        await this.mixpanelAnalytics.track(
            userActionName.toLowerCase(),
          {
                event_type: USER_ACTION,
                event_name: userActionName.toLowerCase(),
                ...this.getPathPageProperties(pathName),
                ...extraProperties,
                ...this.userData,
                ...this.globalMetadata
            }
        );
    }

    /**
     * @param pathName must come from location.pathname or equivalent
     */
     async trackPageNavigation(destinationPathName: string, originPathName: string | null) {
        if (!ClientConfig.enableAnalytics || !this.enableUserTrackingEvents) {
            return;
        }
        if (!this.mixpanelAnalytics) {
            return;
        }

        // pageName is the first name in the path. e.g. /pageName/other/parts
        const destinationPageProperties = this.getPathPageProperties(destinationPathName);
        const originPageProperties = this.getPathPageProperties(originPathName);

        await this.mixpanelAnalytics.track(
            `go_to_${destinationPageProperties.page_name}`,
          {
              event_type: NAVIGATION,
              event_name: `go_to_${destinationPageProperties.page_name}`,
              ...originPageProperties,
              ...this.userData,
              ...this.globalMetadata
          }
        );
    }

    async resetUser() {
        if (!this.mixpanelAnalytics) {
            return;
        }
        await this.mixpanelAnalytics.reset();
    }

}

const analyticsService = new AnalyticsService();
export default analyticsService;
