import * as React from "react";
import { useContext } from "react";
import "./IndustrySelectionHeader.scss";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { IonButton, IonIcon } from "@ionic/react";
import PencilIcon from "../../assets/images/pencil-gray.svg";


type IndustrySelectionHeaderProps = {
    onHeaderClick: (isOpen: boolean) => void;
};

export function IndustrySelectionHeader({ onHeaderClick } : IndustrySelectionHeaderProps) {

    const { industryCssClass } = useContext(ApplicationContext);

    function handleMenuClick() {  
        onHeaderClick(true);
    }


    return (
        <div className="industry-selection-header-component" onClick={handleMenuClick}>
            <div className="name-container">
                <div className="name button-medium">
                    {industryCssClass}
                </div>
            </div>

            <IonButton className={"edit-button"} onClick={handleMenuClick}>
                <IonIcon icon={PencilIcon} />
            </IonButton>
        </div>
    )
}
