import React, {useEffect, useState} from "react";
import {IonItem, IonInput} from "@ionic/react";
import "./AssessmentQuestion.scss";
import { useTranslation } from 'react-i18next';

interface numberInputAssessmentQuestionProps {
    assessmentQuestion: string | null,
    onValueChange: (value: number) => void,
    hasLabel: boolean,
    className: string,
}

export const NumberInputAssessmentQuestion: React.FC<numberInputAssessmentQuestionProps> = (props: numberInputAssessmentQuestionProps) => {

    const [value, setValue] = useState<number | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        setValue(null);
    },[props.assessmentQuestion])

    function handleValueChange (event: any) {
        const input: number = parseInt(event.target.value);
        const isANumber: boolean = !isNaN(input);
        if(isANumber){
            const value: number = input;
            setValue(value);
            props.onValueChange(value);
        }
    }

    return(
        <div className={`assessment-question-component ${props.className}`}>
            <div className="assessment-question-copy header-4">{props.assessmentQuestion}</div>
            <div className={`assessment-input-container`}>
                <IonItem className="input-item">
                    <IonInput
                        className={"number-input header-4"}
                        inputMode={"numeric"}
                        type="number"
                        value={value}
                        onInput={handleValueChange}/>
                </IonItem>
                {props.hasLabel && <div className={"input-label header-4"}>{t("days")}</div>}
            </div>
        </div>
    );
}
