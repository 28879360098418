import "./ArticleTile.scss";
import { WPOContentItem } from "../../utils/ApiTypes";
import { ArticleStyle } from "./ContentStyles/Article/ArticleStyle";
import { EventHandler } from "react";

interface Props {
    item: WPOContentItem;
    onClick?: EventHandler<any>;
}

const ArticleTile: React.FC<Props> = (props) => {
    const { item, onClick } = props;
    return (
        <div className="article-item">
            <ArticleStyle content={item} onClick={onClick ? onClick : () => {}} />
        </div>
    );
};

export default ArticleTile;
