import * as React from "react";
import "./CopyToClipboardModal.scss";
import {IonButton, IonModal} from "@ionic/react";
import GlobalMessage from "../GlobalMessage/GlobalMessage";
import {useContext, useState} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    link: string;
    isModalOpen: boolean;
    dismissModal: () => void;
}

export const CopyToClipboardModal: React.FC<Props> = ({title, link, isModalOpen, dismissModal}) => {

    const [globalHeader, setGlobalHeader] = useState<string | null>(null);
    const {isMobileWidth} = useContext(ApplicationContext);
    const { t } = useTranslation();

    async function handleCopyButtonClick () {
        await navigator.clipboard.writeText(link);
        setGlobalHeader("Link copied");
        setTimeout( () => {
            setGlobalHeader(null);
            dismissModal();
        }, 2500);
    }

    return(
        <IonModal
            className={"copy-to-clipboard-modal"}
            showBackdrop={true}
            isOpen={isModalOpen}
            initialBreakpoint={isMobileWidth ? .8 : undefined}
            onWillDismiss={dismissModal}>
            <div className="body-container">
                <h2>{title}</h2>
                <div className="clipboard-item-container">
                    <p className={"clipboard-text " + (isMobileWidth && link.length > 40) ? "body-small-clipboard" : "body-small"}>
                        {link}
                    </p>
                    {!isMobileWidth && <IonButton onClick={handleCopyButtonClick}>{t('copy')}</IonButton>}
                </div>
                {isMobileWidth && <IonButton className="clipboard-button button-small-variant" onClick={handleCopyButtonClick}>{t('copy')}</IonButton>}
            </div>
            <GlobalMessage message={link} header={globalHeader} onClose={() => null} isSuccessMessage={true} />
        </IonModal>
    )
}
