import {FC, useEffect, useContext} from "react";
import "./MuxAudioPlayer.scss";
import MuxPlayer from "@mux/mux-player-react";
import * as React from "react";
import { useMemo } from "react";
import {ApiUser} from "../../utils/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {useLocation} from "react-router-dom";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import LodashDebounce from "lodash.debounce";
import {ApplicationContext} from "../../misc/ApplicationContext";
import { useTranslation } from 'react-i18next';

interface MuxAudioPlayerProps{
    videoId: number;
    muxPlaybackId: string | null;
    muxVideoId: string | null;
    title: string | null;
    currentUser: ApiUser | null;
    trackCuePointEvent?: (cuePoint: number) => {};
    trackPlayEvent?: () => {};
    trackPauseEvent?: () => {};
    trackUnmute?: () => void;
    thumbnail?: string | null;
    isComponentVisible: boolean;
    isLoading: boolean;
    handleApplicationError?: (description: string) => void;
    duration: number;
    autoplay: boolean;
    isInitialMuted?: boolean;
    isPublicVideo?: boolean;
    hideInitialControls?: boolean;
    playlistId?: number;
    onAudioEnd: () => void;
}

export const MuxAudioPlayer: FC<MuxAudioPlayerProps> = (props: MuxAudioPlayerProps) => {

    const audioElementRef = React.useRef<any>(null);
    const location = useLocation();
    const playerUUID = useMemo(() => (Math.random() + 1).toString(36).substring(7), []);
    const { currentlyPlayingId, setCurrentlyPlayingId } = useContext(ApplicationContext);
    const { t } = useTranslation();

    useEffect( () => {
        if(audioElementRef && audioElementRef.current && currentlyPlayingId !== playerUUID){
            audioElementRef.current.pause();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentlyPlayingId]);

    useEffect(() => {
        if (props.autoplay) {
            audioElementRef.current?.play();
        }
    }, [props.autoplay])

    useEffect( () => {
        if(!props.isComponentVisible && audioElementRef.current){
            audioElementRef.current.pause();
            audioElementRef.current = null;
        }
    },[props.isComponentVisible]);

    const trackPlayEvent = async () => {
        setCurrentlyPlayingId(playerUUID);
        let eventProperties = {
            video_title: props.title,
            video_duration: props.duration,
            video_id: props.videoId,
        }
        await AnalyticsService.trackUserAction("audio_play", location.pathname, eventProperties);
        if (props.currentUser) {
            JourneyApiClient.recordActivity('audio-listen');
        }
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: props.title,
            video_duration: props.duration,
            video_id: props.videoId,
        }
        await AnalyticsService.trackUserAction("audio_pause", location.pathname,  eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: props.title,
            video_duration: props.duration,
            video_id: props.videoId,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("audio_checkpoint", location.pathname,  eventProperties);
    }

    function handleTimeChange () {
        if(audioElementRef.current){
            const currentTime = Math.round(audioElementRef.current.currentTime);
            if(props.duration && currentTime && !isNaN(currentTime)){
                if (currentTime % 5 === 0) {
                    JourneyApiClient.logVideoWatch(props.videoId, currentTime, props.duration);
                }

                if(currentTime === Math.round(props.duration/4)){
                    trackCuePointEvent(25);
                } else if(currentTime === Math.round(props.duration/2)){
                    trackCuePointEvent(50);
                } else if(currentTime === Math.round(3*(props.duration/4))){
                    trackCuePointEvent(75);
                } else if(currentTime === Math.round(props.duration * 0.99)){
                    trackCuePointEvent(99);
                }
            }
        }
    }

    const debouncedHandleTime = LodashDebounce(handleTimeChange, 900, {maxWait: 900})

    return(
        <div className="mux-audio-player-component">
            {props.isLoading ? <></> : (props.muxPlaybackId && props.muxVideoId) ?
                <>
                    <MuxPlayer
                        audio={true}
                        streamType="on-demand"
                        ref={audioElementRef}
                        autoPlay={props.autoplay}
                        primaryColor={"rgb(65,65,65)"}
                        onEnded={props.onAudioEnd}
                        secondaryColor={"rgba(128,128,128,0.32)"}
                        muted={false}
                        playbackId={props.muxPlaybackId}
                        poster={props.thumbnail ? props.thumbnail : ""}
                        onPlay={trackPlayEvent}
                        onPause={trackPauseEvent}
                        onTimeUpdate={debouncedHandleTime}
                        metadata={{
                            video_id: props.videoId,
                            video_title: props.title,
                            viewer_user_id: props.currentUser ? props.currentUser.id : null,
                            player_name: 'vod-audio-player',
                        }}/>
                        <div className="caption">{t("Press play to listen to the Daily Journey")}</div>
                </>
                : <div className="mux-player-error-copy">{t("There was an error displaying this audio.")}</div>
            }
        </div>
    )
}