import './SignUpFormError.scss';
import React from 'react';

export const SignUpFormError: React.FC = () => {

        return (
            <div className='sign-up-form-error-component paper-container'>
                <h1 className='sign-up-title'> Error in Registration </h1>

                <div className="email-notice">
                    Unable to register an account at this time,
                    please contact us at <a href="mailto:support@journey.live">support@journey.live</a>
                </div>
            </div>
        );
};
