import { IonButton, IonIcon } from '@ionic/react';
import { globeOutline, openOutline } from 'ionicons/icons';
import './CallSupportButton.scss';
import React, { useContext, useEffect, useRef, useState } from 'react';
import PhoneIcon from "../../assets/images/phone-blue-gradient.svg";
import { GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';
import { ApplicationContext } from '../../misc/ApplicationContext';
import ExpandMore from "../../assets/images/expand_more.svg";
import "flag-icons/css/flag-icons.min.css";
import { EAP_SUPPORT_PHONES_PATH } from '../../utils/utils';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";

interface CallSupportButtonProps {
    className?: string;
    benefitsInfo: GetCompanyBenefitsInfoResponse;
    onClick?: () => void;
}

const CallSupportButton: React.FC<CallSupportButtonProps> = (props: CallSupportButtonProps) => {

    const { currentUser, language } = useContext(ApplicationContext);
    const [isListVisible, setIsListVisible] = useState(false);
    const dropdownRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const history = useHistory();

    // Priority of displayed phone number: current language country (selected in UI) > user's country > default country (US)
    const phoneNumbersInCurrentLanguageCountry = props.benefitsInfo.supportPhones.filter(phone => phone.countryCode?.toLowerCase() === language?.countryId.toLowerCase());
    const phoneNumbersInUserCountry = props.benefitsInfo.supportPhones.filter(phone => phone.countryCode?.toLowerCase() === currentUser?.country.toLowerCase());
    const phoneNumbersInDefaultCountry = props.benefitsInfo.supportPhones.filter(phone => phone.countryCode?.toLowerCase() === 'us');
    const phoneNumberDisplayed = phoneNumbersInCurrentLanguageCountry.length > 0 ? phoneNumbersInCurrentLanguageCountry[0].subtitle : phoneNumbersInUserCountry.length > 0 ? phoneNumbersInUserCountry[0].subtitle : phoneNumbersInDefaultCountry.length > 0 ? phoneNumbersInDefaultCountry[0].subtitle : props.benefitsInfo.supportPhones[0].subtitle;

    const toggleListVisibility = () => {
        setIsListVisible(!isListVisible);
    };

    const handleOpenSupportPage = () => {
        setIsListVisible(!isListVisible);
        props.onClick && props.onClick();
        history.push(EAP_SUPPORT_PHONES_PATH);
    };

    // Close dropdown when clicking outside of it
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsListVisible(false);
            }
        };

        // Add event listener when the dropdown is visible
        if (isListVisible) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Clean up event listener on unmount or when the dropdown closes
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isListVisible]);

    return (
        <div className="phone-list-container" ref={dropdownRef}>
            <IonButton
                className={`${props.className}`}
                onClick={toggleListVisibility}>
                <IonIcon src={PhoneIcon} className={`call-support-button-icon`} />
                {phoneNumberDisplayed}
                <IonIcon className={`arrow ${isListVisible ? "up" : "down"}`} src={ExpandMore} />
            </IonButton>
            {isListVisible && (
                <div className="dropdown-list">
                    {phoneNumbersInCurrentLanguageCountry.length > 0 && phoneNumbersInCurrentLanguageCountry.map((phone, index) => (
                        <div key={index} className="dropdown-item caption" onClick={() => window.location.href = `tel:${phone.phoneNumber}`}>
                            <span className={`flag-icon fi fi-${phone.flagName?.toLowerCase()}`} />
                            <a className="caption"> {phone.subtitle}</a>
                        </div>
                    ))}
                    <div className="dropdown-item" onClick={handleOpenSupportPage}>
                        <IonIcon className={`flag-icon`} icon={globeOutline} color="secondary" />
                        <a className="caption">{t("More countries")}</a>
                        <a className={`open-icon`} target='_blank' rel='noreferrer'>
                            <IonIcon icon={openOutline} />
                        </a>
                    </div>
                </div>
            )}
          </div>
    );
};

export default CallSupportButton;
