import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './ClampLines.scss';
import { t } from 'i18next';

interface ClampLinesProps {
  text: string;
  showButton: boolean;
  lines?: number;
  ellipsis?: string;
  moreText?: string;
  lessText?: string;
  className?: string;
}

const ClampLines: React.FC<ClampLinesProps> = ({
  text,
  lines = 3,
  ellipsis = '...',
  moreText = t('Expand'),
  lessText = t('Collapse'),
  className = '',
  showButton,
}) => {
  const [clamped, setClamped] = useState(true);
  const [isTruncated, setIsTruncated] = useState(false);
  const [truncatedHtml, setTruncatedHtml] = useState(text);
  const textRef = useRef<HTMLElement>(null);
  const truncatedTextRef = useRef<HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    truncateText();
    window.addEventListener('resize', truncateText);
    return () => {
      window.removeEventListener('resize', truncateText);
    };
  }, [text, lines]);

  const truncateText = () => {
    const container = containerRef.current;
    const truncatedTextEl = truncatedTextRef.current;

    if (!container || !truncatedTextEl) return;

    const lineHeight = parseFloat(window.getComputedStyle(truncatedTextEl).lineHeight);
    const maxHeight = lineHeight * lines;

    if (container.scrollHeight <= maxHeight) {
        setIsTruncated(false);
      return;
    }

    setIsTruncated(true);

    let start = 0;
    let end = text.length;
    let middle;

    while (start <= end) {
      middle = Math.floor((start + end) / 2);
      truncatedTextEl.innerHTML = text.substring(0, middle) + ellipsis;

      if (truncatedTextEl.scrollHeight <= maxHeight) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }

    let truncatedText = text.substring(0, end) + ellipsis;
    setTruncatedHtml(truncatedText);
  };

const toggleClamp = () => {
    setClamped(!clamped);
};

  return (
    <div ref={containerRef} className="clamp-lines-container">
      {!clamped ? <><div ref={textRef as React.RefObject<any>} className={`${className} ${clamped ? 'clamp-lines-text' : ''}`} dangerouslySetInnerHTML={{ __html: text ?? "" }}/>{isTruncated && showButton && <button className="clamp-lines-button" onClick={toggleClamp}>{lessText}</button>}</> :
      <><div ref={truncatedTextRef as React.RefObject<any>} className={`${className}`} dangerouslySetInnerHTML={{ __html: truncatedHtml}}/>{isTruncated && showButton && <button className="clamp-lines-button" onClick={toggleClamp}>{moreText}</button>}</> }
  
    </div>
  );
};

ClampLines.propTypes = {
  text: PropTypes.string.isRequired,
  lines: PropTypes.number,
  ellipsis: PropTypes.string,
  moreText: PropTypes.string,
  lessText: PropTypes.string,
  className: PropTypes.string,
};

export default ClampLines;
