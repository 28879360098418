import React, { useContext } from "react";
import { IonButton, IonButtons, IonHeader, IonToolbar } from "@ionic/react";
import './Toolbar.scss';
import Logo from "../../assets/images/journey.png";
import { ApplicationContext } from "../../misc/ApplicationContext";
import {
    HOME_PATH,
    RESOURCES_PATH,
    DAILY_JOURNEY_PATH,
    SEARCH_PATH,
    BENEFITS_PATH,
    PERSONAL_PLAN_PATH, PROFILE_PATH,
    HR_HUB_PAGE_PATH
}
    from "../../utils/utils";
import { useHistory, useLocation } from "react-router-dom";
import NavigationHelper from "../../misc/NavigationHelper";
import { InstantHelpButton } from "../InstantHelpSlider/InstantHelpButton";
import ClientConfig from "../../utils/ClientConfig";
import { ProfileProgressBar } from "../Profile/ProfileProgressBar";
import { useTranslation } from 'react-i18next';
import { Feature } from "../Feature/Feature";
import { HR_HUB_ADMIN } from "../../utils/ApiTypes";
import { MiniLanguagesMenu } from "../MiniLanguageMenu/MiniLanguagesMenu";
import { MiniLanguageMenuHeader } from "../MiniLanguageMenu/MiniLanguageMenuHeader";
import useAppLanguages from "../CustomHooks/useAppLanguages";

interface ToolbarProps {
    handleOpenLoginModal?: () => void;
    hideMenuButtons?: boolean;
}

export const Toolbar: React.FC<ToolbarProps> = (props: ToolbarProps) => {
    
    const { handleLogout, currentUser, hasBenefitsInThisLocale, hasDailyJourneyAvailable, hasCompanyCountries, fullLanguageSupport, setIsInstantHelpSliderOpen, isMiniLanguageMenuOpen, hasWpoContent } = useContext(ApplicationContext);
    const loc = useLocation();
    const history = useHistory();
    const profileHeaderRef = React.useRef<any>(null);
    const instantHelpRef = React.useRef<any>(null);
    const environmentTitle = ClientConfig.environment === "production" ? "" : ClientConfig.environment;
    const { t } = useTranslation();
    const languagesRightMargin = props.hideMenuButtons ? instantHelpRef?.current?.clientWidth : profileHeaderRef?.current?.clientWidth + instantHelpRef?.current?.clientWidth;
    const { languages } = useAppLanguages(currentUser?.companyId);

    const handleLoginLogoutButtonClick = () => {
        if (!currentUser && props.handleOpenLoginModal) {
            props.handleOpenLoginModal();
        } else {
            handleLogout();
        }
    }

    const handleToolbarButtonClick = async (path: string) => {
        const currentPath: string = "/" + NavigationHelper.getParam(1, window.location.pathname);
        if (path === DAILY_JOURNEY_PATH) {
            history.push(DAILY_JOURNEY_PATH);
            return;
        }
        if (!currentUser) {
            if (props.handleOpenLoginModal) {
                props.handleOpenLoginModal();
            }
        } else {
            history.push(path);
        }
    }

    const hasHrHubAccess = currentUser?.userRoles.includes(HR_HUB_ADMIN);

    return (
        <IonHeader className={`header toolbar-component-wrapper`}>
            {hasCompanyCountries && isMiniLanguageMenuOpen && <MiniLanguagesMenu companyId={currentUser?.companyId} sendToHome={true} rightMargin={languagesRightMargin} languages={languages} />}
            <IonToolbar className={`toolbar-component `}>
                <div className="toolbar-container">
                    <div className="toolbar-logo-container">
                        <a href="/">
                            <img src={currentUser?.company?.appLogoUrl ?? Logo} className="logo-image" alt="Logo" />
                        </a>
                        {environmentTitle &&
                            <span className="environment-title">{environmentTitle}</span>
                        }
                    </div>

                    {!props.hideMenuButtons &&
                        <IonButtons className="toolbar-buttons">
                            {fullLanguageSupport && hasDailyJourneyAvailable &&
                                <IonButton className={`toolbar-button button-small ${loc.pathname === DAILY_JOURNEY_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(DAILY_JOURNEY_PATH)}>{t("Daily Journey")}</IonButton>
                            }
                            {fullLanguageSupport && <Feature feature='search'><IonButton className={`button-small ${loc.pathname === SEARCH_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(SEARCH_PATH)}>{t('explore')}</IonButton></Feature>}
                            {hasBenefitsInThisLocale && <IonButton className={`button-small ${loc.pathname === BENEFITS_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(BENEFITS_PATH)}>{t('benefits')}</IonButton>}
                            {fullLanguageSupport && hasWpoContent && <IonButton className={`button-small ${loc.pathname === RESOURCES_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(RESOURCES_PATH)}>{t('resources')}</IonButton>}
                            {fullLanguageSupport && <Feature feature='personal-plan'><IonButton className={`button-small ${loc.pathname === PERSONAL_PLAN_PATH ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(PERSONAL_PLAN_PATH)}>{t("Personal Plan")}</IonButton></Feature>}
                            {hasHrHubAccess && <IonButton className={`button-small ${loc.pathname.startsWith(HR_HUB_PAGE_PATH) ? 'selected' : ''}`} fill="clear" onClick={() => handleToolbarButtonClick(HR_HUB_PAGE_PATH)}>HR Hub</IonButton>}
                        </IonButtons>
                    }

                    <IonButtons className="toolbar-buttons toolbar-buttons-auth">
                        {currentUser ?
                            <>
                                {hasCompanyCountries && <MiniLanguageMenuHeader />}
                                {!props.hideMenuButtons && 
                                    <ProfileProgressBar reference={profileHeaderRef} onProfileClick={() => handleToolbarButtonClick(PROFILE_PATH)} />
                                }
                            </>
                            :
                            <IonButton className="toolbar-button button-small login-button" fill="clear" onClick={handleLoginLogoutButtonClick}>
                                {t("Log in")}
                            </IonButton>
                        }
                        {currentUser &&
                            <div ref={instantHelpRef}><InstantHelpButton onClick={() => setIsInstantHelpSliderOpen(true)} /></div>
                        }
                    </IonButtons>

                </div>
            </IonToolbar>
        </IonHeader>
    )
}