import React, {SyntheticEvent, useContext} from 'react';
import './AddToPlaylist.scss';
import {IonIcon} from "@ionic/react";
import RemoveFromList from "../../assets/images/list-remove.svg";
import AddToList from "../../assets/images/add-to-list.svg";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { useTranslation } from 'react-i18next';

export type AddToPlaylistProps = {
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
};

export function AddToPlaylist(props: AddToPlaylistProps) {

    const { currentUser } = useContext(ApplicationContext);
    const { t } = useTranslation();

    if(currentUser) {
        return(
            <div className="add-to-playlist-component">
                {props.isSaved && <>
                    <div className='tooltip remove'>{t("Remove from My List")}</div>
                    <IonIcon onClick={props.handleRemoveFromList} src={RemoveFromList}/>
                </>}
                {!props.isSaved && <>
                    <div className='tooltip add'>{t("Add to My List")}</div>
                    <IonIcon onClick={props.handleAddToList} src={AddToList}/>
                </>}
            </div>
        )
    } else {
        return (
            <></>
        )
    }
}