import React from "react";
import {IonButton, IonIcon} from "@ionic/react";
import leftArrow from "../../assets/images/left-arrow-gray.svg";
import "./BackButton.scss";

interface BackButtonProps {
    onClick: () => void;
}

export const BackButton: React.FC<BackButtonProps> = (props: BackButtonProps) => {
    return(
        <IonButton className="back-button" fill="clear" onClick={props.onClick}>
            <IonIcon className="back-icon" icon={leftArrow}/>
        </IonButton>
    );
}
