import React, {useEffect, useState} from 'react';
import {IonAlert} from '@ionic/react';
import './GlobalMessage.scss';

interface Props {
    message: string | null;
    header: string | null;
    onClose: () => void;
    isSuccessMessage?: boolean;
}


const GlobalMessage: React.FC<Props> = (props: Props) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect( () => {
        if(isOpen){
            setTimeout( () => {
                props.onClose();
            }, 8000);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect( () => {
        if(props.message && props.header){
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [props.message, props.header])

    return(
        <IonAlert
            isOpen={isOpen}
            cssClass={`global-error-component ${props.isSuccessMessage ? "success" : "error"}`}
            onWillDismiss={props.onClose}
            backdropDismiss={true}
            header={props.header || undefined}
            message={props.message || undefined}
        />
    )
}

export default GlobalMessage;
