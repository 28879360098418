import "./Benefit.scss";
import * as React from 'react';
import DOMPurify from 'dompurify';
import { ApiUser, Benefit as ApiBenefit } from "../../utils/ApiTypes";

interface Props {
    benefit: ApiBenefit;
    currentUser: ApiUser | null;
}

export const Benefit: React.FC<Props> = ({benefit, currentUser}) => {
    const description: string = DOMPurify.sanitize(benefit.description ?? "");

    return(
        <div className='benefit-component'>
            {
                benefit.redirectUrl ?
                    <a target="_blank" href={benefit.redirectUrl || ""} rel="noreferrer">
                        <img src={benefit.imageUrl || ""} alt="benefit"/>
                    </a>
                    :
                    <img src={benefit.imageUrl || ""} alt="benefit"/>
            }
            <div className={"subtitle1 benefit-name"}>{benefit.name}</div>
            <div className={"body-small"} dangerouslySetInnerHTML={{ __html: description }}></div>
        </div>
    );
};
