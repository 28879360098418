import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {
    IonButton,
    IonContent, IonIcon
} from "@ionic/react";
import "./AssessmentSteps.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {ApiMonthlyCheckinAnswerV101, User} from '../../utils/ApiTypes';
import {AssessmentQuestion} from "./AssessmentQuestion";
import {NumberInputAssessmentQuestion} from "./NumberInputAssessmentQuestion";
import {CheckboxAssessmentQuestion} from "./CheckboxAssessmentQuestion";
import {AssessmentFinishedStep} from "./AssessmentFinishedStep";
import {useTranslation} from "react-i18next";
import AnalyticsService from "../../misc/AnalyticsService";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {BackButton} from "../BackButton/BackButton";
import {ProgressIndicator} from "../ProgressIndicator/ProgressIndicator";
import rightArrow from "../../assets/images/right-arrow-white.svg";
import {AssessmentRange} from "./AssessmentRange";
import { useLocation } from 'react-router';
import { HasFeatureEnabled } from "../Feature/Feature";

interface AssessmentStepsProps{
    progress: number,
    currentUser: User;
}


export const AssessmentSteps: React.FC<AssessmentStepsProps> = (props: AssessmentStepsProps) => {

    const {isMobileWidth, handleGeneralError, handleHasCompletedAssessment, silentlyRefreshUserData} = useContext(ApplicationContext);
    const [progress, setProgress] = useState<number>(props.progress);
    const [isCurrentAnswerNull, setIsCurrentAnswerNull] = useState<boolean>(true);
    const [isMultipleChoiceAssessment, setIsMultipleChoiceAssessment] = useState<boolean>(false);
    const [isAssessmentNegative, setIsAssessmentNegative] = useState<boolean>(false);
    const hasAnsweredCareerAndInterests: boolean = props.currentUser.interests !== null && props.currentUser.career !== null;
    let showBackAndCloseButtons: boolean = progress >= 0 && progress <= 7;
    const lastStepNumber = 8;
    const [formValues, setFormValues] = useState<ApiMonthlyCheckinAnswerV101>({
        stressLevel: null,
        feelingsFrequency: null,
        manageStressLevel: null,
        feelingsFrequencyAffectWork: null,
        daysEmotionallyChallenging: null,
        priorities: null,
    });
    const { t } = useTranslation();
    const location = useLocation();
    const hasProactiveClinicalCare = HasFeatureEnabled("negative-check-in-prompt");

    useEffect(() => {
        //The questions that correspond to progress 4,5 and 7 are considered multiple choice and therefore the user needs to press continue button
        if(progress === 4 || progress === 5 || progress === 7){
            setIsMultipleChoiceAssessment(true);
        } else {
            setIsMultipleChoiceAssessment(false);
        }
        //Saving user answers to the formValues object
        if(progress > 0){
            handleSubmitAssessmentProgress(formValues);
        }

        //At this step we know if its a negative assessment for Clinical Care
        if(progress === 5 && hasProactiveClinicalCare){
            JourneyApiClient.logClinicalCareMonthlyAssessment(formValues).then((isNegative) => {
                setIsAssessmentNegative(isNegative);
            });
        }
        
        //If last step then set assessment as completed
        if(progress === lastStepNumber){
            handleHasCompletedAssessment(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [progress]);

    useEffect( () => {
        //Do not remove this since this allows users to continue were they left off
        setProgress(props.progress);
    },[props.progress])

    function handleBackButtonPressed () {
        setProgress(progress - 1);
        setIsCurrentAnswerNull(true);
    }

    function showNextStep () {
        if(hasAnsweredCareerAndInterests && progress === 5){
            setProgress(lastStepNumber)
        } else {
            setProgress(progress + 1);
        }
        setIsCurrentAnswerNull(true);
        trackAssessmentStepCompleted(progress+1);
    }


    async function trackAssessmentStepCompleted (step: number){
        const eventName = `monthly_assessment_step_completed_${step.toString()}`;
        await AnalyticsService.trackUserAction(eventName, location.pathname, {is_assessment_negative: isAssessmentNegative});
    }

    function handleValueChange (name: string, value: number | null, event?: any)  {
        setFormValues({...formValues, [name]: value});
        if((value !== null && value >=0 && value <= 31)){
            setIsCurrentAnswerNull(false);
        } else {
            setIsCurrentAnswerNull(true);
        }
        //If the device is mobile width or multiple choice assessment the user needs to press continue button otherwise
        //we automatically move them forward for the next question
        if(!isMobileWidth && !isMultipleChoiceAssessment){
            showNextStep();
        }
    }

    function handlePrioritiesValueChange (stringedArray: string) {
        setFormValues({...formValues, ["priorities"]: stringedArray});
        if((JSON.parse(stringedArray).length === 0)){
            setIsCurrentAnswerNull(true);
        } else {
            setIsCurrentAnswerNull(false);
        }
    }

    function handleCareerSelection (career: string){
        if(career !== ""){
            //Formatting career to not be a string[]
            let careerString: string = JSON.parse(career)[0];
            setIsCurrentAnswerNull(false);
            showNextStep();
            saveUserCareer(careerString);
        } else {
            setIsCurrentAnswerNull(true);
        }
    }

    async function saveUserCareer (career: string){
        try{
            await JourneyApiClient.saveUserCareer(career);
            //Refresh the user so other componentns are aware of the new value for career that is stored in the user object
            await silentlyRefreshUserData();
        } catch(e){
            handleGeneralError("Could not save user career", e);
        }
    }

    function handleContentTopicChange (interests: string) {
        if((JSON.parse(interests).length === 0)){
            setIsCurrentAnswerNull(true);
        } else {
            saveUserInterests(interests);
            setIsCurrentAnswerNull(false);
        }
    }

    async function saveUserInterests (interests: string){
        try{
            await JourneyApiClient.saveUserInterests(interests);
            //Refresh the user so other components are aware of the new value for interests that is stored in the user object
            await silentlyRefreshUserData();
        } catch(e){
            handleGeneralError("Could not save user interests", e);
        }
    }

    function handleSubmitAssessmentProgress (formValues: ApiMonthlyCheckinAnswerV101){
        JourneyApiClient.saveMonthlyAssessmentV101(formValues).catch((e) => {
            handleGeneralError("Could not save assessment answer", e);
        });
    }

    function renderAssessment (step: number){
        switch (step){
            case 0:
                return(
                    <AssessmentRange
                        assessmentQuestion={t("During the last month, how would you rate your stress?")}
                        assessmentLowerBoundText={t('low')}
                        assessmentUpperBoundText={t('high')}
                        onValueChange={(value) => handleValueChange("stressLevel", value)}
                        assessmentValueOptions={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                        isAlignmentVertical={isMobileWidth}
                        isMobileWidth={isMobileWidth}/>
                )
            case 1:
                return(
                    <AssessmentQuestion
                        assessmentQuestion={t("How often did you feel sad, anxious, worried, or irritable?")}
                        assessmentLowerBoundText={null}
                        assessmentUpperBoundText={null}
                        onValueChange={(value) => handleValueChange("feelingsFrequency", value)}
                        assessmentValueOptions={[t('never'), t("Almost never"), t('sometimes'), t("Fairly often"), t("Very often")]}
                        isAlignmentVertical={true}
                        wideButtons={true}
                        isMobileWidth={isMobileWidth}/>
                )
            case 2:
                return(
                    <AssessmentRange
                        assessmentQuestion={t("How would you rate your ability to manage your stress and feelings?")}
                        assessmentLowerBoundText={t("Very Poor")}
                        assessmentUpperBoundText={t('exceptional')}
                        onValueChange={(value) => handleValueChange("manageStressLevel", value)}
                        assessmentValueOptions={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]}
                        isAlignmentVertical={isMobileWidth}
                        isMobileWidth={isMobileWidth}/>
                )
            case 3:
                return(
                    <AssessmentQuestion
                        assessmentQuestion={t("How often did your stress or feelings get in the way of work?")}
                        assessmentLowerBoundText={null}
                        assessmentUpperBoundText={null}
                        onValueChange={(value) => handleValueChange("feelingsFrequencyAffectWork", value)}
                        assessmentValueOptions={[t('never'), t("Almost never"), t('sometimes'), t("Fairly often"), t("Very often")]}
                        isAlignmentVertical={true}
                        wideButtons={true}
                        isMobileWidth={isMobileWidth}/>
                )
            case 4:
                return(
                    <NumberInputAssessmentQuestion
                        className="assessment-steps-number-input"
                        assessmentQuestion={t("How many days were emotionally challenging (you didn’t feel like yourself, low energy, etc.)?")}
                        onValueChange={(value) => handleValueChange("daysEmotionallyChallenging", value)} hasLabel={true}/>
                )
            case 5:
                return(
                    <CheckboxAssessmentQuestion
                        assessmentQuestion={t("What would you like to work on or improve over the next 30 days? Pick your top 3.")}
                        onValueChange={(value) => handlePrioritiesValueChange(JSON.stringify((value)))}
                        assessmentValueOptions={[t('stress'), t('sleep'), t('anxiety'), t('relationships'), t('happiness'), t('productivity'), t('communication'), t("Work-Life Balance"), t('other')]}
                        checkboxLimit={3}/>
                )
            case 6:
                return(
                    <CheckboxAssessmentQuestion
                        assessmentQuestion={t("What kind of work do you do?")}
                        onValueChange={(career) => handleCareerSelection(JSON.stringify(career))}
                        assessmentValueOptions={[t('administrative'), t('creative'), t("Customer Service"), t("Engineer/Product"), t("Executive/C - Suite"), t("Food Services"), t('healthcare'), t('hr'), t('industrial'), t('manager'), t('lawyer'), t('retail'), t('sales'), t('other')]}
                        checkboxLimit={1}/>
                )
            case 7:
                return(
                    <CheckboxAssessmentQuestion
                        assessmentQuestion={t("What content is interesting to you? Check all that apply")}
                        onValueChange={(interests) => handleContentTopicChange(JSON.stringify(interests))}
                        assessmentValueOptions={[t('aapi'), t('bipoc'), t('latinx'), t("LGBTQ+"), t('differently-abled'), t('men'), t('women'), t('parents'), t('caregiver'), t('nutrition'), t('finance'), t("Digital Hygiene")]}
                        checkboxLimit={12}/>
                )
            case 8:
                return(
                    <AssessmentFinishedStep
                        isComponentVisible={progress === lastStepNumber}
                        isMobileWidth={isMobileWidth}
                        currentUser={props.currentUser}
                        isAssessmentNegative={isAssessmentNegative}/>
                )
        }
    }

    return(
        <IonContent className="assessment-steps-component">
            {showBackAndCloseButtons && <div className="top-container">
                {progress > 0 && <div className="back-button-container"><BackButton onClick={handleBackButtonPressed}/></div>}
                <div className={`progress-bar-container ${(progress === 0 || progress === lastStepNumber) && "no-right-margin"}`}>
                    <ProgressIndicator isMobileWidth={isMobileWidth} currentStep={progress} totalSteps={hasAnsweredCareerAndInterests ? 6 : 8}/>
                </div>
            </div>}
            <div className="bottom-container">
                {renderAssessment(progress)}
            </div>
            {(isMobileWidth || isMultipleChoiceAssessment) && progress >= 0 && progress !==lastStepNumber &&
            <div className="assessment-continue-button-container">
                <IonButton className="assessment-continue-button button-medium-variant" onClick={showNextStep} disabled={isCurrentAnswerNull}>
                    {t('continue')}<IonIcon src={rightArrow}/>
                </IonButton>
            </div>
            }
        </IonContent>
    )
};
