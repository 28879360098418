import { IonContent, IonModal } from "@ionic/react";
import "./CommunicationsOptInModal.scss";
import { ExitButton } from "../ExitButton/ExitButton";
import React, { useContext, useEffect } from "react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import AnalyticsService from "../../misc/AnalyticsService";
import { useLocation } from "react-router-dom";
import { Feature, FeatureShowContents } from "../Feature/Feature";

interface CommunicationsOptInModalProps {

}

export const CommunicationsOptInModal: React.FC<CommunicationsOptInModalProps> = (props: CommunicationsOptInModalProps) => {

    const location = useLocation();
    const { handleGeneralError, isCommunicationsOptInModalOpen, setIsCommunicationsOptInModalOpen } = useContext(ApplicationContext);

    useEffect(() => {
        (async function () {
            if (isCommunicationsOptInModalOpen) {
                try {
                    trackFormOpen();
                    await JourneyApiClient.userMarketingOptIn();
                } catch (e) {
                    await handleExitButtonPressed();
                    handleGeneralError("Unable to opt in communication emails. Try again!", e);
                }
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCommunicationsOptInModalOpen]);

    async function trackFormOpen() {
        await AnalyticsService.trackUserAction("communications_opt_in_modal_open", location.pathname);
    }

    async function handleExitButtonPressed() {
        await new Promise(r => setTimeout(r, 10));
        setIsCommunicationsOptInModalOpen(false);
    }

    return (
        <IonModal
            className={`communications-opt-in-modal-component`}
            showBackdrop={true}
            isOpen={isCommunicationsOptInModalOpen}
            initialBreakpoint={.95}
            onWillDismiss={handleExitButtonPressed}
        >
            <IonContent>
                <div className="communications-opt-in-container">
                    <Feature feature="has-eap">
                        <div className="communications-title body-large">
                            Thanks for signing up for the Daily Journey from Journey Proactive EAP!
                        </div>
                        <div className="body-medium">
                            Now you’ll have a daily dose of mental health inspiration delivered straight to your inbox each day. We encourage you to use the Daily Journey as a reminder to make time for your mental health and to explore all of Journey’s features. From the daily check-in to help monitor your mood to the personalized resources you need to strengthen your well-being, with Journey Proactive EAP it’s all right at your fingertips.
                        </div>
                    </Feature>

                    <Feature feature="has-eap" showContents={FeatureShowContents.whenDisabled}>
                        <div className="communications-title body-large">
                            Thanks for signing up for the Daily Journey from Journey Live!
                        </div>
                        <div className="body-medium">
                            Now you’ll have a daily dose of mental health inspiration delivered straight to your inbox each day. We encourage you to use the Daily Journey as a reminder to make time for your mental health and to explore all of Journey’s features. From the daily check-in to help monitor your mood to the personalized resources you need to strengthen your well-being, with Journey Live it’s all right at your fingertips.
                        </div>
                    </Feature>

                    <div className="exit-button-container"><ExitButton onClick={handleExitButtonPressed} /></div>
                </div>
            </IonContent>
        </IonModal>
    )
}