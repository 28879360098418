import * as React from 'react';
import {useContext, useEffect} from "react";
import {
    IonContent,
    IonPage
} from "@ionic/react";
import "./AskAQuestionPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { AskAQuestion } from '../../components/AskAQuestion/AskAQuestion';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { useTranslation } from 'react-i18next';

export const AskAQuestionPage: React.FC = () => {

    const isComponentVisible = useIsComponentVisible();
    const { t } = useTranslation();


    return(
        <IonPage className="ask-a-question-page">
            
            <ResponsiveNavigationMenu title={t("Ask a Question")} />

            <IonContent className="ask-a-question-page-container">
                <AskAQuestion/>
            </IonContent>
        </IonPage>
    )
};
