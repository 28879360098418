import React, {useContext} from 'react';
import {LoggedOutNavigation} from "./LoggedOutNavigation";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {LoggedInNavigation} from "./LoggedInNavigation";
import {PleaseWait} from "../PleaseWait/PleaseWait";
import {IonReactRouter} from "@ionic/react-router";
import {AppUrlListener} from "./AppUrlListener";

export const AppNavigation: React.FC = () => {

    const {currentUser, isAppLoaded, isCurrentUserLoading} = useContext(ApplicationContext);

    return (
        <IonReactRouter>
            <AppUrlListener />
            {
                (!isAppLoaded || isCurrentUserLoading) ?
                    <PleaseWait/>
                    :
                    currentUser ?
                        <LoggedInNavigation />
                        :
                        <LoggedOutNavigation />
            }
        </IonReactRouter>
    );

};


