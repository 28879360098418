import "./ChipFilter.scss";
import {IonChip, IonIcon, IonLabel} from "@ionic/react";
import { checkmarkCircle } from "ionicons/icons";
import closeIconWhite from "../../assets/images/close-icon-white.svg";

export type ChipFilterProps = {
    name: string;
    id: string;
    handleChipClick: (name: string) => void;
    selectedChips: string[];
    className?: string;
    labelClassName?: string;
    checkmarkIcon?: boolean;
};

/*
* name: String that goes on the chip
* id: Id of the item that the chip represents, id is in string format
* selectedChips: Array of ids in string format that represent the selected chips
*/
export function ChipFilter(props: ChipFilterProps) {

    const isSelected: boolean = props.selectedChips.includes(props.id);

    return(
        <IonChip
            onClick={() => props.handleChipClick(props.id)}
            className={`chip-filter-component ${isSelected && "selected"} ${props.className}`} >
            {isSelected && props.checkmarkIcon && <IonIcon className="chip-filter-icon" icon={checkmarkCircle}/>}
            <IonLabel className={`${props.labelClassName ?? `chip-filter-label`} ${isSelected && props.labelClassName}-variant`}>{props.name}</IonLabel>
            {isSelected && !props.checkmarkIcon && <IonIcon className="chip-filter-icon" icon={closeIconWhite}/>}
        </IonChip>
    )
}