import "./ContinuousDailyJourney.scss";
import * as React from "react";
import {IonButton} from "@ionic/react";
import {ApiDailyJourney, User} from "../../utils/ApiTypes";
import {useEffect, useState} from "react";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {DailyJourney} from "../DailyJourney/DailyJourney";
import {LoginModalComponent} from "../LoginModalComponent/LoginModalComponent";
import { useTranslation } from 'react-i18next';

interface Props {
    dailyJourney: ApiDailyJourney;
    isComponentVisible: boolean;
    currentUser?: User | null;
    showSingle?: boolean;
}

export const ContinuousDailyJourney: React.FC<Props> = ({dailyJourney, isComponentVisible, currentUser, showSingle}) => {

    const [dailyJourneysOldestDate, setDailyJourneysOldestDate] = useState<Date>(new Date(dailyJourney.publicationDate));
    const [dailyJourneyPages, setDailyJourneyPages] = useState<ApiDailyJourney[]>(new Array(dailyJourney));
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState<boolean>(false);
    const [audioPlayingIndex, setAudioPlayingIndex] = useState<number>(-1);
    const { t } = useTranslation();

    useEffect(() => {
        if(dailyJourney){
            let initialDailyJourney = new Array<ApiDailyJourney>();
            initialDailyJourney.push(dailyJourney);
            setDailyJourneyPages(initialDailyJourney);

            loadOlderDailyJourneys();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dailyJourney]);

    async function loadOlderDailyJourneys() {
        setIsLoading(true);
        const olderDailyJourneysData: ApiDailyJourney[] = await JourneyApiClient.getOlderDailyJourneyInfo("10", dailyJourneysOldestDate);
        if(olderDailyJourneysData.length){
            setDailyJourneysOldestDate(olderDailyJourneysData[olderDailyJourneysData.length - 1].publicationDate);
            setDailyJourneyPages(dailyJourneyPages.concat(olderDailyJourneysData));
        }
        setIsLoading(false);
    }

    async function handleOlderButtonPressed() {
        //send data to segment
        if(!currentUser){
            setIsLoginModalOpen(true);
            return;
        }
        await loadOlderDailyJourneys();
    }

    function handleAudioDjEnded(djIndex: number) {
        setAudioPlayingIndex(djIndex + 1);
    }

    return(<>
        <div className="continuous-daily-journey-component">

            <LoginModalComponent
                isComponentVisible={isComponentVisible}
                isModalOpen={isLoginModalOpen}
                closeModal={() => setIsLoginModalOpen(false)}
            />
            {showSingle && <DailyJourney showTalkToSomeone={true} dailyJourney={dailyJourney} isComponentVisible={isComponentVisible}/>}
            {!showSingle && dailyJourneyPages.map((dJ: ApiDailyJourney, index: number) => {
                return(<DailyJourney showTalkToSomeone={index === 0} playAudio={index === audioPlayingIndex} onAudioEnd={() => handleAudioDjEnded(index)} key={index} dailyJourney={dJ} showTruncatedArticle={dailyJourney.id !== dJ.id} isComponentVisible={isComponentVisible}/>)
            })}
        </div>
        {!showSingle && <div className="older-button-container">
            <IonButton className="button" disabled={isLoading} onClick={handleOlderButtonPressed}>{t("Show Older Daily Journeys")}</IonButton>
        </div>}
        </>
    )
}