import { useTranslation } from "react-i18next";
import { EAPDescriptionType } from "../../utils/ApiTypes";
import "./TalkToSomeone.scss";

interface TalkToSomeoneProps {
    eapDescription: `${EAPDescriptionType}`;
}

export function EapDescription(props: TalkToSomeoneProps) {
    
    const { t } = useTranslation();
    const {eapDescription} = props;

    const eapDescriptionMap: Record<EAPDescriptionType, string[]> = {
        [EAPDescriptionType.Default]: [
            t("work or personal stress overwhelms me"),
            t("workplace conflicts need resolution"),
            t("personal crises become hard to handle"),
            t("I need advice on managing finances"),
            t("balancing work and life becomes challenging"),
            t("I'm dealing with difficult family problems"),
        ],
        [EAPDescriptionType.ChildCare]: [
            t("For information about adoption services"),
            t("To learn conflict resolution strategies"),
            t("To get support with emotional caregiving challenges"),
            t("For guidance on parenting and family dynamics"),
            t("To discuss challenges with children's behavior"),
            t("To connect your children with a master's level clinician"),
        ],
        [EAPDescriptionType.ElderCare]: [
            t("For tips on aging well"),
            t("For support with grief and loss"),
            t("For help supporting elders with disabilities"),
            t("For advice about caring for aging family members"),
            t("To get support with emotional caregiving challenges"),
            t("To connect your family member with a master's level clinician"),
        ],
        [EAPDescriptionType.LegalAndFinancial]:[
            t("For estate planning tips"),
            t("For help with finding a lawyer"),
            t("For assistance in finding a financial advisor"),
            t("For debt management advice"),
            t("To learn new financial management skills"),
            t("To learn how to create and maintain a budget"),
        ],
        [EAPDescriptionType.ResourcesForManagers]: [
            t("For help navigating changes in the workplace"),
            t("To help managers address employee absenteeism"),
            t("To help managers communicate with employees"),
            t("To improve team morale, motivation, and teamwork"),
            t("To address workplace trauma and/or crisis response"),
            t("When needing to resolve conflict between individuals and teams"),
        ]
    }


    return (
        <div className="benefits-description">
            <div className={"description-title h6-bold"}>{t("I call my EAP when …")}</div>
            <div className="unordered-list-wrapper">
                <ul className="unordered-list-container">
                    {eapDescriptionMap[eapDescription].map((description: string, index: number) => {
                        if(index < 3){
                            return <li key={index} className="unordered-list-item body-medium">{description}</li>
                        }
                    })}
                </ul>
                <ul className="unordered-list-container">
                    {eapDescriptionMap[eapDescription].map((description: string, index: number) => {
                        if(index >= 3){
                            return <li key={index} className="unordered-list-item body-medium">{description}</li>
                        }
                    })}
                </ul>
            </div>
        </div>
    )
}