import "./CountryCodeSelector.scss";
import * as React from "react";
import { AreEquivalentCodes } from "../../misc/CountryCodeLookup";
import { IonSelect, IonSelectOption } from "@ionic/react";
import { useTranslation } from 'react-i18next';

export type CountryCodeSelectorProps = {
    codeMap: {[country: string]: string},
    updateCountryCode: (code: string) => void,
    initialCode: string,
}

export const CountryCodeSelector: React.FC<CountryCodeSelectorProps> = (props: CountryCodeSelectorProps) => {
    const initialValue = findEquivalentInitialValue(props);
    const { t } = useTranslation();

    return <div className="country-code-section">
        <label className="country-code-selector-label" htmlFor="instant-help-country-code-selector">{`${t("Get help in")}:`}</label>
        <IonSelect
            id="instant-help-country-code-selector"
            interface="popover"
            onIonChange={(event) => props.updateCountryCode(event.target.value)}
            className="country-code-selector"
            value={initialValue}>
            {Object.keys(props.codeMap).map((country) => {
                const code = props.codeMap[country];

                return <IonSelectOption value={code} key={code} className="foo">{t(country)}</IonSelectOption>
            })}
        </IonSelect>
    </div>
}

// account for non-identical country codes (e.g. US and USA, GB and UK), which
// currently exist in the DB
//
// we should probably pick a format in the future, but for now
// handle things sensibly
//
function findEquivalentInitialValue(props: CountryCodeSelectorProps) {
    let initialValue = "";

    Object.values(props.codeMap).forEach((code) => {
        if (AreEquivalentCodes(code, props.initialCode)) {
            initialValue = code;
        }
    });

    return initialValue;
}
