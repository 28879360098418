import React from 'react';
import './DeleteAccountModal.scss';
import {IonButton, IonModal, IonSpinner} from "@ionic/react";
import { useTranslation } from 'react-i18next';
import {ExitButton} from "../ExitButton/ExitButton";

interface DeleteAccountModalProps {
    isOpen: boolean;
    close: () => void;
    handleDeleteAccount: () => void;
    isDeletionLoading: boolean;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = (props: DeleteAccountModalProps) => {

    const { t } = useTranslation();

    const handleDeletionRefusalButtonPressed = () => {
        props.close();
    }

    return(
        <IonModal isOpen={props.isOpen} className={"account-deletion-modal-component"} backdropDismiss={false}>
            <div className="exit-button-container">
                <ExitButton onClick={() => props.close()}/>
            </div>

            <p className="warning-text-question header-4">{t("Are you sure you want to delete your account?")}</p>
            <p className="warning-text body-medium">{t("All your content will be deleted and you will have to contact your HR department to get reinstated into this free benefit.")}</p>
            <div className="buttons-container">
                <IonButton
                    disabled={props.isDeletionLoading}
                    className="buttons do-not-delete-button button-medium-variant"
                    onClick={handleDeletionRefusalButtonPressed}>
                    {t("No, don't delete my account")}
                </IonButton>
                <IonButton
                    disabled={props.isDeletionLoading}
                    className="buttons delete-confirmation-button button-medium-variant"
                    onClick={props.handleDeleteAccount}>
                    {props.isDeletionLoading ? <IonSpinner name="circles"/> : t("Yes, delete my account")}
                </IonButton>
            </div>
        </IonModal>
    );
}

export default DeleteAccountModal;
