import "./CategoryHeader.scss";

import { FC, MouseEventHandler, useContext }  from 'react';
import { ApplicationContext } from '../../misc/ApplicationContext';
import {IonIcon} from "@ionic/react";
import ArrowRight from "../../assets/images/arrow-right-icon.svg";
import { Link } from "react-router-dom";

interface Properties {
    name: string;
    description: string | null;
    path?: string;
    onClick: MouseEventHandler;
    children?: React.ReactNode;
}

function noop() {}

export const CategoryHeader: FC<Properties> = (props) => {

    const {isMobileWidth} = useContext(ApplicationContext);

    return(
        <div className='category-header-component'>
            {props.path && <>
                <Link onClick={props.onClick ?? noop} to={props.path}>
                    <div className={"title-description-container"}>
                        <div className={"title-text h6-bold"}>{props.name}</div>
                        {props.description && <div className={`description-text ${isMobileWidth ? "body-small" : "subtitle1"}`}>{props.description}</div>}
                    </div>
                </Link>
                {!isMobileWidth && <IonIcon className="mobile-forward-arrow" src={ArrowRight}/>}
            </>}
            {!props.path && 
                <div className={"title-description-container"}>
                    <div className={"title-text h6-bold"} onClick={props.onClick ?? noop}>{props.name}</div>
                    {props.description && <div className={`description-text ${isMobileWidth ? "body-small" : "subtitle1"}`}>{props.description}</div>}
                </div>
            }
            {props.children}
        </div>
    );
}