import "./MiniLanguageHeader.scss";
import * as React from "react";
import { useContext } from "react";
import {
    IonButton,
    IonIcon,
} from "@ionic/react";
import { ApplicationContext } from "../../misc/ApplicationContext";
import ExpandMore from "../../assets/images/expand_more.svg";
import "flag-icons/css/flag-icons.min.css";

export const MiniLanguageMenuHeader: React.FC = () => {

    const { handleToggleMiniLanguageMenu, isMiniLanguageMenuOpen, language } = useContext(ApplicationContext);

    return (
        <div className="mini-language-menu-header-component" onClick={handleToggleMiniLanguageMenu}>
            <div className="name-container">
                <div className="name button-small">
                    <span className={`flag fi fi-${language?.alpha2CodeISO ?? "us"}`} />
                    {language?.languageCode?.toUpperCase() ?? "EN"}
                </div>
            </div>

            <IonButton className={`dropdown-button ${isMiniLanguageMenuOpen ? 'down' : 'up'}`} onClick={handleToggleMiniLanguageMenu}>
                <IonIcon className="dropdown-icon" src={ExpandMore} />
            </IonButton>
        </div>
    )
}
