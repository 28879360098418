import { IonButton, IonTextarea } from "@ionic/react";
import "./AskAQuestion.scss";
import React, { useState, useContext, useEffect } from "react";
import { CheckboxAssessmentQuestion } from "../Assessment/CheckboxAssessmentQuestion";
import { ApplicationContext } from "../../misc/ApplicationContext";
import { NumberInputAssessmentQuestion } from "../Assessment/NumberInputAssessmentQuestion";
import { JourneyApiClient } from "../../utils/JourneyApiClient";
import { RadioButtonAssessmentQuestion } from "../Assessment/RadioButtonAssessmentQuestion";
import AnalyticsService from "../../misc/AnalyticsService";
import { useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

export class ResourcesEmailAnswer {
    phoneNumber: number | null = null;
    preferredContactMethod: "phone" | "email" | null = null;
    areasOfConcern: string | null = null;
    question: string | null = null;
}

export const AskAQuestion: React.FC = () => {

    const location = useLocation();
    const { currentUser, handleGeneralError, handleGeneralMessage } = useContext(ApplicationContext);
    const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
    const [formValues, setFormValues] = useState<ResourcesEmailAnswer>({
        phoneNumber: null,
        preferredContactMethod: null,
        areasOfConcern: null,
        question: null,
    });
    const { t } = useTranslation();


    useEffect(() => {
        const phoneNumberInputIsValid: boolean = (formValues.phoneNumber !== null && !(isNaN(formValues.phoneNumber)));
        if (currentUser &&
            (formValues.preferredContactMethod) && (formValues.areasOfConcern && formValues.areasOfConcern !== "[]")) {
            if (formValues.preferredContactMethod === "phone" && !phoneNumberInputIsValid) {
                setIsFormComplete(false);
            } else {
                setIsFormComplete(true);
            }
        } else {
            setIsFormComplete(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues]);

    async function trackFormSubmission() {
        await AnalyticsService.trackUserAction("ask_a_question_submission", location.pathname);
    }

    function handleValueChange(name: string, value: string | null | undefined, event?: any) {
        setFormValues({ ...formValues, [name]: value });
    }

    function handleNumberChange(name: string, value: number | null, event?: any) {
        setFormValues({ ...formValues, [name]: value });
    }

    function handleSubmitForm() {
        JourneyApiClient.submitResourcesAskQuestionForm(formValues).then(() => {
            handleGeneralMessage(t("Success"), t("Your question has been submitted, we'll respond shortly"));
            trackFormSubmission();
        }).catch(error => handleGeneralError("Could not submit ResourcesEmailForm", error))
    }

    const areasOfConcern: string[] = [
        t("Anger Management"),
        t("anxiety"),
        t("depression"),
        t("Drug/Alcohol & Addiction Concerns"),
        t("Dependent Care Concerns"),
        t("Family or Relationships Concerns"),
        t("Grief and Loss"),
        t("Legal/Financial Services"),
        t("Smoking Cessation"),
        t("Stress Management"),
        t("Workplace Concerns"),
        t("Other"),
    ];

    return (
        <div className={`ask-a-question-component`}>
            <div className="top-container">
                <div className="top-container-copy body-medium">{t("After completing the form below, one of our team members will contact you within one business day. If this is a life-threatening emergency, please contact emergency services for assistance.")}</div>
            </div>
            <div className="bottom-container">
                <div className="radio-button-wrapper">
                    <RadioButtonAssessmentQuestion
                        className="ask-a-question-radio-button"
                        questionClassName="header-4"
                        assessmentQuestion={t("Preferred contact method *")}
                        onValueChange={(contactMethod: string) => handleValueChange("preferredContactMethod", contactMethod.toLocaleLowerCase())}
                        assessmentValueOptions={[t("email"), t("phone")]} />
                </div>

                {(formValues.preferredContactMethod === "phone") &&
                    <div className="phone-number-input-container">
                        <div className="phone-number-input-label header-6">
                            {`${t("Phone Number")} *`}
                        </div>
                        <NumberInputAssessmentQuestion
                            className="ask-a-question-number-input"
                            assessmentQuestion={null}
                            onValueChange={(phoneNumber: number) => handleNumberChange("phoneNumber", phoneNumber)}
                            hasLabel={false} />
                    </div>}

                <CheckboxAssessmentQuestion
                    assessmentQuestion={t("Please indicate your area(s) of concern *")}
                    onValueChange={(concerns) => handleValueChange("areasOfConcern", JSON.stringify((concerns)))}
                    assessmentValueOptions={areasOfConcern}
                    checkboxLimit={areasOfConcern.length} />

                <IonTextarea
                    onIonChange={(e) => handleValueChange("question", e.detail.value)}
                    className="body-small question-input-field"
                    placeholder={t("Ask a question...") || ""}
                    value={formValues.question}
                    autoGrow={true}
                    rows={4}
                />
            </div>
            <div className="submit-button-container">
                <IonButton
                    disabled={!isFormComplete || !currentUser}
                    className="submit-button button-medium-variant"
                    onClick={handleSubmitForm}
                >
                    {t('submit')}
                </IonButton>
            </div>
        </div>
    )

}