import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {IonContent, IonPage} from "@ionic/react";
import "./PublicVideoPage.scss";
import {JourneyApiClient} from "../../utils/JourneyApiClient";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {VideoResponse} from "../../utils/ApiTypes";
import {PleaseWait} from "../../components/PleaseWait/PleaseWait";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxVideoPlayer} from "../../components/MuxVideoPlayer/MuxVideoPlayer";
import { useRouteMatch } from 'react-router';
import {useLocation} from "react-router";

export const PublicVideoPage: React.FC = () => {
    const [muxVideo, setMuxVideo] = useState<VideoResponse|null>(null);
    const [isVideoContentLoading, setIsVideoContentLoading] = useState<boolean>(true);
    const {handleGeneralError, handleApplicationError, isMobileWidth} = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const [videoId, setVideoId] = useState<number | null>(null);
    const match = useRouteMatch<{ id: string }>();
    const location = useLocation();

    useEffect(() => {
        if(isComponentVisible && !isNaN(parseInt(match.params.id, 10))){
            setVideoId(parseInt(match.params.id, 10));
            setIsVideoContentLoading(true);
            getVideoContent(match.params.id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isComponentVisible, match.params.id]);


    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("public_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("public_video_pause", location.pathname, eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: muxVideo?.title,
            video_duration: muxVideo?.duration,
            video_id: muxVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("public_video_checkpoint", location.pathname, eventProperties);
    }

    const getVideoContent = (videoId: string) => {
        setIsVideoContentLoading(true);
        JourneyApiClient.getPublicVideoById(videoId).then((jsonResponse: VideoResponse) => {
            setMuxVideo(jsonResponse);
            setIsVideoContentLoading(false);
        }).catch( error => {
            setIsVideoContentLoading(false);
            handleGeneralError("Could not get public video content", error);
        });
    }

    const handleError = (description: string) => {
        handleApplicationError(description);
    }

    const getVideoDurationString = (duration: number): string => {
        const minutes: number = Math.round(duration / 60);
        return `${minutes}m`;
    }

    return(
        <IonPage className="public-video-page-component">

            <IonContent className="public-video-content">
                <div className='public-video-container'>
                    {(videoId === null || isVideoContentLoading) ?
                        <PleaseWait/> : (muxVideo) &&
                        <MuxVideoPlayer
                            isPublicVideo={true}
                            videoId={muxVideo.id}
                            duration={muxVideo.duration}
                            position={muxVideo.position}
                            isLoading={isVideoContentLoading}
                            isComponentVisible={isComponentVisible}
                            isInitialMuted={true}
                            muxPlaybackId={muxVideo.muxPlaybackId}
                            muxVideoId={muxVideo.muxVideoId}
                            title={muxVideo.title}
                            currentUser={null}
                            trackCuePointEvent={trackCuePointEvent}
                            trackPlayEvent={trackPlayEvent}
                            trackPauseEvent={trackPauseEvent}
                            handleApplicationError={handleError}
                            numberOfRecommendationsToShow={isMobileWidth ? 1 : 6}/>
                    }
                </div>
                <div className="public-video-content-container">
                    {muxVideo && <>
                        <h1 className="public-title">{muxVideo.title }</h1>
                        <h6 className="public-duration">{muxVideo.duration ? getVideoDurationString(muxVideo.duration) : ""}</h6>
                        <h6 className="public-description">{muxVideo.description}</h6></>}

                </div>
            </IonContent>
        </IonPage>
    )
};
