import * as React from 'react';
import {useContext, useEffect} from "react";
import {useHistory} from "react-router-dom";
import {
    IonPage
} from "@ionic/react";
import "./ScheduleAppointmentPage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import {ScheduleAppointmentComponent} from "../../components/ScheduleAppointment/ScheduleAppointmentComponent";
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { Feature, HasFeatureEnabled } from '../../components/Feature/Feature';
import { HOME_PATH } from '../../utils/utils';

export const ScheduleAppointmentPage: React.FC = () => {

    const { currentUser } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();
    const history = useHistory();
    const hasScheduling = HasFeatureEnabled("talk-to-someone-schedule-appointment") || HasFeatureEnabled("talk-to-someone-schedule-wpo-appointment");

    useEffect(() => {
        if(isComponentVisible && !hasScheduling) history.push(HOME_PATH);
    }, [isComponentVisible]);
    
    return(
        <IonPage className="schedule-appointment-page">

            <ResponsiveNavigationMenu title={"Schedule Appointment"} />

            {currentUser &&
                <Feature feature='talk-to-someone-schedule-appointment'>
                    <ScheduleAppointmentComponent 
                        currentUser={currentUser} 
                        isComponentVisible={isComponentVisible}
                        isWpo={false}/>
                </Feature>
            }
            {currentUser &&
                <Feature feature='talk-to-someone-schedule-wpo-appointment'>
                    <ScheduleAppointmentComponent 
                        currentUser={currentUser} 
                        isComponentVisible={isComponentVisible}
                        isWpo={true}/>
                </Feature>
            }
        </IonPage>
    )
};
