import * as React from 'react';
import {useContext} from "react";
import "./DailyVideo.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../CustomHooks/useIsComponentVisible";
import { ApiUser, VideoResponse} from "../../utils/ApiTypes";
import AnalyticsService from "../../misc/AnalyticsService";
import {MuxVideoPlayer} from "../MuxVideoPlayer/MuxVideoPlayer";
import { useLocation } from "react-router-dom";
import INLINE_SHARE_ICON from "../../assets/images/inline-share-icon.svg";
import SVG from 'react-inlinesvg';
import { CopyToClipboardModal } from '../CopyToClipboard/CopyToClipboardModal';
import { useTranslation } from 'react-i18next';

interface DailyVideoProps {
    muxVideo: VideoResponse;
    currentUser: ApiUser | null;
    playVideoOnMute: boolean;
    isDailyJourneyVideo?: boolean;
    className?: string
    shareDailyJourneyUrl?: string;
}

export const DailyVideo: React.FC<DailyVideoProps> = (props: DailyVideoProps) => {

    const {handleApplicationError, isMobileWidth} = useContext(ApplicationContext);
    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const isComponentVisible = useIsComponentVisible();
    const location = useLocation();
    const { t } = useTranslation();

    const trackPlayEvent = async () => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_video_play", location.pathname, eventProperties);
    }

    const trackPauseEvent = async () => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_video_pause", location.pathname,  eventProperties);
    }

    const trackCuePointEvent = async (position: any) => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
            completion_percentage: position,
        }
        await AnalyticsService.trackUserAction("daily_video_checkpoint", location.pathname,  eventProperties);
    }

    const trackUnmute = async () => {
        let eventProperties = {
            video_title: props.muxVideo?.title,
            video_duration: props.muxVideo?.duration,
            video_id: props.muxVideo?.id,
        }
        await AnalyticsService.trackUserAction("daily_video_unmute", location.pathname, eventProperties);
    }

    const handleError = (description: string) => {
        handleApplicationError(description);
    }

    async function handleButtonClick () {
        await AnalyticsService.trackUserAction("more_videos_by_teacher", location.pathname);
    }

    async function handleShareButtonClick () {
        setIsModalOpen(true);
        await AnalyticsService.trackUserAction("daily_journey_share_link_viewed", location.pathname);
    }

    function addHiperlinkToTeachersName(description: string) {
        let teacherName = props.muxVideo.teacher?.firstName;
        const position = description.indexOf(teacherName!);
        let textBeforeName = "";
        let textAfterName = "";
        if (position >= 0) {
            textBeforeName = description.substring(0, position);
            textAfterName = description.substring(position + teacherName!.length);
        }
        else {
            textAfterName = description;
            teacherName += ": ";
        }

        return (
            <>
                {textBeforeName}
                <a onClick={handleButtonClick} href={`/teacher/${props.muxVideo.teacher?.id}`} className="teacher-name-link">
                    {teacherName}
                </a>
                {textAfterName + " "}
                <SVG className="share-icon" onClick={handleShareButtonClick} src={INLINE_SHARE_ICON} />
            </>
        );
    }

    return(
        <div className={`daily-video-component ${props.className ?? ''}`}>
            { props.muxVideo &&
                <>
                    <div className="daily-video-container">
                        <MuxVideoPlayer
                            videoId={props.muxVideo.id}
                            duration={props.muxVideo.duration}
                            isLoading={false}
                            isComponentVisible={isComponentVisible}
                            muxPlaybackId={props.muxVideo.muxPlaybackId}
                            muxVideoId={props.muxVideo.muxVideoId}
                            title={props.muxVideo.title}
                            currentUser={props.currentUser}
                            thumbnail={props.muxVideo.thumbnailUrl}
                            trackCuePointEvent={trackCuePointEvent}
                            trackPlayEvent={trackPlayEvent}
                            trackPauseEvent={trackPauseEvent}
                            handleApplicationError={handleError}
                            trackUnmute={trackUnmute}
                            autoplay={props.playVideoOnMute}
                            isInitialMuted={props.playVideoOnMute}
                            hideInitialControls={!props.playVideoOnMute}
                            numberOfRecommendationsToShow={isMobileWidth ? 1 : 2}/>
                    </div>
                    <div className="daily-video-description body-medium">
                        {addHiperlinkToTeachersName(props.muxVideo.description)}
                    </div>
                    {props.shareDailyJourneyUrl &&
                    <CopyToClipboardModal
                        title={t("Share Daily Journey")}
                        link={props.shareDailyJourneyUrl}
                        isModalOpen={isModalOpen}
                        dismissModal={() => setIsModalOpen(false)}
                    />}
                </>
            }
        </div>
    )
};
