import {IonButton, IonIcon} from "@ionic/react";
import { Link} from "react-router-dom";
import "./ArticlePreviewTile.scss";
import { ApiArticle } from "../../utils/ApiTypes";
import { useTranslation } from 'react-i18next';
import * as React from "react";
import { addOutline } from 'ionicons/icons';

export type ArticlePreviewTileProps = {
    article: ApiArticle;
    link: string;
};

export function ArticlePreviewTile(props: ArticlePreviewTileProps) {
    const { t, i18n } = useTranslation();

    return(
        <Link to={`${props.link}`} style={{ textDecoration: 'none' }} className={"article-preview-component"}>
            <div className="article-preview-container">
                <div className={"article-preview-title body-small-variant"}>
                    {props.article.title}
                </div>
                <div className={"article-preview-truncated-body caption"} dangerouslySetInnerHTML={{__html: props.article.teaser ?? ""}}/>
                <div className="article-preview-read-more-button-container">
                    <IonButton className="article-preview-read-more-button button-small-variant">
                        {t("Read More")}
                        {!i18n.exists('Read More') && <IonIcon className="read-more" icon={addOutline}/>}
                    </IonButton>
                </div>
            </div>
        </Link>
    )
}