import React, {useEffect, useState} from "react";
import {Direction, getTrackBackground, Range} from "react-range";
import "./AssessmentQuestion.scss";

interface assessmentRangeProps {
    assessmentQuestion: string,
    assessmentLowerBoundText: string | null,
    assessmentUpperBoundText: string | null,
    onValueChange: (value: number) => void,
    assessmentValueOptions: any[],
    isAlignmentVertical: boolean,
    isMobileWidth: boolean,
}

export const AssessmentRange: React.FC<assessmentRangeProps> = (props: assessmentRangeProps) => {

    const [levelSelected, setLevelSelected] = useState<number>(4);
    const isMobileWidth: boolean = props.isMobileWidth;
    const isAlignmentVertical: boolean = props.isAlignmentVertical;

    useEffect(() => {
        setLevelSelected(4);
    },[props.assessmentQuestion])

    function handleValueChange (indexSelected: number[]) {
        if(indexSelected.length > 0){
            setLevelSelected(indexSelected[0]);
        }
    }

    function handleFinalValueChange (indexSelected: number[]) {
        if(indexSelected.length > 0){
            setLevelSelected(indexSelected[0]);
            //Values stored in the db start on 1, the integer value is the index + 1 since its shifted
            props.onValueChange(indexSelected[0] + 1);
        }
    }

    return(
        <div className="assessment-question-component">
            <div className={`assessment-question-copy header-4`}>{props.assessmentQuestion}</div>
            <div className={`assessment-range-container ${isAlignmentVertical && "vertical"}`}>

                {!isAlignmentVertical && <div className="assessment-bound-copy header-6">{props.assessmentLowerBoundText}</div>}
                <div className="range-container">
                    <Range
                        values={[levelSelected]}
                        onChange={handleValueChange}
                        onFinalChange={handleFinalValueChange}
                        direction={isAlignmentVertical ? Direction.Up : Direction.Right}
                        min={0}
                        max={props.assessmentValueOptions.length - 1}
                        renderTrack={({ props, children }: any) => (
                            <div className={"range-track-container"} style={{...props.style,}}>
                                <div ref={props.ref} className={"range-track"} {...props}
                                    style={{
                                        background: getTrackBackground({
                                            values: [levelSelected],
                                            colors: ["#94EBE5", "#94EBE5"],
                                            min: 0,
                                            max: 10,
                                            direction: isAlignmentVertical ? Direction.Up : Direction.Right,
                                        }),
                                    }}
                                >
                                    {children}
                                </div>
                            </div>
                        )}
                        renderThumb={({ props, isDragged }: any) => (
                            <div {...props}
                                style={{
                                    ...props.style,
                                    height: `${isMobileWidth ? "60px" : "50px"}`,
                                    width: `${isMobileWidth ? "60px" : "50px"}`,
                                    borderRadius: `${isMobileWidth ? "30px" : "25px"}`,
                                    backgroundColor: "#4EDED6",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <div className={"range-number header-4-variant"}>{levelSelected + 1}</div>
                            </div>
                        )}
                        renderMark={({props}: any) => (
                            <div {...props}
                                style={{
                                    height:`${isMobileWidth ? "24px" : "18px"}`,
                                    width: `${isMobileWidth ? "24px" : "18px"}`,
                                    backgroundColor: "#4EDED6",
                                    borderRadius: `${isMobileWidth ? "12px" : "9px"}`,
                                    alignSelf: "center"
                                }}
                            >
                            </div>
                        )}
                    />
                </div>
                <div className={"assessment-bound-copy-container"}>
                    {isAlignmentVertical && <div className="assessment-bound-copy header-6">{props.assessmentLowerBoundText}</div>}
                    <div className="assessment-bound-copy header-6">{props.assessmentUpperBoundText}</div>
                </div>
            </div>

        </div>
    );
}
