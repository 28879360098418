import {useState} from 'react';
import {useIonViewWillEnter, useIonViewWillLeave} from '@ionic/react';

export default function useIsComponentVisible(): boolean  {
    const [isComponentVisible, setIsComponentVisible] = useState<boolean>(false);

    useIonViewWillEnter( async () =>{
        setIsComponentVisible(true);
    });

    useIonViewWillLeave(() => {
        setIsComponentVisible(false);
    });

    return isComponentVisible;
}