import "./ContentCarousel.scss";
import { MouseEventHandler, useContext } from 'react';
import {IonButton, IonIcon} from "@ionic/react";
import { Swiper, SwiperSlide } from 'swiper/react';
import ForwardArrow from "../../assets/images/forward-arrow-blue.svg";
import { ApplicationContext } from "../../misc/ApplicationContext";
import {FreeMode, Navigation} from 'swiper';
import { VideoTile } from "./VideoTile";
import { ContentItem, CollectionType, ApiTopic, VideoPlaylistContentItemResponse } from "../../utils/ApiTypes";
import { v4 as uuidv4 } from 'uuid';
import { PlaylistTile } from "./PlaylistTile";
import { LiveStreamTile } from "./LiveStreamTile";
import { GenericTile } from './GenericTile';
import { useWindowWidth } from '@react-hook/window-size/throttled';
import ArticleTile from "./ArticleTile";
import { TopicTile } from "./TopicTile";

export type ContentCarouselProps = {
    items: ContentItem[];
    onClick?: MouseEventHandler;
    className?: string;
    onSaveChange?: (saved: boolean) => void;
    showVideoTitles?: boolean;
    showVideoDescriptions?: boolean;
    pageSource?: string;
}

function noop() {}

export function ContentCarousel(props: ContentCarouselProps) {

    const { isMobileWidth } = useContext(ApplicationContext);
    const uniqueId = uuidv4();
    const windowWidth = useWindowWidth();

    return(
        <div className={`content-carousel-component ${props.className ?? ''}`} onClick={props.onClick ?? noop}>
            <IonButton fill="clear" className={`custom_prev_${uniqueId} arrow-button left`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <IonButton fill="clear" className={`custom_next_${uniqueId} arrow-button right`}>
                <IonIcon className="arrow-icon" icon={ForwardArrow}/>
            </IonButton>
            <Swiper
                className="swiper"
                modules={[Navigation, FreeMode]}
                scrollbar={false}
                freeMode={true}
                navigation={{
                    nextEl: `.custom_next_${uniqueId}`,
                    prevEl: `.custom_prev_${uniqueId}`,
                    disabledClass: 'hide-arrow'
                }}
                spaceBetween={isMobileWidth ?  20 : 40}
                slidesPerView={isMobileWidth ?  1.4 : windowWidth < 769 ? 2 : windowWidth < 1024 ? 2.5 : windowWidth < 1280 ? 3 : windowWidth < 1600 ? 3.5 : windowWidth < 1700 ? 4 : windowWidth < 1800 ? 4.5 : windowWidth < 1900 ? 5 : windowWidth < 2000 ? 5.5 : windowWidth < 2200 ? 6 : 6.5} >
                {
                    props.items.map((item, i) => {

                        switch (item.type) {
                            case 'playlist':
                                //TODO: Remove 'as VideoPlaylistContentItemResponse' when we refactor types
                                return <SwiperSlide key={i}><PlaylistTile showTitle={false} showDescription={false} onSaveChange={props.onSaveChange} playlist={item as VideoPlaylistContentItemResponse} className='image-carousel-tile' /></SwiperSlide>
                            case 'video':
                                return <SwiperSlide key={i}><VideoTile showTitle={false} showDescription={false} onSaveChange={props.onSaveChange} video={item} className='image-carousel-tile' /></SwiperSlide>
                            case 'livestream':
                                return <SwiperSlide key={i}><LiveStreamTile showTitle={false} showDescription={false} stream={item} className='image-carousel-tile' /></SwiperSlide>
                            case 'wpocontent':
                                return <SwiperSlide key={i}><ArticleTile item={item} /></SwiperSlide>
                            case 'generic':
                                return <SwiperSlide key={i}><GenericTile item={item} className='image-carousel-tile' /></SwiperSlide>
                            case CollectionType.Topic:
                                //TODO: Remove 'as ApiTopic' when we refactor types
                                return <SwiperSlide key={i}><TopicTile topic={item as ApiTopic} onSaveChange={props.onSaveChange}  className='image-carousel-tile' /></SwiperSlide>
                            default:
                                return <SwiperSlide key={i}><div></div></SwiperSlide>
                        }
                    })
                }
            </Swiper>

        </div>
    );
}
