import * as React from "react";
import "./CancelConfirmationModal.scss";
import {IonButton, IonModal} from "@ionic/react";
import { useTranslation } from "react-i18next";
import { ExitButton } from "../../ExitButton/ExitButton";
import Checkmark from "../../../assets/images/check-circle-regular-aqua.svg";


interface CancelConfirmationModalProps {
    isOpen: boolean
    handleCloseModal: () => void;
}

export const CancelConfirmationModal: React.FC<CancelConfirmationModalProps> = (props: CancelConfirmationModalProps) => {

    const { t } = useTranslation();

    return(
        <IonModal
            className={`cancel-appointment-confirmation-modal-component`}
            showBackdrop={true}
            isOpen={props.isOpen}
            onWillDismiss={props.handleCloseModal}
        >
            <div className="exit-button-container">
                <ExitButton onClick={() => props.handleCloseModal()}/>
            </div>

            <img className="cancel-appointment-confirmation-image" alt="Cancel appointment" src={Checkmark} />
            <p className="cancel-appointment-confirmation-header header-4">{t("Your appointment has been cancelled.")}</p>
            <div className="buttons-container">
                <IonButton
                    className="cancel-appointment-confirmation-button button-medium-variant"
                    onClick={props.handleCloseModal}>
                        {t("Done!")}
                </IonButton>
            </div>
        </IonModal>
    )
}
    