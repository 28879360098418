import React, {SyntheticEvent} from 'react';
import "./ThemeStyle.scss";
import {VideoPlaylistResponse} from "../../../../utils/ApiTypes";
import {AddToPlaylist} from "../../../AddToPlaylist/AddToPlaylist";
import { useTranslation } from 'react-i18next';

export type VideoStyleProps = {
    playlist: VideoPlaylistResponse;
    isSaved: boolean;
    handleRemoveFromList: (e: SyntheticEvent) => void;
    handleAddToList: (e: SyntheticEvent) => void;
    industryClass: string;
};

export function ThemeStyle(props: VideoStyleProps)  {

    const { t } = useTranslation();
    const isStandardColor = props.industryClass === "standard";

    return(
        <div className={`theme-style-component ${!isStandardColor && "industry-targeting-color-container"}`}>
            <div className={`image-container `}>
                {props.playlist.teacher?.videoThumbnailImageS3Path && <img className={"image"} alt="Theme thumbnail" src={`${props.playlist.teacher?.videoThumbnailImageS3Path}?fit=max&h=200&fm=webp`}/>}
                <div className={"tile-name-container"}>
                    <div className={"tile-name overline"}>{props.playlist.tileTitle ?? t('theme')}</div>
                </div>
                <div className={"title-container"}>
                    <div className={"title subtitle1-variant"}>
                    <p className={`title-size-modifier ${props.playlist.title.length > 40 ? 'small' : ''}`}>{props.playlist.title}</p>
                    </div>
                </div>
                <div className={"add-to-playlist-container"}>
                    <AddToPlaylist isSaved={props.isSaved} handleRemoveFromList={props.handleRemoveFromList} handleAddToList={props.handleAddToList}/>
                </div>

            </div>
        </div>
    )
}