import './ResponsiveNavigationMenu.scss';
import { FC } from 'react';
import { SideMenuButton } from '../SideMenuButton/SideMenuButton';
import { Toolbar } from '../Toolbar/Toolbar';

interface Props {
    title?: Maybe<string>;
    handleOpenLoginModal?: () => void;
    hideMenuButtons?: boolean;
}

export const ResponsiveNavigationMenu: FC<Props> = ({title, handleOpenLoginModal, hideMenuButtons}) => {
    return(
        <div className='responsive-navigation-menu-component'>
            <SideMenuButton title={title} />
            <Toolbar handleOpenLoginModal={handleOpenLoginModal} hideMenuButtons={hideMenuButtons} />
        </div>
    );
}