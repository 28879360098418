import "./MiniLanguageMenu.scss";
import * as React from "react";
import { MiniLanguageMenuList } from "./MiniLanguageMenuList";
import { ApiResourcesContentLanguage } from "../../utils/ApiTypes";

interface Props {
    rightMargin: number | null;
    sendToHome: boolean;
    companyId: number | undefined;
    languages: ApiResourcesContentLanguage[];
}

export const MiniLanguagesMenu: React.FC<Props> = (props: Props) => {

    return (
        <div className='mini-language-menu-component' style={{ right: `${props.rightMargin ? `${props.rightMargin}px` : '50%'}` }}>
            <MiniLanguageMenuList sendToHome={props.sendToHome} companyId={props.companyId} languages={props.languages}/>
        </div>
    )
}
