import * as React from "react";
import "./ModifyAppointmentModal.scss";
import {IonButton, IonContent, IonModal} from "@ionic/react";
import { ExitButton } from "../../ExitButton/ExitButton";
import { useTranslation } from "react-i18next";
import { ApiAcuityAppointment } from "../../../utils/ApiTypes";
import AppointmentInformation from "../../AppointmentInformation/AppointmentInformation";

interface ModifyAppointmentModalProps {
    isOpen: boolean
    handleCloseModal: () => void;
    appointment: ApiAcuityAppointment;
    onRescheduleButtonClicked: () => void;
    onCancelButtonClicked: () => void;
}

export const ModifyAppointmentModal: React.FC<ModifyAppointmentModalProps> = (props: ModifyAppointmentModalProps) => {

    const { t } = useTranslation();

    return(
        <IonModal
            className={`modify-appointment-modal-component`}
            showBackdrop={true}
            isOpen={props.isOpen}
            onWillDismiss={props.handleCloseModal}>
            <IonContent>
                <div className="exit-button-container">
                    <ExitButton onClick={() => props.handleCloseModal()}/>
                </div>

                <p className="modify-appointment-header header-4">{t("Modify Upcoming Appointment")}</p>

                <div className={"modify-appointment-bod"}>
                    {<AppointmentInformation 
                        appointment={props.appointment} 
                        className={"modify-appointment-modal-appointment-information"}  
                        includeDuration={true}
                        includeVirtualAppointmentText={true}/>
                    }
                </div>
                <div className={"modify-appointment-buttons-container"}>
                    <IonButton
                        className={"button-medium-variant modify-appointment-button"}
                        onClick={props.onRescheduleButtonClicked}>
                        {t('reschedule')}
                    </IonButton>
                    <IonButton
                        className={"button-medium-variant modify-appointment-button"}
                        onClick={props.onCancelButtonClicked}>
                        {t('cancel')}
                    </IonButton>
                </div>
            </IonContent>
        </IonModal>
    )
}
