import "./PhoneNumberList.scss";
import * as React from "react";
import PhoneIcon from '@mui/icons-material/Phone';
import { FilteredPhone, PhoneNumberFilter } from "./PhoneNumberFilter";
import { EAPSupportPhone } from '../../utils/ApiTypes';
import { CountryCodeLookup } from "../../misc/CountryCodeLookup";
import { CountryCodeSelector } from "./CountryCodeSelector";
import { useTranslation } from 'react-i18next';

export type PhoneNumberListProps = {
    countryCode: string,
    phones?: EAPSupportPhone[],
    updateCountryCode: (code: string) => void,
}

export const PhoneNumberList: React.FC<PhoneNumberListProps> = (props: PhoneNumberListProps) => {
    const numbers = PhoneNumberFilter(props.phones, props.countryCode)
    const { t } = useTranslation();
    const parts: HTMLElement[] = formatPhoneList(numbers, t);

    const codeMap: { [country: string]: string; } = mapCountriesToCodes(props);

    const needSelect = (Object.keys(codeMap).length > 1);

    if (numbers.length > 0) {
        return <div className='help-numbers'>
            {needSelect && <CountryCodeSelector codeMap={codeMap} updateCountryCode={props.updateCountryCode} initialCode={props.countryCode} />}

            <h6 className="header-6">{`${t("For immediate crisis support:")}:`}</h6>

            <p className="header-6">
                <PhoneIcon className="instant-help-icon" />
                <span className="phone-number-list">{parts}</span>
            </p>
        </div>;

    }
    else {
        return <div />;
    }

}

function mapCountriesToCodes(props: PhoneNumberListProps): { [country: string]: string; } {
    const codeMap: { [country: string]: string; } = {};

    (props?.phones || []).forEach((phoneInfo) => {
        const country = CountryCodeLookup(phoneInfo.countryCode || "");

        if (country && !(country in codeMap)) {
            codeMap[country] = phoneInfo.countryCode || "";
        }
    });

    return codeMap;
}

function formatPhoneList(numbers: FilteredPhone[], t: any): HTMLElement[] {
    let first = true;
    const parts: any[] = [];

    numbers.forEach((number) => {
        parts.push(<span key={number.displayNumber}>
            {first ? `${t("Call")} ` : " or "}
            <a href={number.linkNumber} className="display-number">{number.displayNumber}</a>
        </span>);

        first = false;
    });

    return parts;
}
