import React, { useContext, useState } from 'react';
import { ArrowContainer, Popover } from "react-tiny-popover";
import { IonButton } from "@ionic/react";
import AnalyticsService from "../../misc/AnalyticsService";
import { ApplicationContext } from "../../misc/ApplicationContext";
import "./AssessmentPopover.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Feature } from '../Feature/Feature';

export function AssessmentPopover() {

    const { currentUser, hasCompletedAssessment } = useContext(ApplicationContext);
    const [isAssessmentPopoverOpen, setIsAssessmentPopoverOpen] = useState<boolean>(false);
    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();

    function calculateRemainingDaysInMonth() {
        const date = new Date();
        const time = new Date(date.getTime());
        time.setMonth(date.getMonth() + 1);
        time.setDate(0);
        return time.getDate() > date.getDate() ? time.getDate() - date.getDate() : 0;
    }


    return (
        <div
            className={"monthly-assessment-popover-component"}
            onTouchStart={() => {
                if (hasCompletedAssessment || !currentUser) {
                    setIsAssessmentPopoverOpen(true)
                }
            }}
            onMouseEnter={() => {
                if (hasCompletedAssessment || !currentUser) {
                    setIsAssessmentPopoverOpen(true)
                }
            }}
            onTouchEnd={() => setIsAssessmentPopoverOpen(false)}
            onMouseLeave={() => setIsAssessmentPopoverOpen(false)}>
            <Popover
                containerClassName={"popover"}
                isOpen={hasCompletedAssessment && isAssessmentPopoverOpen}
                positions={['top']}
                padding={10}
                onClickOutside={() => setIsAssessmentPopoverOpen(false)}
                content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer
                        position={position}
                        childRect={childRect}
                        popoverRect={popoverRect}
                        arrowColor={'rgba(0, 0, 0, 0.72)'}
                        arrowSize={10}
                        className='popover-arrow-container'
                        arrowClassName='popover-arrow'
                    >
                        <div
                            className="popover-text"
                            onClick={() => setIsAssessmentPopoverOpen(!isAssessmentPopoverOpen)}
                        >
                            {t("Next Monthly Assessment opens in")} {calculateRemainingDaysInMonth()} {t("days")}
                        </div>
                    </ArrowContainer>
                )}
            >
                <div>
                    <Feature feature='assessment'>
                        <IonButton
                            className={"take-assessment-button button-small-variant"}
                            disabled={hasCompletedAssessment || !currentUser}
                            size="small"
                            onClick={() => {
                                history.push("/assessment");
                                AnalyticsService.trackUserAction("hp_personalized_assessment", location.pathname);
                            }}>
                            {t("Take Assessment")}
                        </IonButton>
                    </Feature>
                </div>
            </Popover>
        </div>
    )
}