import "./HrHubHeader.scss";
import * as React from "react";
import { ApiUser } from "../../utils/ApiTypes";

interface Props {
  currentUser: Maybe<ApiUser>;
}


export const HrHubHeader: React.FC<Props> = ({currentUser}) => {

  return(
      <div className="hr-hub-header-component">

        <div className="top">

            <div className="page-title">
              HR Hub
            </div>
            {
              currentUser?.company.logoUrl && 
              <>
                <div className="company-logo-space" />
                <img className="company-logo" src={`${currentUser.company.logoUrl}?h=80`} alt="Company logo" />
              </>
            }
        </div>

        <div className="subtitle1">
          Resources to support your organization's engagement and well being goals.
        </div>
      </div>
  );
}