import * as React from "react";
import "./ProfileProgressBar.scss";
import {useContext} from "react";
import {ApplicationContext} from "../../misc/ApplicationContext";
import {buildStyles, CircularProgressbarWithChildren} from 'react-circular-progressbar';
import DefaultProfileIcon from "../../assets/images/account-icon-blue.svg";
import 'react-circular-progressbar/dist/styles.css';

interface Props {
    reference?: React.Ref<any>;
    onProfileClick?: () => void;
}

export const ProfileProgressBar: React.FC<Props> = (props: Props) => {

    const {profileStepsCompleted, totalProfileSteps, currentUser} = useContext(ApplicationContext);

    return (
        <div className={"profile-header"} ref={props.reference} onClick={props.onProfileClick}>
            <CircularProgressbarWithChildren
                value={profileStepsCompleted}
                maxValue={totalProfileSteps}
                styles={buildStyles({
                    textColor: "#4EDED6",
                    pathColor: "#4EDED6",
                    textSize: "35px",
                    trailColor: "#F7F2ED"
                })}
            >
                <img style={{width: '30px', height: '30px', borderRadius: '50%'}} alt="Profile" className={"profile-image"}
                     src={currentUser ? currentUser.profileImage : DefaultProfileIcon}/>
            </CircularProgressbarWithChildren>
        </div>
    )
}
