import * as React from 'react';
import {useContext} from "react";
import { IonContent, IonPage } from "@ionic/react";
import "./EapSupportPhonePage.scss";
import {ApplicationContext} from "../../misc/ApplicationContext";
import useIsComponentVisible from "../../components/CustomHooks/useIsComponentVisible";
import { JourneyApiClient } from '../../utils/JourneyApiClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ResponsiveNavigationMenu } from '../../components/Navigation/ResponsiveNavigationMenu';
import { EapSupportPhones } from '../../components/EapSupportPhones/EapSupportPhones';
import { GetCompanyBenefitsInfoResponse } from '../../utils/ApiTypes';

export const EapSupportPhonesPage: React.FC = () => {

    const { currentUser, handleGeneralError, language } = useContext(ApplicationContext);
    const isComponentVisible = useIsComponentVisible();

    async function getBenefits(): Promise<GetCompanyBenefitsInfoResponse | undefined> {
        try {
            return await JourneyApiClient.getCompanyBenefitsInfo();
        } catch (e) {
            handleGeneralError("Could not benefits", e, undefined, { 
                contextName: "Get Benefits EapSupportPhonesPage", 
                contextData: {
                    error: e,
                    userId: currentUser?.id,
                    language: language,
                    company: currentUser?.company
                } 
            });
        }
    }
    const benefitsQuery = useQuery<Promise<GetCompanyBenefitsInfoResponse | undefined>, unknown, GetCompanyBenefitsInfoResponse>
        (["getCompanyBenefitsInfo"], getBenefits);


    return(
        <IonPage className="eap-support-phone-page">
            
            <ResponsiveNavigationMenu title={"Support Phones"} />
            
            <IonContent className="eap-support-phone-page-content">
                <div className="eap-support-phone-page-container">
                    {currentUser && benefitsQuery.status === "success" && benefitsQuery.data &&
                    <EapSupportPhones isComponentVisible={isComponentVisible} benefits={benefitsQuery.data}/>}
                </div>
            </IonContent>
        </IonPage>
    )
};
