import "./AssessmentComponent.scss";
import * as React from "react";
import { User} from "../../utils/ApiTypes";
import {AssessmentSteps} from "./AssessmentSteps";
import useMonthlyAssessmentProgress from "../CustomHooks/useMonthlyAssessmentProgress";
import { PleaseWait } from "../PleaseWait/PleaseWait";

interface assessmentComponentProps {
    currentUser: User;
}

export const AssessmentComponent: React.FC<assessmentComponentProps> = (props: assessmentComponentProps) => {

    const {assessmentProgress, isMonthlyAssessmentProgressLoading} = useMonthlyAssessmentProgress(props.currentUser);

    return(
        <div className="assessment-component">
            {isMonthlyAssessmentProgressLoading ? <PleaseWait/> : 
            <AssessmentSteps progress={assessmentProgress} currentUser={props.currentUser}/>}
        </div>
    )
}
