import { EAPSupportPhone } from '../../utils/ApiTypes';
import { AreEquivalentCodes } from '../../misc/CountryCodeLookup';

export type FilteredPhone = {
    displayNumber: string,
    linkNumber: string,
}

/**
 * Given a list of EAP support numbers and a country code, returns the
 * numbers matching that country, as a displayable number and a linkable
 * 'tel:...' value
 *
 * @param numbers list of support numbers for a company
 * @param countryCode country code we're concerned with
 * @returns matching numbers, ready for display/link
 */
export function PhoneNumberFilter(numbers: EAPSupportPhone[] | undefined, countryCode: string) : FilteredPhone[] {
    const matches = (numbers || []).filter((numberInfo) => {
        return AreEquivalentCodes(numberInfo.countryCode || undefined, countryCode);
    });

    return matches.map((numberInfo: EAPSupportPhone) => {
        return {
            displayNumber: numberInfo.subtitle.replace(/^\s*call\s+/i, ''),
            linkNumber: `tel:${numberInfo.phoneNumber}`,
        }
    });
}
