import React, {useEffect, useState} from 'react';
import "./ProgressIndicator.scss";

interface ProgressIndicatorProps{
    currentStep: number;
    totalSteps: number;
    isMobileWidth: boolean
}

interface Progress {
    state: "complete" | "current" | "incomplete";
    color: "green-step" | "teal-step";
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> = (props: ProgressIndicatorProps) => {

    const [progress, setProgress] = useState<Progress[]>([]);

    useEffect(() => {
        createSteps(props.currentStep, props.totalSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentStep]);


    function createSteps(currentStep: number, totalSteps: number) {
        const completedSteps: number = props.currentStep > 0 ? props.currentStep - 1 : 0;
        let progress: Progress[] = [];
        for (let i = 0; i < totalSteps; i++) {
            if (i === props.currentStep) {
                progress.push({
                    state: "current",
                    color: "teal-step"
                });
            } else if (i <= completedSteps) {
                progress.push({
                    state: "complete",
                    color: "green-step"
                });
            } else {
                progress.push({
                    state: "incomplete",
                    color: "teal-step"
                });
            }
        }
        setProgress(progress);
    }


    return (
        <div className={"progress-indicator-component"}>
            {progress.map((step: Progress, index) => {
                return (
                    <div className={"progress-indicator-container"} key={index}>
                        <div className={`rectangle ${step.state} ${step.color} ${index === 0 && "first"} ${index === progress.length-1 && "last"}`}/>
                        <div className={"step-text overline"}>{step.state === "current" && `${index + 1} ${props.isMobileWidth ? "/" : "of" } ${progress.length}`}</div>
                    </div>

                )
            })}
        </div>
    )
}