import "./TalkItOutCard.scss";
import {FC} from 'react';
import TalkItOutImage from "../../assets/images/talk-it-out.png";
import PhoneIcon from "../../assets/images/phone-plus-heart.svg";
import { IonButton, IonIcon } from "@ionic/react";
import { useTranslation } from 'react-i18next';


export const TalkItOutCard: FC = () => {
    const { t } = useTranslation();

    return(
        <div className="talk-it-out-card-component journey-card">
            <img className='background-image'src={TalkItOutImage} />
            <div className="box-header">
                <div className="box-title">
                    <div className={"title overline"}>{t('Talk It Out')}</div>
                </div>
            </div>
            
            <div className="inner-content">
                <div className="header-6-correct message">{t('Feel better in 20 minutes or less.')}</div>
                <IonButton className="button-medium-variant medium-primary-button" href="tel:18005551234">
                    <IonIcon src={PhoneIcon} slot="start" className="call-icon" />
                    1-800-555-1234
                </IonButton>
            </div>
            
        </div>
    );

};

