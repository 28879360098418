import * as React from 'react';
import {ApiUser, GetCompanyBenefitsInfoResponse} from "../../utils/ApiTypes";
import "./Benefits.scss";
import {Benefit} from "../Benefit/Benefit";
import { ApplicationContext } from '../../misc/ApplicationContext';
import {IonItemDivider} from "@ionic/react";
import {useTranslation} from "react-i18next";
import {LocalizedImage} from "../LocalizedImage/LocalizedImage";

interface Props {
    benefitsInfo: GetCompanyBenefitsInfoResponse;
    currentUser: ApiUser | null;
}


export const Benefits: React.FC<Props> = ({benefitsInfo, currentUser}) => {

    const {companyBenefits, standardBenefits} = benefitsInfo;
    const {isMobileWidth} = React.useContext(ApplicationContext);
    const { t } = useTranslation();
    const fallbackDesktopHeaderEnglish = "https://journeylive.imgix.net/production/benefits-images/benefits-header-desktop-en.png";
    const fallbackMobileHeaderEnglish = "https://journeylive.imgix.net/production/benefits-images/benefits-header-mobile-en.png";


    return(
        <div className='benefits-component'>


            <div className={"benefits-header-container industry-targeting-header-gradient"}>
                <div className={"benefits-header-text page-title-variant"}>
                    {t("benefits")}
                </div>
                <LocalizedImage
                    className='benefits-header-image'
                    i18nKey={isMobileWidth ? 'benefits-header-image-mobile' : 'benefits-header-image-desktop'}
                    fallback={isMobileWidth ? fallbackMobileHeaderEnglish : fallbackDesktopHeaderEnglish}
                />
            </div>
            <div className={"welcome-text-container"}>
                <div className={"welcome-text-title header-4"}>{t("Welcome!")}</div>
                <div className={"welcome-text-body body-large"}>
                    {t("Below you will find a list of crisis lines and resources that your employer provides as a benefit to you.")}
                </div>
            </div>
            <div id='benefits-section' className='benefits-panel-container'>
                {
                    standardBenefits.length > 0 &&
                    <div id='standard-benefits-section' className={"standard-benefits-panel"}>
                        <div className='benefits-list'>
                            {
                                standardBenefits.map((benefit, index) => {
                                    return(
                                        <div key={index}>
                                            <Benefit benefit={benefit} currentUser={currentUser} />
                                            { ((index < standardBenefits.length - 1) || isMobileWidth ) && <div  className="benefits-page-line-divider-container"><IonItemDivider className="benefits-page-line-divider"/></div>}
                                        </div>
                                    )

                                })
                            }
                        </div>

                    </div>
                }
                <div  className="benefits-vertical-line-divider-container"><IonItemDivider className="benefits-vertical-line-divider"/></div>
                {
                    !!companyBenefits.length &&
                    <div id='third-party-benefits-section' className='company-benefits-panel'>
                        <div className='benefits-list'>
                            {
                                companyBenefits.map((benefit, index) => {
                                    return(
                                        <div key={index}>
                                            <Benefit benefit={benefit} currentUser={currentUser} />
                                            { index < companyBenefits.length - 1 && <div  className="benefits-page-line-divider-container"><IonItemDivider className="benefits-page-line-divider"/></div>}
                                        </div>
                                    )

                                })
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
