import "./LiveStreamStyle.scss";
import {IonIcon} from "@ionic/react";
import Play from "../../../../assets/images/play.svg";
import {ApiLiveStream} from "../../../../utils/ApiTypes";
import {BACKGROUND_TILE_COLOR} from "../../../../utils/utils";
import { randomGenerator } from '../../../../utils/RandomGenerator';

export type VideoStyleProps = {
    liveStream: ApiLiveStream;
    industryClass: string;
};

export function LiveStreamStyle(props: VideoStyleProps)  {
    let rand = randomGenerator(props.liveStream.id);
    const colorId = Math.floor(rand() * BACKGROUND_TILE_COLOR.length);
    const isStandardColor = props.industryClass === "standard";

    return(
        <div className={`video-style-component ${isStandardColor ? BACKGROUND_TILE_COLOR[colorId] : "industry-targeting-color-container"}`}>
            <div className={`image-container`}>
            {props.liveStream.teacher?.videoThumbnailImageS3Path && <img className={"image"} alt="Video thumbnail" src={`${props.liveStream.teacher?.videoThumbnailImageS3Path}?fit=max&h=200&fm=webp`}/>}
                <div className={"title-container"}>
                    <div className={"title subtitle2-variant"}>{props.liveStream.title}</div>
                </div>
                <IonIcon className="play-icon" src={Play} />
            </div>
        </div>
    )
}