/**
 * Pseudo random generator based on mulberry32 algorithm
 * It generates numbers between [0,1)
 */
export function randomGenerator(seed: number) {
    return function() {
      var t = seed += 0x6D2B79F5;
      t = Math.imul(t ^ t >>> 15, t | 1);
      t ^= t + Math.imul(t ^ t >>> 7, t | 61);
      return ((t ^ t >>> 14) >>> 0) / 4294967296;
    }
}
