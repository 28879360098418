import React from 'react';
import "./AppointmentInformation.scss";
import { ApiAcuityAppointment } from '../../utils/ApiTypes';
import {format, addMinutes} from "date-fns";
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import openInNew from "../../assets/images/open_in_new.svg";
import { capitalizeFirstLetter } from '../../utils/utils';

interface AppointmentInformationProps {
    appointment: ApiAcuityAppointment;
    className?: string
    includeDuration: boolean;
    includeVirtualAppointmentText: boolean;
}

const howToMakeTheMostOfItVideoId = 39943062;

export default function AppointmentInformation ({appointment, className, includeDuration, includeVirtualAppointmentText}: AppointmentInformationProps) {

    const { t } = useTranslation();
    const firstName = capitalizeFirstLetter(appointment.clinicianFirstName);
    const lastName = capitalizeFirstLetter(appointment.clinicianLastName);

    return (
        <div className={`appointment-information-component ${className}`}>
            {appointment.clinicianThumbnailUrl && <img src={appointment.clinicianThumbnailUrl} alt="clinician thumbnail"/>}
            <div className={"appointment-information-container"}>
                <div className={`clinician-name ${className === "talk-to-someone" ? "body-large-variant" : "header-6-variant"}`}>
                    {firstName} {lastName}
                </div>
                {includeVirtualAppointmentText && <div className={`${className === "talk-to-someone" ? "body-large" : "h6"}`}>
                    {appointment.appointmentLink === 'Phone' ? t('Phone Call') : t("Virtual Appointment")}

                </div>}
                {includeDuration && <div className={`${className === "talk-to-someone" ? "body-large" : "h6"}`}>
                    {`${appointment.appointmentDurationInMinutes} ` + t("minutes")}
                </div>}
                <div className={`${className === "talk-to-someone" ? "body-large" : "h6"}`}>
                    {`${format(new Date(appointment.appointmentDatetime), 'MMM, dd')} `}

                    {`${new Date(appointment.appointmentDatetime).toLocaleTimeString(undefined, {hour: 'numeric', minute:'2-digit'})} - 
                            ${addMinutes(new Date(appointment.appointmentDatetime), appointment.appointmentDurationInMinutes)
                        .toLocaleTimeString(undefined, {hour: 'numeric', minute:'2-digit'})}`}
                </div>
                <div>               
                    <a href={`/watch/${howToMakeTheMostOfItVideoId}`} className={"button-small-variant appointment-button"}>{t("How to make the most of it")}<IonIcon src={openInNew}/></a>
                </div>
            </div>
        </div>
    );
};