import React, { useContext } from 'react';
import "./AssessmentWelcomeStep.scss";
import {IonButton, IonIcon} from "@ionic/react";
import rightArrow from "../../assets/images/right-arrow-white.svg";
import journeyLogoBlue from "../../assets/images/journey-logo-blue.svg";
import { ApplicationContext } from '../../misc/ApplicationContext';
import { useTranslation } from 'react-i18next';
import {ExitButton} from "../ExitButton/ExitButton";

interface assessmentWelcomeStepProps {
    handleNextStepNavigation: () => void;
    handleModalDismiss: () => void;
}

export const AssessmentWelcomeStep: React.FC<assessmentWelcomeStepProps> = (props: assessmentWelcomeStepProps) => {

    const { currentUser } = useContext(ApplicationContext);
    const { t } = useTranslation();

    return(
        <div className="assessment-welcome-step-component">
            <div className="exit-button-container"><ExitButton onClick={props.handleModalDismiss}/></div>
            <div className="journey-logo-container">
                <img alt={"Journey Logo"} src={currentUser?.company?.appLogoUrl ?? journeyLogoBlue}/>
            </div>
            <div className="assessment-title header-4">{t("Welcome to Journey!")}</div>
            <div className="assessment-text body-medium">{t("We’re excited to help you prioritize your wellbeing. Can we start by asking you a few questions?")}</div>
            <div className="assessment-continue-button-container">
                <IonButton className="assessment-continue-button button-medium-variant" onClick={props.handleNextStepNavigation}>
                {t("Get Started")}<IonIcon src={rightArrow}/>
                </IonButton>
            </div>
        </div>
    )
}