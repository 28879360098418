import {findCountryFromAlpha2, findCountryFromAlpha3} from 'coodes-country-codes';

/**
 * get a country's name based on its country code
 *
 * Special cases:
 *
 * - given a 'uk' code, we look up 'gb' (the actual ISO code)
 * - the official display name for 'US' includes "of America", which we don't want,
 *   so we remove that
 *
 * @param code 2- or 3-character country code
 * @returns country's display name, or null if no match
 */
export function CountryCodeLookup(code?: string): string | null {
    let countryName: string | null = null;

    if (code) {
        if (code.toLowerCase() === 'uk') {
            countryName = findCountryFromAlpha2('gb');
        }
        else if (code.length === 3) {
            countryName = findCountryFromAlpha3(code);
        }
        else {
            countryName = findCountryFromAlpha2(code);
        }
    }

    if (countryName === "United States of America") {
        countryName = "United States";
    }

    return countryName;
}

/**
 * test whether a 2- or 3-char country code represents the US
 *
 * @param code country code to check
 * @returns true if the code maps to the US, false otherwise
 */
export function IsUSCode(code?: string): boolean {
    if (code) {
        return ['us', 'usa'].includes(code.toLowerCase());
    }

    return false;
}

/**
 * Check whether two country codes represent the same country
 *
 * @param codeA code to check
 * @param codeB other code to compare
 *
 * @returns true if the codes are equivalent, false otherwise
 */
export function AreEquivalentCodes(codeA?: string, codeB?: string): boolean {
    return codeA === codeB || CountryCodeLookup(codeA) === CountryCodeLookup(codeB);
}
